export const getGrades = (percentage, level) => {
  if (level?.startsWith("primary")) {
    switch (true) {
      case percentage >= 90 && percentage <= 100:
        return "AL1";
      case percentage >= 85 && percentage <= 89:
        return "AL2";
      case percentage >= 80 && percentage <= 84:
        return "AL3";
      case percentage >= 75 && percentage <= 79:
        return "AL4";
      case percentage >= 65 && percentage <= 74:
        return "AL5";
      case percentage >= 45 && percentage <= 64:
        return "AL6";
      case percentage >= 20 && percentage <= 44:
        return "AL7";
      case percentage >= 0 && percentage <= 19:
        return "AL8";
      default:
        return "Invalid Percentage";
    }
  } else {
    switch (true) {
      case percentage >= 75 && percentage <= 100:
        return "A1";
      case percentage >= 70 && percentage <= 74:
        return "A2";
      case percentage >= 65 && percentage <= 69:
        return "B3";
      case percentage >= 60 && percentage <= 64:
        return "B4";
      case percentage >= 55 && percentage <= 59:
        return "C5";
      case percentage >= 50 && percentage <= 54:
        return "C6";
      case percentage >= 45 && percentage <= 49:
        return "D7";
      case percentage >= 40 && percentage <= 44:
        return "E8";
      case percentage >= 0 && percentage <= 39:
        return "F8";
      default:
        return "Invalid Percentage";
    }
  }
};

export const getGrade = (percentage) => {
  switch (true) {
    case percentage >= 90 && percentage <= 100:
      return "AL1";
    case percentage >= 85 && percentage <= 89:
      return "AL2";
    case percentage >= 80 && percentage <= 84:
      return "AL3";
    case percentage >= 75 && percentage <= 79:
      return "AL4";
    case percentage >= 65 && percentage <= 74:
      return "AL5";
    case percentage >= 45 && percentage <= 64:
      return "AL6";
    case percentage >= 20 && percentage <= 44:
      return "AL7";
    case percentage >= 0 && percentage <= 19:
      return "AL8";
    default:
      return "Invalid Percentage";
  }
};
export const getGradeSecondary = (percentage) => {
  switch (true) {
    case percentage >= 75 && percentage <= 100:
      return "A1";
    case percentage >= 70 && percentage <= 74:
      return "A2";
    case percentage >= 65 && percentage <= 69:
      return "B3";
    case percentage >= 60 && percentage <= 64:
      return "B4";
    case percentage >= 55 && percentage <= 59:
      return "C5";
    case percentage >= 50 && percentage <= 54:
      return "C6";
    case percentage >= 45 && percentage <= 49:
      return "D7";
    case percentage >= 40 && percentage <= 44:
      return "E8";
    case percentage >= 0 && percentage <= 39:
      return "F8";
    default:
      return "Invalid Percentage";
  }
};

export const getGoalGrade = (grade) => {
  switch (true) {
    case grade === "AL1":
      return { paper1: (42 / 45) * 100, paper2: (38 / 55) * 100 };
    case grade === "AL2":
      return { paper1: (40 / 45) * 100, paper2: (45 / 55) * 100 };
    case grade === "AL3":
      return { paper1: (38 / 45) * 100, paper2: (42 / 55) * 100 };
    case grade === "AL4":
      return { paper1: (36 / 45) * 100, paper2: (39 / 55) * 100 };
    case grade === "AL5":
      return { paper1: (33 / 45) * 100, paper2: (32 / 55) * 100 };
    case grade === "AL6":
      return { paper1: (20 / 45) * 100, paper2: (25 / 55) * 100 };
    case grade === "AL7":
      return { paper1: (18 / 45) * 100, paper2: (20 / 55) * 100 };
    case grade === "AL8":
      return { paper1: (15 / 45) * 100, paper2: (15 / 55) * 100 };

    default:
      return "Invalid";
  }
};

export const getGoalGrades = (grade, title) => {
  if (title === "Mathematics") {
    switch (true) {
      case grade === "AL1":
        return { paper1: (42 / 45) * 100, paper2: (38 / 55) * 100 };
      case grade === "AL2":
        return { paper1: (40 / 45) * 100, paper2: (45 / 55) * 100 };
      case grade === "AL3":
        return { paper1: (38 / 45) * 100, paper2: (42 / 55) * 100 };
      case grade === "AL4":
        return { paper1: (36 / 45) * 100, paper2: (39 / 55) * 100 };
      case grade === "AL5":
        return { paper1: (33 / 45) * 100, paper2: (32 / 55) * 100 };
      case grade === "AL6":
        return { paper1: (20 / 45) * 100, paper2: (25 / 55) * 100 };
      case grade === "AL7":
        return { paper1: (18 / 45) * 100, paper2: (20 / 55) * 100 };
      case grade === "AL8":
        return { paper1: (15 / 45) * 100, paper2: (15 / 55) * 100 };

      default:
        return "Invalid";
    }
  } else if (title === "English") {
    switch (true) {
      case grade === "AL1":
        return { paper1: (26 / 28) * 100, paper2: (60 / 67) * 100 };
      case grade === "AL2":
        return { paper1: (25 / 28) * 100, paper2: (57 / 67) * 100 };
      case grade === "AL3":
        return { paper1: (25 / 28) * 100, paper2: (52 / 67) * 100 };
      case grade === "AL4":
        return { paper1: (24 / 28) * 100, paper2: (48 / 67) * 100 };
      case grade === "AL5":
        return { paper1: (21 / 28) * 100, paper2: (40 / 67) * 100 };
      case grade === "AL6":
        return { paper1: (15 / 28) * 100, paper2: (28 / 67) * 100 };
      case grade === "AL7":
        return { paper1: (12 / 28) * 100, paper2: (25 / 67) * 100 };
      case grade === "AL8":
        return { paper1: (8 / 28) * 100, paper2: (20 / 67) * 100 };

      default:
        return "Invalid";
    }
  } else {
    switch (true) {
      case grade === "AL1":
        return { paper1: (54 / 56) * 100, paper2: (36 / 44) * 100 };
      case grade === "AL2":
        return { paper1: (52 / 56) * 100, paper2: (33 / 44) * 100 };
      case grade === "AL3":
        return { paper1: (50 / 56) * 100, paper2: (30 / 44) * 100 };
      case grade === "AL4":
        return { paper1: (48 / 56) * 100, paper2: (27 / 44) * 100 };
      case grade === "AL5":
        return { paper1: (44 / 56) * 100, paper2: (21 / 44) * 100 };
      case grade === "AL6":
        return { paper1: (30 / 56) * 100, paper2: (15 / 44) * 100 };
      case grade === "AL7":
        return { paper1: (26 / 56) * 100, paper2: (10 / 44) * 100 };
      case grade === "AL8":
        return { paper1: (20 / 56) * 100, paper2: (8 / 44) * 100 };

      default:
        return "Invalid";
    }
  }
};

export const getLastThreeTopics = (data) => {
  // Sort the array by index (in ascending order)
  // data.sort((a, b) => a.value - b.value);

  // Use slice to get the last three topics
  const lastThreeTopics = data.slice(0, 3);

  // Extract and return only the "topic" property from each object

  return lastThreeTopics;
};

export const getActiveRoute = (path) => {
  if (path === "profile") {
    return ["0"];
  } else if (path === "statistics") {
    return ["1"];
  } else if (path === "testHistory") {
    return ["2"];
  } else if (path === "livetest") {
    return ["3"];
  } else if (path === "questionConsultation") {
    return ["4"];
  } else if (path === "badges") {
    return ["5"];
  } else if (path === "claimRewards") {
    return ["6"];
  } else if (path === "profileSettings") {
    return ["7"];
  }
};

export const getRowsTable = (height) => {
  if (height > 800 && height < 850) {
    return 8;
  } else if (height > 850 && height < 930) {
    return 8;
  } else if (height >= 930 && height < 1000) {
    return 8;
  } else if (height >= 1000 && height < 1070) {
    return 8;
  } else if (height >= 1070) {
    return 8;
  } else {
    return 8;
  }
};
