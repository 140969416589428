import React, { useState } from "react";
import { Form, Input, Button, Icon, Checkbox } from "antd";
import { connect } from "react-redux";
import { login, logout, forgotpassword } from "../../actions/loginAction";
import auth from "../../services/AuthServices";
import { Redirect, withRouter, Link } from "react-router-dom";
import { Typography } from "antd";
import Alert from "../../components/common/alert";
import "../../components/basic/login/login.css";
const ForgotPassword = ({ form, history, user, login }) => {
  const { Title } = Typography;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [authType, setAuthType] = useState("STUDENT");

  const handleClick = () => {
    history.push("/register");
  };

  const handleLoginClick = () => {
    history.push("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        auth
          .ResetLogin(
            values.email,
            `${window.location.origin}/#/reset-password`
          )
          .then((response) => {
            if (response.data.success) {
              forgotpassword(response.data.user);
              return Alert("success", "Success!", response.data.message);
              // auth.storeToken(response.data.token);
              // setIsLoggedIn(true);
            } else {
              return Alert(
                "error",
                "Error!",
                response.data.message.replaceAll("TRAINER", "TEACHER")
              );
            }
          })
          .catch((error) => {
            console.log(error);
            const message = error.response
              ? error.response.data.message
              : error.message;
            return Alert("error", "Error!", message);
          });
      }
    });
  };

  // const changeAuthType = (selected) => {
  //   if (
  //     selected !== "ADMIN" &&
  //     selected !== "TRAINER" &&
  //     selected !== "STUDENT"
  //   )
  //     return;
  //   setAuthType(selected);
  // };

  const { getFieldDecorator } = form;
  if (isLoggedIn) {
    return <Redirect to={user.userOptions[0].link} />;
  } else {
    return (
      <div className="login-container">
        <div className="login-student-inner">
          <div className="space-btw">
            <Button className="login-btn" ghost onClick={handleLoginClick}>
              Sign In
            </Button>
            <Button className="register-btn" ghost onClick={handleClick}>
              Register
            </Button>
          </div>
          <div className="login-log">
            <img
              src="/assets/leagueonlinelogo2.png"
              alt="logo"
              width={"30%"}
              //   height={"200px"}
            />
          </div>
          {/* <div className="register_login_switch">
            <span
              onClick={() => changeAuthType("STUDENT")}
              className={`${authType === "STUDENT" ? "selected" : ""}`}
            >
              Student
            </span>
            <span
              onClick={() => changeAuthType("TRAINER")}
              className={`${authType === "TRAINER" ? "selected" : ""}`}
            >
              Teacher
            </span>
            <span
              onClick={() => changeAuthType("ADMIN")}
              className={`${authType === "ADMIN" ? "selected" : ""}`}
            >
              Admin
            </span>
          </div> */}
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                gap: 12,
                flexDirection: "column",
              }}
            >
              <div>
                <Title
                  style={{ color: "white", fontSize: "30px", fontWeight: 400 }}
                  level={4}
                >
                  Forgot your password?
                </Title>
                <Title
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: 400,
                    textAlign: "start",
                  }}
                >
                  Please enter the email used to sign up with. Information to
                  reset your password will be sent to the email.
                </Title>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 24,
                  flexDirection: "column",
                }}
              >
                <Form.Item
                  label="Enter email address"
                  colon={false}
                  hasFeedback
                >
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ],
                  })(
                    <Input
                      // prefix={
                      //   <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                      // }
                      placeholder="Email"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Request reset link
                  </Button>
                </Form.Item>
              </div>
            </div>

            {/* {authType === "STUDENT" && ( */}
            {/* <Form.Item>
              <Button type="primary" block >
                Register
              </Button>
            </Form.Item> */}
            {/* )} */}
          </Form>
        </div>
      </div>
    );
  }
};

const ForgotForm = Form.create({ name: "fogot-password" })(ForgotPassword);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { login, logout, forgotpassword })(ForgotForm)
);
