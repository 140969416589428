import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./portal.css";
import SingleQuestion from "./singleQuestion";
import {
  fetchTraineeTestQuestions,
  fetchTraineeTestAnswerSheet,
  fetchPeanding,
} from "../../../actions/traineeAction";
import BgImage from "../../../assets/testPageBg.svg";
import SiderQImage from "../../../assets/siderQImage.svg";
import { Typography } from "antd";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import apis from "../../../services/Apis";
import { useRef } from "react";

const Question = (props) => {
  const location = useLocation(); // For React Router

  // Access query parameters
  const queryParams = new URLSearchParams(location.search);
  const testId = queryParams.get("testid");
  const traineeid = queryParams.get("traineeid");

  const [shouldRenderQuestion, setShouldRenderQuestion] = useState(false);
  const socket = useRef();

  // useEffect(() => {
  //   socket.current = io(apis.BASE_LOCAL_URL);
  // }, []);

  // useEffect(() => {
  //   socket.current.on("connect", () => {
  //     console.log("socket");
  //   });
  //   socket.current.emit("join_test", {
  //     testid: testId,
  //     traineeid: traineeid,
  //     socketid: socket.current.id,
  //   });
  //   socket.current.on("pending", (data) => {
  //     props.fetchPeanding(data, socket);
  //   });
  // }, [socket, testId, traineeid]);

  // useEffect(() => {
  //   props.fetchTraineeTestQuestions(testId);
  //   props.fetchTraineeTestAnswerSheet(testId, traineeid);

  //   const timeoutId = setTimeout(() => {
  //     setShouldRenderQuestion(true);
  //   }, 3000);

  //   return () => clearTimeout(timeoutId);
  // }, [testId, traineeid]);

  return (
    <div className="question-holder-new">
      <div className="single-question-container-new">
        {props.loading && props.trainee?.questions?.length > 0 ? (
          <SingleQuestion
            mode={props.mode}
            triggerSidebar={props.triggerSidebar}
            key={props.trainee.activeQuestionIndex}
            title={props.trainee.title}
          />
        ) : null}
      </div>

      <div className="ImagebgTest">
        <img src={BgImage} alt="" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default connect(mapStateToProps, {
  fetchTraineeTestQuestions,
  fetchTraineeTestAnswerSheet,
  fetchPeanding,
})(Question);
