import React, { useEffect } from "react";
import { BrowserRouter, Route, HashRouter, Switch } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";

import Homepage from "./components/basic/homepage/homepage";
import Dashboard from "./components/dashboard/backbone";
import TraineeRegister from "./components/trainee/register/traineeregister";
import MainPortal from "./components/trainee/examPortal/portal";
import answer from "./components/trainee/answersheet/answer";
// import Answer from "../answersheet/answer";
import "react-quill/dist/quill.snow.css";
import Forgot from "./auth/forgotpassword/index";
import ResetPassword from "./auth/forgotpassword/ResetPassword";

function App() {
  return (
    <Provider store={store}>
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/admin" component={Homepage} />
          <Route exact path="/home" component={Homepage} />
          <Route exact path="/user" component={Dashboard} />
          <Route path="/user/:options" component={Dashboard} />
          <Route exact path="/register" component={TraineeRegister} />
          <Route exact path="/forgot-password" component={Forgot} />
          <Route exact path="/reset-password/:id" component={ResetPassword} />
          <Route exact path="/trainee/taketest" component={MainPortal} />
          <Route
            path="/trainee/taketest?testid=:testid&traineeid=:traineeid"
            component={answer}
          />
        </Switch>
      </HashRouter>
    </Provider>
  );
}

export default App;
