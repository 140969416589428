import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Icon,
  Typography,
  Divider,
  Modal,
  Popconfirm,
  Form,
  Select,
} from "antd";

import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import {
  ChangeTrainerSearchText,
  ChangeStudentTableData,
  ChangeTrainerModalState,
} from "../../../actions/adminAction";
import "../allStudent/allstudent.css";
import "./AllRewards.css";
import Diamond from "../../../assets/diamond.svg";

import Alert from "../../../components/common/alert";
import {
  SecureDelete,
  SecurePost,
  SecurePostMultipart,
} from "../../../services/axiosCall";
import apis, { IMGAE_BASE_URL } from "../../../services/Apis";
import NewTrainerForm from "../newTrainer/newtrainer";
import UploadIcon from "../../../assets/fileUpload.svg";
import CrossIcon from "../../../assets/crossIcon.svg";
import AuthServices from "../../../services/AuthServices";
import { GetCategory } from "../../../actions/GetCategory";
import { GetRewards } from "../../../actions/GetRewards";
const initialValue = {
  name: "",
  description: "",
  cost: "",
  categoryId: "",
};

const AllRewards = (props) => {
  const {
    admin,
    category,
    rewards,
    ChangeTrainerSearchText,
    ChangeStudentTableData,
    ChangeTrainerModalState,
  } = props;
  const [trainertableLoading, setTrainertableLoading] = useState(false);

  const searchInputRef = useRef(null);

  const [isCategory, setIsCategory] = useState(false);
  const [isRewardInfo, setIsRewardInfo] = useState(false);
  const [rewardObject, setRewardObject] = useState();

  const [isReward, setIsReward] = useState(false);
  const [image, setImage] = useState(null);
  const [category1, setCategory1] = useState("");
  const [reFectch, setRefetch] = useState(false);
  const [rewardValue, setRewardValue] = useState(initialValue);
  const [reFectchRewards, setRefetchRewards] = useState(false);

  const handleImageChage = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.type.startsWith("image/")) {
        setImage(selectedFile);
      } else {
        Alert("warning", "message", "Please select a valid image file.");
      }
    }
  };

  const handleChangeReward = (e) => {
    const { name, value } = e.target;
    setRewardValue({
      ...rewardValue,
      [name]: value,
    });
  };

  const handleAproved = async (id) => {
    try {
      const res = await SecurePost({
        url: `${apis.APROVE_STUDENT}`,
        data: {
          studentId: id,
        },
      });

      if (res) {
        ChangeStudentTableData();
      }
    } catch (error) {}
  };

  const deleteRewards = (id) => {
    SecureDelete({
      url: `${apis.DELETE_REWARD}/${id}`,
    })
      .then((response) => {
        if (response.data.message) {
          Alert("success", "Success", response?.data?.message);
          setRefetchRewards(!reFectchRewards);
        } else {
          return Alert("warning", "Warning!", response?.data?.message);
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  const handleCreateCategory = () => {
    if (category1 === "") {
      return Alert("warning", "Warning!", "Please write any category Name");
    }
    SecurePost({
      url: `${apis.CREATE_CATEGORY}`,
      data: {
        name: category1,
      },
    })
      .then((response) => {
        if (response.data.success) {
          Alert("success", "Success", response.data.message);
          setRewardValue(initialValue);
          setRefetch(!reFectch);
          setIsCategory(false);
        } else {
          return Alert("warning", "Warning!", response.data.message);
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  useEffect(() => {
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      AuthServices.GetAllCategory(t).then((response) => {
        props.GetCategory(response?.data?.data);
      });
    } else {
      window.location = "/";
    }
  }, [reFectch]);

  useEffect(() => {
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      AuthServices.GetAllReward(t).then((response) => {
        props.GetRewards(response?.data?.data);
      });
    } else {
      window.location = "/";
    }
  }, [reFectchRewards]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInputRef.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[admin.TrainersearchText]}
        autoEscape
        textToHighlight={text?.toString()}
      />
    ),
  });

  const handleCreateReward = () => {
    if (rewardValue.name === "") {
      return Alert("warning", "Warning!", "Name is required");
    }
    if (rewardValue.description === "") {
      return Alert("warning", "Warning!", "Description is required");
    }
    if (rewardValue?.categoryId === "") {
      return Alert("warning", "Warning!", "Category is required");
    }
    if (rewardValue.cost === "") {
      return Alert("warning", "Warning!", "Cost is required");
    }
    if (!image) {
      return Alert("warning", "Warning!", "Image is required");
    }

    const formData = new FormData();
    formData.append("name", rewardValue.name);
    formData.append("description", rewardValue.description);
    formData.append("categoryId", rewardValue.categoryId);
    formData.append("cost", rewardValue.cost);
    formData.append("image", image);

    SecurePostMultipart({
      url: `${apis.CREATE_REWARD}`,
      data: formData,
    })
      .then((response) => {
        if (response.data.success) {
          Alert("success", "Success", response.data.message);
          setRefetchRewards(!reFectchRewards);
          setIsReward(false);
        } else {
          return Alert("warning", "Warning!", response.data.message);
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    ChangeTrainerSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    ChangeTrainerSearchText("");
  };

  useEffect(() => {
    ChangeStudentTableData();
  }, []);

  const { Title } = Typography;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (text) => text || "N/A",
      //   ...getColumnSearchProps("name"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "25%",
      render: (text) => text || "N/A",
      //   ...getColumnSearchProps("description"),
    },
    {
      title: "Category",
      dataIndex: "categoryId",
      key: "categoryId",
      obj: "obj",
      render: (key, obj) => obj?.categoryId?.name || "N/A",
      //   ...getColumnSearchProps("categoryId"),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (text) => text || "N/A",
      //   ...getColumnSearchProps("cost"),
    },

    {
      title: "Action",
      key: "_id",
      dataIndex: "_id",
      obj: "obj",
      render: (key, obj) => (
        <span>
          <Button
            type="primary"
            shape="circle"
            icon="info-circle"
            onClick={() => {
              setIsRewardInfo(true);
              setRewardObject(obj);
            }}
          />
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure？"
            cancelText="No"
            okText="Yes"
            onConfirm={() => {
              deleteRewards(key);
            }}
            icon={<Icon type="delete" style={{ color: "red" }} />}
          >
            <Button type="danger" shape="circle" icon="delete" />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="admin-table-container">
        <div
          style={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            style={{ marginBottom: "10px", borderRadius: "30px" }}
            onClick={() => setIsCategory(true)}
          >
            Create Category
          </Button>
          <Button
            type="primary"
            style={{ marginBottom: "10px", borderRadius: "30px" }}
            onClick={() => setIsReward(true)}
          >
            Create Reward
          </Button>
        </div>
        <div className="register-trainer-form-header">
          <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
            Create Rewards
          </Title>
        </div>
        <Table
          bordered={true}
          columns={columns}
          dataSource={rewards}
          size="medium"
          pagination={{ pageSize: 10 }}
          loading={admin.studentTableLoadingStatus}
          rowKey="_id"
          style={{ backgroundColor: "#fff", padding: "10px" }}
        />
      </div>
      <Modal
        visible={isCategory}
        footer={null}
        closable={false}
        // centered
        onCancel={() => setIsCategory(false)}
        className="rewardMModalAdmin"
        width={450}
        style={{
          top: "30%",
          borderRadius: "25px",
          backgroundColor: "#ffff",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={CrossIcon}
              alt=""
              onClick={() => setIsCategory(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography className="cretaCateText">Create Category</Typography>

            <Typography>Name of category</Typography>
            <Input
              value={category1}
              name="answer"
              className="ansInput"
              onChange={(e) => setCategory1(e.target.value)}
              style={{
                backgroundColor: "white",
                width: "inherit",
              }}
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Button
              style={{ width: "120px", borderRadius: "60PX" }}
              type="primary"
              onClick={handleCreateCategory}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={isReward}
        footer={null}
        closable={false}
        // centered
        onCancel={() => setIsReward(false)}
        className="rewardMModalAdmin"
        width={525}
        style={{
          top: "10%",
          borderRadius: "25px",
          backgroundColor: "#ffff",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={CrossIcon}
              alt=""
              onClick={() => setIsReward(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography className="cretaCateText">Create Reward</Typography>

            <Typography>Name</Typography>
            <Input
              //   value={this.state.customAnswer}
              name="name"
              className="ansInput"
              style={{
                backgroundColor: "white",
                width: "inherit",
              }}
              value={rewardValue.name}
              onChange={handleChangeReward}
              required
            />
            <Typography>Description</Typography>
            <Input
              //   value={this.state.customAnswer}
              name="description"
              className="ansInput"
              style={{
                backgroundColor: "white",
                width: "inherit",
              }}
              value={rewardValue.description}
              onChange={handleChangeReward}
              required
            />
            <Typography>Category</Typography>

            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Level"
              className="ansInput"
              optionFilterProp="s"
              required
            >
              {category?.map((d, i) => (
                <Select.Option
                  // className="input_editProfile"
                  key={d._id}
                  s={d.name}
                  value={d._id}
                  onClick={() =>
                    setRewardValue({
                      ...rewardValue,
                      categoryId: d._id,
                    })
                  }
                >
                  {d.name}
                </Select.Option>
              ))}
            </Select>
            <Typography>Cost</Typography>
            <Input
              value={rewardValue.cost}
              name="cost"
              className="ansInput"
              style={{
                backgroundColor: "white",
                width: "inherit",
              }}
              required
              onChange={handleChangeReward}
            />
            <div>
              <Typography className="uploadText">upload image</Typography>
              <label style={{ cursor: "pointer" }} className="fileUpload">
                <img src={UploadIcon} alt="" />
                <input
                  onChange={handleImageChage}
                  type="file"
                  accept="image/*"
                  style={{ width: "0%" }}
                />
              </label>
              {image && <Typography>{image.name}</Typography>}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Button
              style={{ width: "120px", borderRadius: "60PX" }}
              type="primary"
              onClick={handleCreateReward}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={isRewardInfo}
        footer={null}
        closable={false}
        // centered
        onCancel={() => setIsRewardInfo(false)}
        className="rewardMModalAdmin"
        width={450}
        style={{
          top: "30%",
          borderRadius: "25px",
          backgroundColor: "#ffff",
        }}
      >
        <div className="reward_Card">
          <div className="rewardImgBox">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                width={"60%"}
                // height={height <= 760 ? "100px" : height * 0.16151515151}
                src={apis.IMGAE_BASE_URL + rewardObject?.imgUrl}
                alt=""
              />
            </div>
            <Typography className="rewardTitle">
              {rewardObject?.name}
            </Typography>
            <Typography className="rewardDesc">
              {rewardObject?.description}
            </Typography>
          </div>
          <div className="rewardPointsBox">
            <div
              style={{
                display: "flex",
                gap: 2,
                alignItems: "center",
              }}
            >
              <img width={"23px"} src={Diamond} alt="" />
              <Typography className="rewardsPoints">
                {rewardObject?.cost} points
              </Typography>
            </div>
            <Button
              type="primary"
              className="reedemButton"
              onClick={() => setIsRewardInfo(false)}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  category: state.category.category,
  rewards: state.rewards.rewards,
});

export default connect(mapStateToProps, {
  ChangeTrainerSearchText,
  ChangeStudentTableData,
  ChangeTrainerModalState,
  GetCategory,
  GetRewards,
})(AllRewards);
