import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge, Icon, Button, Row, Col, Typography } from "antd";
import "./portal.css";
import { switchQuestion } from "../../../actions/traineeAction";

class Operations extends Component {
  render() {
    return (
      <div
        style={{
          padding: "5px",
          overflowY: "scroll",
          minHeight: "60vh",
          overflowX: "hidden",
        }}
        className="question-list-wrapper"
      >
        <div className="question-list-inner-new">
          <Row>
            <Row>
              <Col span={12} style={{ padding: "10px" }}>
                <Typography className="qDf">Question No</Typography>
              </Col>
              <Col span={12} style={{ padding: "10px" }}>
                <Typography className="qDf">Status</Typography>
              </Col>
            </Row>
            {this.props.trainee.answers.map((d, i) => {
              return (
                <Row style={{ borderBottom: "1px solid #B4B4B4" }} key={i}>
                  <Col span={12} style={{ padding: "0px 10px" }}>
                    <Mark
                      qid={d.questionid}
                      ans={d.isAnswered}
                      mark={d.isMarked}
                      no={i}
                      activeIndex={this.props.trainee.activeQuestionIndex}
                    />
                  </Col>
                  <Col span={12} style={{ padding: "0px 10px" }}>
                    <Typography className="qDf_inner">
                      {d.isAnswered ? "Completed" : "Incomplete"}
                    </Typography>
                  </Col>
                </Row>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
}

function mark(props) {
  if (props.mark) {
    if (props.ans) {
      return (
        <Badge
          className="qb"
          count={
            <Icon type="flag" theme="filled" style={{ color: "#f5222d" }} />
          }
        >
          <Button
            onClick={() => props.switchQuestion(props.no)}
            style={{ background: "#465D72", color: "#fff" }}
          >
            {props.no + 1}
          </Button>
        </Badge>
      );
    } else {
      return (
        <Badge
          className="qb"
          count={
            <Icon type="flag" theme="filled" style={{ color: "#f5222d" }} />
          }
        >
          <Button
            onClick={() => props.switchQuestion(props.no)}
            style={{ background: "#999999", color: "#fff" }}
          >
            {props.no + 1}
          </Button>
        </Badge>
      );
    }
  } else {
    if (props.ans) {
      return (
        <div>
          <Typography
            onClick={() => props.switchQuestion(props.no)}
            className="qDf_inner"
            style={{
              fontWeight: props.activeIndex === props.no ? 900 : "",
            }}
          >
            {props.no + 1}
          </Typography>
          {/* <Button
            onClick={() => props.switchQuestion(props.no)}
            className="qb"
            style={{ background: "#465D72", color: "#fff" }}
          >
            {props.no + 1}
          </Button> */}
        </div>
      );
    } else {
      return (
        <div>
          <Typography
            onClick={() => props.switchQuestion(props.no)}
            className="qDf_inner"
            style={{
              fontWeight: props.activeIndex === props.no ? 900 : "",
            }}
          >
            {props.no + 1}
          </Typography>
          {/* <Button
            onClick={() => props.switchQuestion(props.no)}
            className="qb"
            style={{ background: "#999999", color: "#fff" }}
          >
            {props.no + 1}
          </Button> */}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

let Mark = connect(mapStateToProps, {
  switchQuestion,
})(mark);

export default connect(mapStateToProps, null)(Operations);
