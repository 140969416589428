import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Input,
  Button,
  Icon,
  Typography,
  Modal,
  Tag,
  Tabs,
  Pagination,
  Spin,
} from "antd";

import Highlighter from "react-highlight-words";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";

import {
  StudentHistoreyTableData,
  ChangeTestSearchText,
  ChangeTestDetailsModalState,
  ProceedtoTest,
  fetchTestdata,
  setTestDetsils,
  fetchTraineedata,
} from "../../../actions/traineeAction";
import TestDetails from "../../trainer/testdetails/testdetails";
import {
  givenLevels,
  givenSchools,
  givenYears,
  paper,
  terms,
} from "../../common/constants";
import { truncateString } from "../examPortal/TestBoardHeaderTest";
import { useWindowDimensions } from "../examPortal/testBoard";
import { getRowsTable } from "../utils";
import AuthServices from "../../../services/AuthServices";
import { login } from "../../../actions/loginAction";
import { Post } from "../../../services/axiosCall";
import apis from "../../../services/Apis";

const StudentHistorey = (props) => {
  const searchInputRef = useRef(null);
  const { history, trainee } = props;
  const { height, width } = useWindowDimensions();

  const rows = getRowsTable(height);

  const componentRef = useRef(null);
  const [mainlink, setMainlink] = useState("");
  const { TabPane } = Tabs;
  const [showData, setShowData] = useState({});
  const [filterQuiries, setFilterQuiries] = useState([]);
  const [filterQuiries1, setFilterQuiries1] = useState([]);

  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  //   const [allStudentData, setAllStudentData] = useState(trainee?.allStudentTest);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [takeTestIdData, setTakeTestIdData] = useState({});

  const handleClick = (e) => {
    const btnId = e.target.dataset.id;
    setShowData({ [btnId]: true });
  };
  const handleOpen = (key, obj) => {
    setTakeTestIdData({ key, obj });
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (props.title) props.StudentHistoreyTableData({ title: props.title });
  }, [props.title]);

  const openModal = (id) => {
    props.ChangeTestDetailsModalState(true, id);
  };

  const closeModal = () => {
    props.ChangeTestDetailsModalState(false, null);
  };
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        e.target.innerText !== "Review Test" &&
        !givenLevels.find((item) => item.label === e.target.innerText) &&
        !givenYears.find((item) => item.label === e.target.innerText) &&
        !terms.find((item) => item.label === e.target.innerText) &&
        !givenSchools.find((item) => item.label === e.target.innerText) &&
        !paper.find((item) => item.label === e.target.innerText)
      ) {
        if (componentRef.current && !componentRef.current.contains(e.target)) {
          setShowData({});
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      AuthServices.FetchAuth(t).then((response) => {
        props.login(response.data.user);
      });
    } else {
      window.location = "/";
    }
  }, []);

  const handleFilterQuiries = (e, name) => {
    const filterString = e.target.dataset.id;
    setShowData({});
    setSortedData([]);

    // Check if a filter with the same name already exists
    const existingFilter = filterQuiries1.find(
      (filter) => Object.keys(filter)[0] === name
    );

    if (existingFilter) {
      // Update the value of the existing filter
      setFilterQuiries1(
        filterQuiries1.map((filter) =>
          Object.keys(filter)[0] === name
            ? { [name]: e.target.dataset.id }
            : filter
        )
      );
    } else {
      // Add a new entry for the filter
      setFilterQuiries1([...filterQuiries1, { [name]: e.target.dataset.id }]);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = rows;

  const data =
    sortedData.length > 0
      ? sortedData
      : trainee?.studentHistorey?.filter((record) =>
          filterQuiries1
            ?.map((obj) => Object.values(obj)[0])
            ?.every((filter) =>
              Object?.values(record)?.some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(filter?.toLowerCase())
              )
            )
        ) || [];

  // Function to handle pagination changes
  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const paginationConfig = {
    // showSizeChanger: true,
    defaultCurrent: 1,
    defaultPageSize: pageSize,
    pageSize: pageSize,
    total: data.length,
    current: currentPage,
    onChange: handlePaginationChange,
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleData = data.slice(startIndex, endIndex);
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();

  // const handleFilterQuiries = (e, name) => {

  //   const filterString = e.target.dataset.id;
  //   setShowData({});
  //   setSortedData([]);

  //   setFilterQuiries1([...filterQuiries1, { [name]: e.target.dataset.id }]);
  //   setFilterQuiries([...filterQuiries, filterString]);
  // };

  const handleFetchTestDataFirst = (data) => {
    props.ProceedtoTest(
      takeTestIdData?.key,
      takeTestIdData?.obj?.studentId,
      () => {
        props.fetchTestdata(
          takeTestIdData?.key,
          takeTestIdData?.obj?.studentId
        );
      }
    );
    props.setTestDetsils(takeTestIdData?.key, takeTestIdData?.obj?.studentId);
    props.fetchTraineedata(takeTestIdData?.obj?.studentId);
    setIsLoader(true);

    let pAnswere = Post({
      url: `${apis.FETCH_TRAINEE_TEST_ANSWERSHEET}`,
      data: {
        testid: takeTestIdData?.key,
        userid: takeTestIdData?.obj?.studentId,
      },
    });

    let pquestions = Post({
      url: `${apis.FETCH_TRAINEE_TEST_QUESTION}`,
      data: {
        id: takeTestIdData?.key,
      },
    });

    Promise.all([pAnswere, pquestions])
      .then((r) => {
        if (r[0].data.success && r[1].data.success) {
          var d = r[0]?.data?.data?.answers?.map((d, i) => {
            if (d.chosenOption.length !== 0) {
              return { ...d, isMarked: false, isAnswered: true };
            } else if (d.customAnswer !== undefined && d.customAnswer !== "") {
              return { ...d, isMarked: false, isAnswered: true };
            } else return { ...d, isMarked: false, isAnswered: false };
          });
          console.log("ddd", d);
          dispatch({
            type: "UPDATE_TRAINEE_TEST_ANSWERSHEET",
            payload: d || [],
          });
          dispatch({
            type: "UPDATE_TRAINEE_TEST_QUESTIONS",
            payload: r[1].data.data,
            payload1: r[1].data.title,
          });
          props.fetchTestdata(
            takeTestIdData?.key,
            takeTestIdData?.obj?.studentId
          );
          setIsLoader(true);
          history.push({
            pathname: `/trainee/taketest`,
            search: `?testid=${takeTestIdData?.key}&traineeid=${takeTestIdData?.obj?.studentId}&isCorrect=${data.isCorrect}`,
            state: { isCorrect: data.isCorrect },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error.response
          ? error.response.data.message
          : error.message;
        // return Alert("error", "Error!", message);
      });
  };

  const handleSort = (columnName) => {
    const sortedData = [...trainee?.studentHistorey];
    setFilterQuiries1([]);

    sortedData.sort((a, b) => {
      const valueA = a[columnName] || "";
      const valueB = b[columnName] || "";

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });

    // Reverse the sorted data if the column is already sorted in ascending order
    if (sortOrder === "asc") {
      sortedData.reverse();
    }

    // Update the sorted data and column sortOrder in the state
    setSortedData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<Icon type="search" />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[props.trainee.TestsearchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    props.ChangeTestSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    props.ChangeTestSearchText("");
  };

  const { Title } = Typography;

  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: "18%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {truncateString(text, 15) || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("title"),
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{
            backgroundColor: "transparent",
            color: "white",
            textTransform: "capitalize",
          }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("level"),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("year"),
    },
    {
      title: "Term",
      dataIndex: "term",
      key: "term",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("term"),
    },
    {
      title: "School",
      dataIndex: "school",
      key: "school",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("school"),
    },
    {
      title: "Paper",
      dataIndex: "exam",
      key: "paper",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("paper"),
    },
    {
      title: "Status",
      width: "10%",

      dataIndex: "markedStatus",
      key: "markedStatus",
      render: (text) => text || "N/A",
      // ...getColumnSearchProps("type"),
    },
    {
      title: "Marks",
      key: "_id",
      dataIndex: "_id",
      // width: "10%",
      render: (key, obj) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {obj.markedStatus === "Pending"
            ? "N/A"
            : obj?.obtainedMarks + "/" + obj?.totalMarks || "N/A"}
        </div>
      ),
    },

    {
      title: "",
      key: "_id",
      dataIndex: "_id",
      render: (key, obj) => (
        <span>
          {key && (
            <Button
              style={{
                borderRadius: "35px",
                backgroundColor: "#0082fb",
              }}
              type="primary"
              onClick={() => {
                handleOpen(key, obj);
              }}
            >
              Review Test
            </Button>
          )}
        </span>
      ),
    },
  ];

  const rowClassNameFn = (record, index) => {
    return index % 2 === 0 ? "even_row" : "odd_row";
  };
  return (
    <>
      <div className="custom_trainee_table">
        <div className="sorting_Buttons">
          <div
            data-id={"Level"}
            className="sortButton"
            ref={componentRef}
            onClick={handleClick}
          >
            <Typography data-id={"Level"} className="sortText">
              {filterQuiries1.find((item) => Object.keys(item)[0] === "Level")
                ? filterQuiries1.find(
                    (item) => Object.keys(item)[0] === "Level"
                  ).Level
                : "Level"}
            </Typography>
            <div className="icons" onClick={() => handleSort("level")}>
              <Icon style={{ fontSize: "10px" }} type="up" />
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>
            {showData?.Level && (
              <div className="showData">
                {givenLevels.map((item) => (
                  <Typography
                    data-id={item.value}
                    onClick={(e) => handleFilterQuiries(e, "Level")}
                    name="Level"
                    style={{ color: "white" }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          <div
            data-id={"Year"}
            className="sortButton"
            ref={componentRef}
            onClick={handleClick}
          >
            <Typography data-id={"Year"} className="sortText">
              {filterQuiries1.find((item) => Object.keys(item)[0] === "Year")
                ? filterQuiries1.find((item) => Object.keys(item)[0] === "Year")
                    .Year
                : "Year"}
            </Typography>
            <div onClick={() => handleSort("year")} className="icons">
              <Icon style={{ fontSize: "10px" }} type="up" />{" "}
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>
            {showData?.Year && (
              <div className="showData">
                {givenYears.map((item) => (
                  <Typography
                    data-id={item.value}
                    onClick={(e) => handleFilterQuiries(e, "Year")}
                    name="Year"
                    style={{ color: "white" }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          <div
            data-id={"Term"}
            ref={componentRef}
            className="sortButton"
            onClick={handleClick}
          >
            <Typography data-id={"Term"} className="sortText">
              {filterQuiries1.find((item) => Object.keys(item)[0] === "Term")
                ? filterQuiries1.find((item) => Object.keys(item)[0] === "Term")
                    .Term
                : "Term"}
            </Typography>
            <div onClick={() => handleSort("term")} className="icons">
              <Icon style={{ fontSize: "10px" }} type="up" />{" "}
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>
            {showData?.Term && (
              <div className="showData">
                {terms.map((item) => (
                  <Typography
                    data-id={item.value}
                    name="Term"
                    onClick={(e) => handleFilterQuiries(e, "Term")}
                    style={{
                      color: "white",
                    }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          <div
            data-id={"School"}
            ref={componentRef}
            className="sortButton"
            onClick={handleClick}
          >
            <Typography data-id={"School"} className="sortText">
              {filterQuiries1.find((item) => Object.keys(item)[0] === "School")
                ? filterQuiries1.find(
                    (item) => Object.keys(item)[0] === "School"
                  ).School
                : "School"}
            </Typography>
            <div onClick={() => handleSort("school")} className="icons">
              <Icon style={{ fontSize: "10px" }} type="up" />{" "}
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>

            {showData?.School && (
              <div className="showData">
                {givenSchools.map((item) => (
                  <Typography
                    data-id={item.value}
                    name="School"
                    onClick={(e) => handleFilterQuiries(e, "School")}
                    style={{ color: "white" }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          <div
            className="sortButton"
            ref={componentRef}
            onClick={handleClick}
            data-id={"Paper"}
          >
            <Typography data-id={"Paper"} className="sortText">
              {filterQuiries1.find((item) => Object.keys(item)[0] === "Paper")
                ? filterQuiries1.find(
                    (item) => Object.keys(item)[0] === "Paper"
                  ).Paper
                : "Paper"}
            </Typography>
            <div className="icons" onClick={() => handleSort("exam")}>
              <Icon style={{ fontSize: "10px" }} type="up" />{" "}
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>
            {showData?.Paper && (
              <div className="showData">
                {paper.map((item) => (
                  <Typography
                    data-id={item.value}
                    name="Paper"
                    onClick={(e) => handleFilterQuiries(e, "Paper")}
                    style={{ color: "white" }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "55vh",
            height: "100%",
          }}
        >
          <Table
            columns={columns}
            // pagination={{ pageSize: 4 }}
            dataSource={visibleData}
            size="medium"
            loading={trainee.studentHistoreyLoading}
            rowKey="_id"
            style={{
              backgroundColor: "transparent",
              color: "white !important",
            }}
            rowClassName={rowClassNameFn}
            components={{
              header: {
                cell: (props) => (
                  <th
                    className="custom_table_header"
                    style={{
                      backgroundColor: "transparent", // Set your desired header background color
                      color: "white", // Set the font color
                      fontWeight: "bold", // Set the font weight
                    }}
                    {...props}
                  />
                ),
              },
            }}
            className="custom_tables"
          />
          <Pagination
            style={{ display: "flex", justifyContent: "flex-end" }}
            {...paginationConfig}
          />
        </div>
      </div>
      <Modal
        visible={isModalOpen}
        footer={null}
        closable={false}
        onCancel={handleCancel}
        centered
        width={350}
        style={{ borderRadius: "25px" }}
      >
        {isLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <p className="modalTitle">
              Which review option do you wish to choose:
            </p>
            <div className="modalButtons">
              <Button
                className="modalButtonNo"
                onClick={() => {
                  // props.ProceedtoTest(
                  //   takeTestIdData?.key,
                  //   takeTestIdData?.obj?.studentId,
                  //   () => {
                  //     props.fetchTestdata(
                  //       takeTestIdData?.key,
                  //       takeTestIdData?.obj?.studentId
                  //     );
                  //   }
                  // );
                  // history.push({
                  //   pathname: `/trainee/taketest`,
                  //   search: `?testid=${takeTestIdData?.key}&traineeid=${
                  //     takeTestIdData?.obj?.studentId
                  //   }&isCorrect=${true}`,
                  //   state: { isCorrect: true },
                  // });
                  handleFetchTestDataFirst({ isCorrect: true });
                }}
              >
                Full Review
              </Button>
              <Button
                className="modalButtonNo"
                onClick={() => {
                  // props.ProceedtoTest(
                  //   takeTestIdData?.key,
                  //   takeTestIdData?.obj?.studentId,
                  //   () => {
                  //     props.fetchTestdata(
                  //       takeTestIdData?.key,
                  //       takeTestIdData?.obj?.studentId
                  //     );
                  //   }
                  // );
                  // history.push({
                  //   pathname: `/trainee/taketest`,
                  //   search: `?testid=${takeTestIdData?.key}&traineeid=${
                  //     takeTestIdData?.obj?.studentId
                  //   }&isCorrect=${false}`,
                  //   state: { isCorrect: false },
                  // });
                  handleFetchTestDataFirst({ isCorrect: false });
                }}
              >
                Correction Review
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    trainee: state.trainee,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    ChangeTestSearchText,
    StudentHistoreyTableData,
    ChangeTestDetailsModalState,
    ProceedtoTest,
    fetchTraineedata,
    fetchTestdata,
    login,
    setTestDetsils,
  })(StudentHistorey)
);
