import React, { Component } from "react";
import { connect } from "react-redux";
import {
  LocaltestDone,
  fetchTestdata,
  switchQuestion,
} from "../../../actions/traineeAction";
import "./portal.css";
import apis from "../../../services/Apis";
import { Post, SecurePost } from "../../../services/axiosCall";
import Alert from "../../common/alert";
import { Button, Col, Modal, Progress, Row, Spin, Typography } from "antd";
import { withRouter } from "react-router-dom";

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localMinutes: this.props.trainee.m_left,
      localSeconds: this.props.trainee.s_left,
      isQuickResultModalOpen: false,
      isCorrectionReview: false,
      qickResult: {},
    };
  }
  componentDidMount() {
    this.clockF();
  }

  handleQuickResultOpen = () => {
    this.setState({ isQuickResultModalOpen: true });
    this.props.trainee.socket.current.disconnect();
    this.endTest();
  };

  // handleQuickResultCancel = () => {
  //   this.setState({ isQuickResultModalOpen: false });
  // };

  handleTestComplete = () => {
    this.props.fetchTestdata(
      this.props.trainee.testid,
      this.props.trainee.traineeid
    );
  };

  handleReveiwOpen = () => {
    this.setState({ isCorrectionReview: true });
    this.setState({ isQuickResultModalOpen: false });
  };

  handleReveiwClose = () => {
    this.setState({ isCorrectionReview: false });
    this.setState({ isQuickResultModalOpen: true });
  };

  endTest = () => {
    SecurePost({
      url: `${apis.END_TEST}`,
      data: {
        testid: this.props.trainee.testid,
        userid: this.props.trainee.traineeid,
      },
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({ qickResult: response.data.data });

          // this.props.fetchTestdata(
          //   this.props.trainee.testid,
          //   this.props.trainee.traineeid
          // );
        } else {
          return Alert("error", "Error!", response.data.message);
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  clockF = () => {
    let c = setInterval(() => {
      let l = this.props.trainee.m_left;
      let s = this.props.trainee.s_left;
      if (l === 0 && s === 1) {
        clearInterval(c);
        // this.props.setTimerFinish(true);
        // this.endTest();
        this.handleQuickResultOpen();
      } else {
        if (s === 0) {
          s = 59;
          l--;
        } else s--;

        // this.setState({ localMinutes: l, localSeconds: s });
      }
    }, 1000);
  };

  componentWillUnmount() {}

  render() {
    const { history } = this.props;

    return (
      <>
        <div
          style={{
            textAlign: "center",
          }}
          className="clock-container-new"
        >
          {this.props.trainee.m_left}:{this.props.trainee.s_left}
        </div>
        <Modal
          visible={this.state.isQuickResultModalOpen}
          footer={null}
          closable={false}
          centered
          // onCancel={this.handleQuickResultCancel}
          width={550}
          style={{ borderRadius: "25px", zIndex: 999999 }}
        >
          {Object.keys(this.state?.qickResult).length !== 0 ? (
            <div
              style={{
                widthL: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Row>
                <Col span={12}>
                  <div className="quickResultModalParrent">
                    <Typography className="quickResultText">
                      Your <br /> Marks
                    </Typography>
                    <div
                      className={
                        Object.keys(this.state?.qickResult).length !== 0
                          ? "custom-progress"
                          : "custom-progress curcularProgressEmptyFill"
                      }
                    >
                      <Progress
                        type="circle"
                        percent={
                          Object.keys(this.state?.qickResult).length !== 0
                            ? this.state?.qickResult?.studentScorePercentage
                            : 0
                        }
                        strokeColor="#FAFF1C" // Change the color of the progress
                        strokeWidth={10} // Change the width of the progress
                        trailColor="#d3d3d3" // Change the color of the trail
                        trailWidth={10} // Change the width of the trail
                        strokeStyle={{
                          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow to the progress
                        }}
                        format={() => (
                          <span className="progress-text-quickResult">
                            {Object.keys(this.state?.qickResult).length !== 0
                              ? this.state?.qickResult?.studentScore +
                                "/" +
                                this.state?.qickResult?.totalMarks
                              : "N/A"}
                          </span>
                        )}
                      />
                    </div>
                    <Typography
                      style={{ fontWeight: 400 }}
                      className="quickResultText"
                    >
                      {Object.keys(this.state?.qickResult).length !== 0
                        ? this.state?.qickResult?.studentScorePercentage + "%"
                        : "N/A"}
                      <br />
                      {Object.keys(this.state?.qickResult).length !== 0
                        ? this.state?.qickResult?.studentGrade
                        : "N/A"}
                    </Typography>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="quickResultModalParrent">
                    <Typography className="quickResultText">
                      Average <br /> Marks
                    </Typography>
                    <div
                      className={
                        Object.keys(this.state?.qickResult).length !== 0
                          ? "custom-progress"
                          : "custom-progress curcularProgressEmptyFill"
                      }
                    >
                      <Progress
                        type="circle"
                        percent={
                          Object.keys(this.state?.qickResult).length !== 0
                            ? this.state?.qickResult?.allStudentsMarksPercent
                            : 0
                        }
                        strokeColor="#FAFF1C" // Change the color of the progress
                        strokeWidth={10} // Change the width of the progress
                        trailColor="#d3d3d3" // Change the color of the trail
                        trailWidth={10} // Change the width of the trail
                        strokeStyle={{
                          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow to the progress
                        }}
                        format={() => (
                          <span className="progress-text-quickResult">
                            {Object.keys(this.state?.qickResult).length !== 0
                              ? this.state?.qickResult
                                  ?.allStudentsMarksAverage +
                                "/" +
                                this.state?.qickResult?.totalMarks
                              : "N/A"}
                          </span>
                        )}
                      />
                    </div>
                    <Typography
                      style={{ fontWeight: 400 }}
                      className="quickResultText"
                    >
                      {Object.keys(this.state?.qickResult).length !== 0
                        ? this.state?.qickResult?.allStudentsMarksPercent + "%"
                        : "N/A"}
                      <br />
                      {Object.keys(this.state?.qickResult).length !== 0
                        ? this.state?.qickResult?.totalGrade
                        : "N/A"}
                    </Typography>
                  </div>{" "}
                </Col>
              </Row>
              <Row justify="center">
                <Col offset={5} span={14}>
                  <div className="modalButtons">
                    <Button
                      className="modalButtonNo"
                      onClick={this.handleReveiwOpen}
                    >
                      Review Test
                    </Button>
                    <Button
                      className="modalButtonNo"
                      onClick={() => {
                        this.handleTestComplete();
                        history.push("/user/livetest");
                      }}
                    >
                      Return to Take Test page
                    </Button>
                    <Button
                      className="modalButtonNo"
                      onClick={() => {
                        this.handleTestComplete();

                        history.push("/user/testHistory");
                      }}
                    >
                      Return to Test History
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" />
            </div>
          )}
        </Modal>
        <Modal
          visible={this.state.isCorrectionReview}
          footer={null}
          closable={false}
          centered
          onCancel={this.handleReveiwClose}
          width={350}
          style={{ borderRadius: "25px", zIndex: 999999 }}
        >
          <p className="modalTitle">
            Which review option do you wish to choose:
          </p>
          <div className="modalButtons">
            <Button
              className="modalButtonNo"
              onClick={() => {
                this.handleTestComplete();
                this.props.switchQuestion(0);
              }}
            >
              Full Review
            </Button>
            <Button
              className="modalButtonNo"
              onClick={() => {
                this.handleTestComplete();
                this.props.switchQuestion(0);
                history.push({
                  pathname: `/trainee/taketest`,
                  search: `?testid=${this.props.trainee.testid}&traineeid=${
                    this.props.trainee.traineeid
                  }&isCorrect=${false}`,
                  state: { isCorrect: false },
                });
              }}
            >
              Correction Review
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default withRouter(
  connect(mapStateToProps, {
    LocaltestDone,
    fetchTestdata,
    switchQuestion,
  })(Clock)
);
