import apis from "../services/Apis";
import Alert from "../components/common/alert";
import { SecureGet, SecurePost } from "../services/axiosCall";

export const ChangeQuestionModalState = (d1) => (dispatch) => {
  dispatch({
    type: "CHANGE_QUESTION_MODAL_STATE",
    payload1: d1,
  });
};

export const ChangeQuestionConfirmDirty = (d) => (dispatch) => {
  dispatch({
    type: "CHANGE_QUESTION_FORM_CONFIRMDIRTY",
    payload: d,
  });
};

export const ChangeQuestionSearchText = (d) => (dispatch) => {
  dispatch({
    type: "CHANGE_QUESTION_SEARCH_TEXT",
    payload: d,
  });
};

export const ChangeQuestionTableData = (d, l, p, data) => (dispatch) => {
  dispatch({
    type: "CHANGE_QUESTION_TABLE_LOADING_STATUS",
    payload1: true,
    payload2: [],
  });
  SecurePost({
    url: `${apis.GET_ALL_QUESTIONS}`,
    data: {
      subject: d,
      limit: l,
      page: p,
      level: data?.level,
      component: data?.component,
      school: data?.school,
      year: data?.year,
      difficulity: data?.difficulty,
      exam: data?.exam,
    },
  })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "CHANGE_QUESTION_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: response.data.data,
          payload3: response.data.totalCount,
        });
      } else {
        Alert("error", "Error!", response.data.message);
        dispatch({
          type: "CHANGE_QUESTION_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: [],
        });
      }
    })
    .catch((error) => {
      // Alert("error", "Error!", error.message);
      dispatch({
        type: "CHANGE_QUESTION_TABLE_LOADING_STATUS",
        payload1: false,
        payload2: [],
      });
      const message = error.response
        ? error.response.data.message
        : error.message;
      return Alert("error", "Error!", message);
    });
};

export const ChangeSelectedSubjects = (d) => (dispatch) => {
  dispatch({
    type: "CHANGE_SELECTED_SUBJECT",
    payload: d,
  });
};

export const ChangeQuestionFormData = (d) => (dispatch) => {
  dispatch({
    type: "CHANGE_QUESTION_FORM_DATA",
    payload: d,
  });
};

export const AddFifthOptionInQuestion = () => (dispatch) => {
  dispatch({
    type: "ADD_FIFTH_OPTION",
  });
};

export const ChangeTestDetailsModalState = (d1, d2) => (dispatch) => {
  dispatch({
    type: "CHANGE_TEST_DETAILS_MODAL_STATE",
    payload1: d1,
    payload2: d2,
  });
};

export const ChangeTestSearchText = (d) => (dispatch) => {
  dispatch({
    type: "CHANGE_TEST_SEARCH_TEXT",
    payload: d,
  });
};

export const ChangeTestTableData = () => (dispatch) => {
  dispatch({
    type: "CHANGE_TEST_TABLE_LOADING_STATUS",
    payload1: true,
    payload2: [],
  });
  SecurePost({
    url: `${apis.GET_ALL_TESTS}`,
  })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "CHANGE_TEST_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: response.data.data,
        });
      } else {
        Alert("error", "Error!", response.data.message);
        dispatch({
          type: "CHANGE_TEST_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: [],
        });
      }
    })
    .catch((error) => {
      console.log(error);
      // Alert("error", "Error!", error.message);
      dispatch({
        type: "CHANGE_TEST_TABLE_LOADING_STATUS",
        payload1: false,
        payload2: [],
      });
      const message = error.response
        ? error.response.data.message
        : error.message;
      return Alert("error", "Error!", message);
    });
};

export const ChangeConsultatationTableData = () => (dispatch) => {
  dispatch({
    type: "CHANGE_CONSULTATION_TABLE_LOADING_STATUS",
    payload1: true,
    payload2: [],
  });
  SecureGet({
    url: `${apis.GET_ALL_CONSULTATION_TUTOR}`,
  })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "CHANGE_CONSULTATION_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: response.data.data,
        });
      } else {
        Alert("error", "Error!", response.data.message);
        dispatch({
          type: "CHANGE_CONSULTATION_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: [],
        });
      }
    })
    .catch((error) => {
      console.log(error);
      // Alert("error", "Error!", error.message);
      dispatch({
        type: "CHANGE_CONSULTATION_TABLE_LOADING_STATUS",
        payload1: false,
        payload2: [],
      });
      const message = error.response
        ? error.response.data.message
        : error.message;
      return Alert("error", "Error!", message);
    });
};

export const updateQuestiosnActiveTest = (d) => {
  return {
    type: "CHANGE_CURRENT_ACTIVE_TEST_QUESTION",
    payload: d,
  };
};

export const GetAllTest = () => (dispatch) => {
  dispatch({
    type: "CHANGE_TEST_LOADING_STATUS",
    payload1: true,
    payload2: [],
  });
  SecurePost({
    url: `${apis.GET_ALL_TESTS_NAME}`,
  })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "CHANGE_TEST_LOADING_STATUS",
          payload1: false,
          payload2: response.data.data,
        });
      } else {
        Alert("error", "Error!", response.data.message);
        dispatch({
          type: "CHANGE_TEST_LOADING_STATUS",
          payload1: false,
          payload2: [],
        });
      }
    })
    .catch((error) => {
      console.log(error);
      const message = error.response
        ? error.response.data.message
        : error.message;
      Alert("error", "Error!", message);
      // Alert("error", "Error!", error.message);
      dispatch({
        type: "CHANGE_TEST_LOADING_STATUS",
        payload1: false,
        payload2: [],
      });
    });
};

export const ChangeAssignTestModalState = (d1, d2, d3) => (dispatch) => {
  // if (d3 === "Save Changes") {
  //   SecureGet({
  //     url: `${apis.GET_SINGLE_TRAINER_DETAILS}/${d2}`,
  //   })
  //     .then((response) => {
  //       if (response.data.success) {
  //         dispatch({
  //           type: "CHANGE_TRAINER_MODAL_STATE",
  //           payload1: true,
  //           payload2: d2,
  //           payload3: "Save Changes",
  //           payload4: {
  //             ...response.data.data[0],
  //             contact: response.data.data[0].contact.slice(3),
  //             prefix: response.data.data[0].contact.slice(0, 3),
  //           },
  //         });
  //       } else {
  //         return Alert("warning", "Warning!", response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       return Alert("error", "Error!", "Server Error");
  //     });
  // } else {
  //   dispatch({
  //     type: "CHANGE_TRAINER_MODAL_STATE",
  //     payload1: d1,
  //     payload2: d2,
  //     payload3: d3,
  //     payload4: {
  //       name: null,
  //       emailid: null,
  //       contact: null,
  //       prefix: null,
  //       password: null,
  //       confirmpassword: null,
  //     },
  //   });
  // }
};
