import { Button, Col, Divider, Row, Typography } from "antd";
import React from "react";
import "./index.css";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { faker } from "@faker-js/faker";
import { useEffect } from "react";
import AuthServices from "../../../../services/AuthServices";
import {
  statetistics,
  statetisticsComponent,
} from "../../../../actions/loginAction";

import { connect } from "react-redux";
import { useWindowDimensions } from "../../examPortal/testBoard";
import {
  getGoalGrade,
  getGoalGrades,
  getGrade,
  getGrades,
  getLastThreeTopics,
} from "../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Alert from "../../../common/alert";

const BarContainer = ({ height, color, label }) => {
  const barStyle = {
    height: `${height}%`,
    backgroundColor: color,
  };

  return (
    <div className="progress">
      <div style={{ zIndex: 20 }} className="barcontainer">
        <div className="bar" style={barStyle}></div>
      </div>
      {/* <div className="bar-label">{label}</div> */}
    </div>
  );
};

const CustomBar = (props) => {
  const { x, y, width, height, color1, color2 } = props;

  var fillColor = "#18A0FB"; // Default fill color

  if (
    props?.payload?.name === "MONTHLY AVG." &&
    props?.payload?.paper === "paper1"
  ) {
    fillColor = color1;
  } else if (
    props?.payload?.name === "MONTHLY AVG." &&
    props?.payload?.paper === "paper2"
  ) {
    fillColor = color2;
  }

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={60}
        height={height}
        rx={12} // Adjust the radius as needed
        ry={12} // Adjust the radius as needed
        fill={fillColor}
      />
    </g>
  );
};

const data = [
  {
    name: "MonthLy Average p1",
    uv: 10,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "All-Time Average p1",
    uv: 20,
    pv: 1398,
    amt: 2210,
  },

  {
    name: "",
    uv: 0,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "MonthLy Average p2",
    uv: 10,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "All-Time Average p2",
    uv: 20,
    pv: 1398,
    amt: 2210,
  },
];
const referenceLines = [
  {
    name: "Target Range",
    start: 20,
    end: 40,
    stroke: "green",
  },
  {
    name: "Another Range",
    start: 50,
    end: 70,
    stroke: "blue",
  },
];

const SubjectStatetistics = (props) => {
  const { title, id } = props;
  const history = useHistory();
  const {
    // height,
    width,
  } = useWindowDimensions();

  const { paper1, paper2 } = getGoalGrades(
    getGrades(
      props?.traineestate?.userState?.data?.goal,
      props?.traineestate?.userState?.data?.level
    ),
    title
  );

  const dataa = [
    {
      name: "MONTHLY AVG.",
      value: faker.datatype.number({ min: 0, max: 100 }),
    },
    {
      name: "ALL-TIME AVG.",
      value: faker.datatype.number({ min: 0, max: 100 }),
    },
    { name: "", value: 0 },
    {
      name: "MONTHLY AVG.",
      value: faker.datatype.number({ min: 0, max: 100 }),
    },
    {
      name: "ALL-TIME AVG.",
      value: faker.datatype.number({ min: 0, max: 100 }),
    },
  ];

  const handleGeneratePaper = (paper) => {
    const data = getLastThreeTopics(
      props?.traineestate?.userState?.data?.weakestTopics
    );
    const currentDate = new Date();
    const options = { hour: "numeric", minute: "numeric", hour12: false };
    const formattedDate = `${
      props.user.userDetails.name
    }-${currentDate.toLocaleDateString()}-${currentDate.toLocaleTimeString(
      [],
      options
    )}`;
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined" && title) {
      AuthServices.GeneratePaper(t, title, data, paper, formattedDate)
        .then((response) => {
          if (response.data.success) {
            history.push("/user/livetest");
          }
        })
        .catch((error) => {
          Alert(
            "message",
            "Message!",
            "No Questions to create worksheet in this topic"
          );
        });
    }
  };

  useEffect(() => {
    const t = AuthServices.retriveToken();

    if (t && t !== "undefined" && title) {
      AuthServices.FetchStaistics(t, title).then((response) => {
        props.statetistics(response.data);
      });
      AuthServices.FetchStaisticsComponent(t, title).then((response) => {
        props.statetisticsComponent(response.data, response.dataObj);
      });
    } else {
      window.location = "/";
    }
  }, [title]);

  return (
    <Row
      style={{
        maxWidth: "1500px",
        margin: "auto",
      }}
      gutter={[16, 16]}
    >
      <Col className="gutter-row" md={14} xs={24} span={8}>
        <div className="wrapperBoxState">
          <Typography className="titleBox">TOPICAL STATISTICS</Typography>
          <div className="subjectTopicContainer">
            <Typography className="avgTopicsText">
              Avg. (%) Breakdown of Topics
            </Typography>
            {props?.traineestate?.userState?.data?.topicalStatistics?.length !==
            0 ? (
              <div
                style={{
                  position: "relative",
                  // backgroundColor: "black",
                  height: "140px",
                }}
              >
                <div className="containerState">
                  {props?.traineestate?.userState?.data?.topicalStatistics?.map(
                    (item, index) => (
                      <div className="barParrent">
                        <BarContainer
                          key={index}
                          height={item?.value}
                          color={
                            item.value <=
                              props?.traineestate?.userState?.data?.goal &&
                            item.value > 45
                              ? "#FAFF1C"
                              : item.value >=
                                  props?.traineestate?.userState?.data?.goal &&
                                item.value > 45
                              ? "#67FF0B"
                              : item.value < 45
                              ? "red"
                              : "red"
                          }
                          // color={"red"}
                          label={item.topic}
                        />
                        {item?.topic?.includes("/") ? (
                          <div className="bar-label">
                            {item.topic.split("/").map((item) => item + " ")}
                          </div>
                        ) : (
                          <div className="bar-label">{item.topic}</div>
                        )}

                        <div className="bar-details-box">
                          <div
                            style={{
                              display: "flex",
                              gap: "4px",
                            }}
                          >
                            <Typography className="topicBarHoverText">
                              Topic :
                            </Typography>
                            <Typography className="topicBarHoverText">
                              {item.topic}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "4px",
                            }}
                          >
                            <Typography className="topicBarHoverText">
                              Percentage :
                            </Typography>
                            <Typography className="topicBarHoverText">
                              {item.value}%
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    position: "absolute",
                    // top: "40%",
                    bottom: `${
                      props?.traineestate?.userState?.data?.goal
                        ? props?.traineestate?.userState?.data?.goal * 0.84 +
                          "%"
                        : "0%"
                    }`,
                    // bottom: "84%",

                    width: "100%",
                    right: -30,
                    zIndex: 10,
                  }}
                >
                  <div
                    className="dashed_border"
                    // style={{
                    //   borderTop: "4px dashed white",
                    //   backgroundColor: "transparent",
                    //   width: "100%",
                    //   // borderStyle: "dashed",
                    //   zIndex: 99,
                    // }}
                  ></div>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "white",
                      // margin: 0,
                      marginTop: "12px",
                    }}
                  >
                    Goal
                  </p>
                </div>
              </div>
            ) : (
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                No Data Found
              </Typography>
            )}
          </div>
        </div>
      </Col>
      <Col className="gutter-row" md={10} xs={24} span={8}>
        <div className="wrapperBoxState1">
          <Typography className="titleBox">TOPICAL ANALYSIS</Typography>
          <Row gutter={24}>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        backgroundColor: "white",
                        width: "4px",
                        height: "5px",
                        borderRadius: "4px",
                      }}
                    ></Typography>
                    <Typography className="topicHeading">
                      Strongest Topics
                    </Typography>
                  </div>
                  <Typography className="topicHeading">Avg.</Typography>
                </div>
                {props?.traineestate?.userState?.data?.strongestTopics
                  ?.length !== 0 ? (
                  props?.traineestate?.userState?.data?.strongestTopics?.map(
                    (item, index) => (
                      <>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "2px",
                              width: "80%",
                            }}
                          >
                            <Typography className="topicText1">
                              {index + 1}.
                            </Typography>
                            {item?.topic?.includes("/") ? (
                              <div>
                                <Typography className="topicText">
                                  {item.topic
                                    .split("/")
                                    .map((item) => item + " ")}
                                </Typography>
                              </div>
                            ) : (
                              <Typography className="topicText">
                                {item.topic}
                              </Typography>
                            )}
                          </div>
                          <Typography className="topicText1">
                            {item.value}%
                          </Typography>
                        </div>
                        <Divider className="dividerState" />
                      </>
                    )
                  )
                ) : (
                  <Typography
                    style={{
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    No Data Found
                  </Typography>
                )}
              </div>
            </Col>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        backgroundColor: "white",
                        width: "4px",
                        height: "5px",
                        borderRadius: "4px",
                      }}
                    ></Typography>{" "}
                    <Typography className="topicHeading">
                      Weakest Topics
                    </Typography>
                  </div>
                  <Typography className="topicHeading">Avg.</Typography>
                </div>
                {props?.traineestate?.userState?.data?.weakestTopics?.length !==
                0 ? (
                  props?.traineestate?.userState?.data?.weakestTopics?.map(
                    (item, index) => (
                      <>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "4px",
                              width: "80%",
                            }}
                          >
                            <Typography className="topicText1">
                              {index + 1}.
                            </Typography>
                            {item?.topic?.includes("/") ? (
                              <div>
                                <Typography className="topicText">
                                  {item.topic
                                    .split("/")
                                    .map((item) => item + " ")}
                                </Typography>
                              </div>
                            ) : (
                              <Typography className="topicText">
                                {item.topic}
                              </Typography>
                            )}
                          </div>
                          <Typography className="topicText1">
                            {item.value}%
                          </Typography>
                        </div>
                        <Divider className="dividerState" />
                      </>
                    )
                  )
                ) : (
                  <Typography
                    style={{
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    No Data Found
                  </Typography>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col className="gutter-row" md={14} xs={24} span={8}>
        <div className="wrapperBoxStateComponenet">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="titleBox">
              COMPONENT ANALYSIS | MONTHLY
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
              }}
              className="titleBox"
            >
              Goal ----
            </Typography>
          </div>

          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Typography className="paperTest">
              {title === "Mathematics" ? "PAPER 1" : "Booklet A"}
            </Typography>
            <Typography className="paperTest">
              {" "}
              {title === "Mathematics" ? "PAPER 2" : "Booklet B"}
            </Typography>
          </div>
          <div style={{ width: "90%", margin: "auto" }}>
            <Divider />
          </div>
          <div style={{ position: "relative" }}>
            <div
              style={{
                width: "100%",
                height: "170px",
                // backgroundColor: "black",
                marginLeft: "-30px",
                marginBottom: "-30px",
              }}
            >
              <ResponsiveContainer width={"100%"} height={"100%"}>
                <BarChart
                  // width={730}
                  // height={250}
                  data={props?.traineestatecomponent?.userStateComponent?.data}
                  // margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                >
                  <XAxis
                    dataKey="name"
                    tick={{ fill: "white" }}
                    angle={width > 1140 ? 0 : -25}
                    interval={0}
                    style={{ fontSize: width > 1390 ? "10px" : "6px" }}
                    width={"100%"}
                  />
                  <YAxis
                    tick={{ fill: "white" }} // Set label text color to white
                    domain={[0, 100]} // Set the desired Y-axis range
                  />
                  <Tooltip
                    // viewBox={{ x: 0, y: 0, width: 0, height: 0 }}
                    // wrapperStyle={{
                    //   backgroundColor: "#000 !important",
                    //   color: "white",
                    // }}
                    // itemStyle={{
                    //   backgroundColor: "#000",
                    //   color: "white",
                    // }}
                    // contentStyle={{
                    //   backgroundColor: "#000",
                    //   color: "white",
                    // }}
                    // labelStyle={{
                    //   backgroundColor: "#000",
                    //   color: "white",
                    // }}
                    cursor={{ fill: "transparent" }}
                    // allowEscapeViewBox={{ x: true, y: true }}
                    // active={false}
                    // filterNull={false}
                  />
                  <CartesianGrid
                    stroke="#7E7E7E" // Set grid line color to white
                    vertical={false} // Remove vertical grid lines if needed
                  />
                  <ReferenceLine y={0} stroke="white" />

                  <ReferenceLine
                    stroke="white"
                    strokeDasharray="9 9"
                    strokeWidth={2}
                    position="start"
                    segment={[
                      { x: "MONTHLY AVG. P1", y: paper1 },
                      { x: "", y: paper1 },
                    ]}
                  />
                  <ReferenceLine
                    stroke="white"
                    strokeDasharray="9 9"
                    strokeWidth={2}
                    position="end"
                    style={{
                      marginRight: "-60px",
                    }}
                    segment={[
                      { x: "", y: paper2 },
                      { x: "ALL-TIME AVG. P2", y: paper2 },
                    ]}
                  />
                  <Bar
                    dataKey="value"
                    radius={[12, 12, 0, 0]}
                    barSize={60}
                    // shape={
                    //   <CustomBar
                    //     data={props.payload}
                    //     color1={
                    //       props?.traineestatecomponent?.userStateComponent
                    //         ?.dataObj?.paper1PercentageLast30Days?.value <=
                    //         props?.traineestate?.userState?.data?.goal &&
                    //       props?.traineestatecomponent?.userStateComponent
                    //         ?.dataObj?.paper1PercentageLast30Days?.value > 45
                    //         ? "#FAFF1C"
                    //         : props?.traineestatecomponent?.userStateComponent
                    //             ?.dataObj?.paper1PercentageLast30Days?.value >=
                    //             props?.traineestate?.userState?.data?.goal &&
                    //           props?.traineestatecomponent?.userStateComponent
                    //             ?.dataObj?.paper1PercentageLast30Days?.value >
                    //             45
                    //         ? "#67FF0B"
                    //         : props?.traineestatecomponent?.userStateComponent
                    //             ?.dataObj?.paper1PercentageLast30Days?.value <
                    //           45
                    //         ? "red"
                    //         : "red"
                    //     }
                    //     color2={
                    //       props?.traineestatecomponent?.userStateComponent
                    //         ?.dataObj?.paper2PercentageLast30Days?.value <=
                    //         props?.traineestate?.userState?.data?.goal &&
                    //       props?.traineestatecomponent?.userStateComponent
                    //         ?.dataObj?.paper2PercentageLast30Days?.value > 45
                    //         ? "#FAFF1C"
                    //         : props?.traineestatecomponent?.userStateComponent
                    //             ?.dataObj?.paper2PercentageLast30Days?.value >=
                    //             props?.traineestate?.userState?.data?.goal &&
                    //           props?.traineestatecomponent?.userStateComponent
                    //             ?.dataObj?.paper2PercentageLast30Days?.value >
                    //             45
                    //         ? "#67FF0B"
                    //         : props?.traineestatecomponent?.userStateComponent
                    //             ?.dataObj?.paper2PercentageLast30Days?.value <
                    //           45
                    //         ? "red"
                    //         : "red"
                    //     }
                    //   />
                    // }
                  >
                    {props?.traineestatecomponent?.userStateComponent?.data?.map(
                      (entry, index) => {
                        var fillColor = "#18A0FB";
                        if (
                          entry?.name === "MONTHLY AVG. P1" &&
                          entry?.paper === "paper1"
                        ) {
                          fillColor =
                            props?.traineestatecomponent?.userStateComponent
                              ?.dataObj?.paper1PercentageLast30Days?.value <
                              props?.traineestate?.userState?.data?.goal &&
                            props?.traineestatecomponent?.userStateComponent
                              ?.dataObj?.paper1PercentageLast30Days?.value > 45
                              ? "#FAFF1C"
                              : props?.traineestatecomponent?.userStateComponent
                                  ?.dataObj?.paper1PercentageLast30Days
                                  ?.value >=
                                  props?.traineestate?.userState?.data?.goal &&
                                props?.traineestatecomponent?.userStateComponent
                                  ?.dataObj?.paper1PercentageLast30Days?.value >
                                  45
                              ? "#67FF0B"
                              : props?.traineestatecomponent?.userStateComponent
                                  ?.dataObj?.paper1PercentageLast30Days?.value <
                                45
                              ? "red"
                              : "red";
                        } else if (
                          entry?.name === "MONTHLY AVG. P2" &&
                          entry?.paper === "paper2"
                        ) {
                          fillColor =
                            props?.traineestatecomponent?.userStateComponent
                              ?.dataObj?.paper2PercentageLast30Days?.value <
                              props?.traineestate?.userState?.data?.goal &&
                            props?.traineestatecomponent?.userStateComponent
                              ?.dataObj?.paper2PercentageLast30Days?.value > 45
                              ? "#FAFF1C"
                              : props?.traineestatecomponent?.userStateComponent
                                  ?.dataObj?.paper2PercentageLast30Days
                                  ?.value >=
                                  props?.traineestate?.userState?.data?.goal &&
                                props?.traineestatecomponent?.userStateComponent
                                  ?.dataObj?.paper2PercentageLast30Days?.value >
                                  45
                              ? "#67FF0B"
                              : props?.traineestatecomponent?.userStateComponent
                                  ?.dataObj?.paper2PercentageLast30Days?.value <
                                45
                              ? "red"
                              : "red";
                        }

                        return <Cell key={`cell-${index}`} fill={fillColor} />;
                      }
                    )}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            {/* <div
              style={{
                display: "flex",
                alignItems: "baseline",
                gap: "6px",
                position: "absolute",
                // // top: "40%",
                bottom: `${paper1 ? paper1 * 0.95 + "%" : "0%"}`,
                width: "40%",
                // right: -30,
                left: 40,
                zIndex: 10,
              }}
            >
              <div
                style={{
                  borderTop: "2px dashed white",
                  backgroundColor: "transparent",
                  width: "100%",
                  // borderStyle: "dashed",
                  zIndex: 99,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                gap: "6px",
                position: "absolute",
                // // top: "40%",
                bottom: `${paper2 ? paper2 * 0.95 + "%" : "0%"}`,
                width: "40%",
                // right: -30,
                right: 40,
                zIndex: 10,
              }}
            >
              <div
                style={{
                  borderTop: "2px dashed white",
                  backgroundColor: "transparent",
                  width: "100%",
                  // borderStyle: "dashed",
                  zIndex: 99,
                }}
              ></div>
            </div> */}
          </div>
        </div>
      </Col>
      <Col className="gutter-row" md={10} xs={24} span={8}>
        <div className="wrapperBoxState1">
          <Typography style={{ textAlign: "center" }} className="titleBox">
            GENERATE WORKSHEET
          </Typography>
          <Divider />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "36px",
            }}
          >
            <Typography className="paraBox">
              Uses analysis of student’s prior assessment to generate a
              worksheet that targets the student’s weaknesses. Generate a
              worksheet for either Paper 1 or Paper 2.{" "}
            </Typography>

            <div className="buttonBox">
              <Button
                className="paperButton"
                type="primary"
                onClick={() =>
                  handleGeneratePaper(
                    title === "Mathematics" ? "Paper 1" : "Paper 1 Booklet A"
                  )
                }
              >
                {title === "Mathematics" ? "PAPER 1" : "Booklet A"}
              </Button>
              <Button
                className="paperButton"
                type="primary"
                onClick={() =>
                  handleGeneratePaper(
                    title === "Mathematics" ? "Paper 2" : "Booklet B"
                  )
                }
              >
                {title === "Mathematics" ? "PAPER 2" : "Booklet B"}
              </Button>
            </div>
          </div>
          {/* <Row gutter={24}>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", gap: "4px" }}>
                    <Typography className="topicHeading">.</Typography>
                    <Typography className="topicHeading">
                      Strongest Topics
                    </Typography>
                  </div>
                  <Typography className="topicHeading">Avg.</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", gap: "4px" }}>
                    <Typography className="topicText">1</Typography>
                    <Typography className="topicText">Algebra </Typography>
                  </div>
                  <Typography className="topicText">88%</Typography>
                </div>
                <Divider />
              </div>
            </Col>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", gap: "4px" }}>
                    <Typography className="topicHeading">.</Typography>
                    <Typography className="topicHeading">
                      Weakest Topics
                    </Typography>
                  </div>
                  <Typography className="topicHeading">Avg.</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", gap: "4px" }}>
                    <Typography className="topicText">1</Typography>
                    <Typography className="topicText">Algebra </Typography>
                  </div>
                  <Typography className="topicText">88%</Typography>
                </div>
                <Divider />
              </div>
            </Col>
          </Row> */}
        </div>
      </Col>
    </Row>
  );
};

// export default SubjectStatetistics;

const mapStateToProps = (state) => ({
  traineestate: state.traineestate,
  traineestatecomponent: state.traineestatecomponent,
  user: state.user,
});

export default connect(mapStateToProps, {
  statetistics,
  statetisticsComponent,
})(SubjectStatetistics);
