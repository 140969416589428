import React, { useState } from "react";
import { connect } from "react-redux";
import {
  ChangeStudentTableData,
  ChangeTrainerSearchText,
} from "../../../actions/adminAction";
import { GetTestTableData } from "../../../actions/traineeAction";
import { ChangeAssignTestModalState } from "../../../actions/trainerAction";

import { withRouter } from "react-router-dom";

import DashboardWrapper from "../shared/DashboardWrapper";
import { Col, Divider, Row, Spin, Typography } from "antd";
import "./badges.css";
import BadgeGold from "../../../assets/badgeGold.svg";
import BadgeGrey from "../../../assets/badgeGrey.svg";
import Diamond from "../../../assets/diamond.svg";
import { useEffect } from "react";
import AuthServices from "../../../services/AuthServices";
import { SecureGet, SecurePost } from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import { login } from "../../../actions/loginAction";
const formatDate = (date) => {
  if (date) {
    const dateObj = new Date(date);
    const formattedDate = `${
      dateObj.getUTCMonth() + 1
    }/${dateObj.getUTCDate()}/${dateObj.getUTCFullYear()}`;
    return formattedDate;
  }
  return ""; // Return empty string if date is not available
};

const Badges = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [badgesData, setBadgesData] = useState([]);
  const [rewardbadgesData, setRewardBadgesData] = useState();

  useEffect(() => {
    setIsLoading(false);
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      SecureGet({
        url: apis.GET_BADGES,
        params: {
          Token: t,
        },
      }).then((response) => {
        setBadgesData(response?.data?.data);
        setIsLoading(true);
      });
      SecurePost({
        url: apis.GET_REWARD_BADGE,
        params: {
          Token: t,
        },
      }).then((response) => {
        setRewardBadgesData(response?.data);
        setIsLoading(true);
      });
    }
  }, []);

  useEffect(() => {
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      AuthServices.FetchAuth(t).then((response) => {
        props.login(response.data.user);
      });
    } else {
      window.location = "/";
    }
  }, []);
  return (
    <>
      <DashboardWrapper title="MY BADGES">
        <Divider className="divier_edit" />
        <div className="badgeParrent_wrapper">
          {isLoading ? (
            <>
              {" "}
              <Typography className="badgeLengthText">
                {props?.user?.userDetails?.badgesIds?.length} of{" "}
                {badgesData?.length} badges
              </Typography>
              <div className="badgeWrapper">
                <Row gutter={[16, 24]}>
                  {badgesData?.map((item) => {
                    const isBadgeFind =
                      props?.user?.userDetails?.badgesIds?.find(
                        (obj) => obj?.badgeId === item._id
                      );
                    return (
                      <Col
                        className="gutter-row"
                        xs={24}
                        md={8}
                        lg={6}
                        span={6}
                      >
                        <div className="badge">
                          <img
                            width={"100px"}
                            src={isBadgeFind ? BadgeGold : BadgeGrey}
                            alt=""
                          />
                          <Typography className="badgeTitle">
                            {item?.name}
                          </Typography>
                          <Typography
                            style={{ fontSize: "14px", fontWeight: "400" }}
                            className="badgeDesc"
                          >
                            {isBadgeFind
                              ? `Attained:${formatDate(
                                  isBadgeFind?.attainedAt
                                )}`
                              : ""}
                          </Typography>
                          <div className="badgeHoverBox">
                            <Typography
                              className="badgeTitle"
                              style={{ textAlign: "center" }}
                            >
                              {item?.name}
                            </Typography>
                            {/* <Typography className="badgeDesc">
                            {item?.criteria}
                          </Typography> */}
                            <div>
                              <Typography className="badgeDesc">
                                {item.criteria1}
                              </Typography>
                              <Typography className="badgeDesc">
                                {item.criteria2}
                              </Typography>
                            </div>
                            <Typography className="badgeDesc">
                              Reward:{" "}
                              <img width={"18px"} src={Diamond} alt="" />{" "}
                              {parseInt(item?.reward || 0)?.toLocaleString()}{" "}
                              points
                            </Typography>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
              }}
            >
              <Spin size="large" />
            </div>
          )}
        </div>
      </DashboardWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  trainer: state.trainer,
  trainee: state.trainee,
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    login,
    ChangeAssignTestModalState,
    ChangeStudentTableData,
    GetTestTableData,
    ChangeTrainerSearchText,
  })(Badges)
);
