import { Button, Divider, Modal, Typography } from "antd";
import React, { useEffect } from "react";
import "./testBoardHeader.css";
import { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { fetchTestdata, switchQuestion } from "../../../actions/traineeAction";
import Clock from "./clock";
import { useWindowDimensions } from "./testBoard";

export const truncateString = (inputString, maxLength) => {
  if (inputString?.length <= maxLength) {
    return inputString;
  } else {
    return inputString?.substring(0, maxLength);
  }
};
const TestBoardHeader = (props) => {
  const { testName, next, previous, isCorrect, handleSubmitOpen } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { width } = useWindowDimensions();

  const history = useHistory();
  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="headerParrentWrapper">
        <div className="headerLeft">
          <Typography
            style={{ cursor: "pointer" }}
            className="exit"
            onClick={() => handleOpen()}
          >
            {width <= 500 ? "Exit" : "Exit assessment"}
          </Typography>
          <Button
            style={{ borderRadius: "30PX" }}
            type="primary"
            onClick={() => previous()}
            className="testHeaderButton"
          >
            Previous
          </Button>
        </div>
        <div className="headerInner">
          <Typography className="hederTestName">
            {testName
              ? width <= 600
                ? truncateString(testName, 15)
                : truncateString(testName, 40)
              : null}
          </Typography>
          <Divider className="testHeaderDivider" />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              justifyContent: "center",
            }}
          >
            <Typography className="testHeaderTimeText">
              {width <= 600 ? "" : " Time Left:"}
            </Typography>
            <Clock />
          </div>
        </div>
        <div className="headerRight">
          <Button
            style={{ borderRadius: "30PX" }}
            className="testHeaderButton"
            type="primary"
            onClick={
              props.isSubmitted ? () => handleSubmitOpen() : () => next()
            }
          >
            {props.isSubmitted ? "Submit" : "Next >"}
          </Button>
        </div>
      </div>
      <Modal
        visible={isModalOpen}
        footer={null}
        closable={false}
        centered
        onCancel={handleCancel}
        width={350}
        style={{ borderRadius: "25px", zIndex: 999999 }}
      >
        <p className="modalTitle">
          Are you sure you want to leave the assignment?
        </p>
        <div className="modalButtons">
          <Button className="modalButtonNo" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="modalButtonNo"
            onClick={() => {
              props.switchQuestion(0);
              history.push("/user/livetest");
              props.trainee.socket.current.disconnect();
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default withRouter(
  connect(mapStateToProps, {
    switchQuestion,
  })(TestBoardHeader)
);
