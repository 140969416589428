import React, { useEffect } from "react";
import "./homepage.css";
import "./homepage.jpg";
import { connect } from "react-redux";
import Login from "../login/login";
import auth from "../../../services/AuthServices";
import { Redirect } from "react-router-dom";
import { useState } from "react";
import Admin from "../login/Admin";

function Homepage(props) {
  const [token, setToken] = useState("");

  useEffect(() => {
    const retrievedToken = auth.retriveToken();
    setToken(retrievedToken);
  }, []);

  const [isStudent, setIsStudent] = useState(true);
  const persistToken = localStorage.getItem("persistToken") === "true";

  if (token && (persistToken || sessionStorage.getItem("Token"))) {
    return (
      <Redirect
        to={
          localStorage.getItem("type") === "Student"
            ? "/user/profile"
            : "/user/home"
        }
      />
    );
  } else {
    return (
      <>
        {window.location.hash !== "#/admin" ? (
          <div className="home-page-container">
            <div className="parallax">
              {/* <HomepageHeader /> */}
              <Login setIsStudent={setIsStudent} />
            </div>
          </div>
        ) : (
          <div>
            <div className="parallax">
              {/* <HomepageHeader /> */}
              <Admin />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(Homepage);
