import React from "react";
import "../profile.css";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import { Tabs } from "antd";
import AuthServices from "../../../../services/AuthServices";
import { useState } from "react";
import { tutorSubject } from "../../../../actions/tutorwithsubject";

import { givenLevels, givenSchools } from "../../../common/constants";
import { connect } from "react-redux";
import moment from "moment";
import Alert from "../../../common/alert";
import { useEffect } from "react";
import DiamondIcon from "../../../../assets/diamond.svg";

const Profile = (props) => {
  const [values, setValues] = useState({
    name: props.user?.userDetails?.name,
    level: props.user?.userDetails?.level,
    goalForMath: props.user?.userDetails?.goalForMath,
    goalForEnglish: props.user?.userDetails?.goalForEnglish,
    goalForScience: props.user?.userDetails?.goalForScience,
    school: props.user?.userDetails?.school,
    countDownTimer:
      props.user?.userDetails?.countDownTimer &&
      props.user?.userDetails?.countDownTimer !== ""
        ? moment
            .utc(props.user?.userDetails?.countDownTimer, "YYYY-MM-DD")
            .local()
        : null,
    notesOnCountDown: props.user?.userDetails?.notesOnCountDown,
    email: props?.user?.userDetails?.emailid,
    postalCode: props?.user?.userDetails?.postalCode,
    engTutors: props?.user?.userDetails?.engTutor || "",
    mathTutors: props?.user?.userDetails?.mathTutor || "",
    sciTutors: props?.user?.userDetails?.sciTutor || "",
    password: "",
    reEnterPassword: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleLogOut = () => {
    AuthServices.deleteToken();
    window.location.href = "/";
  };
  const onChange = (date, dateString) => {
    const utcDate = moment.utc(dateString, "YYYY-MM-DD");
    setValues({
      ...values,
      countDownTimer: utcDate,
    });
  };

  const validateGoal = (_, value) => {
    if (value > 100) {
      return Promise.reject("Value should not be greater than 100");
    }
    return Promise.resolve();
  };

  const t = AuthServices.retriveToken();

  useEffect(() => {
    AuthServices.FetchSubjectTeacher().then((response) => {
      props.tutorSubject(response.data);
    });
  }, []);

  const handleSubmit = () => {
    if (values.password === values.reEnterPassword) {
      if (t && t !== "undefined") {
        AuthServices.UpdateProfile(t, values)
          .then((response) => {
            if (response.data.message) {
              setIsModalOpen(false);
              return Alert("success", "Success!", response.data.message);
            }
          })
          .catch((response) => {
            return Alert("error", "Error!", response.data.message);
          });
      } else {
        window.location = "/";
      }
    } else {
      setIsModalOpen(false);
      return Alert(
        "warning",
        "Error!",
        "password and re enter Password should be same"
      );
    }
  };

  const handleLevelDifficultyChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <div className="profile_wrapper">
        <div className="profile_containers">
          <div className="container">
            <div className="container_inner">
              <div className="edit_profile_container">
                <div className="space-between">
                  <Typography className="profile_text">
                    EDIT MY PROFILE
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="rewardBox">
                      <img width="20px" src={DiamondIcon} alt="" />
                      <Typography style={{ color: "white" }}>
                        {props.user?.userDetails?.points?.toLocaleString()}
                      </Typography>
                    </div>
                    <Button type="primary" onClick={handleLogOut}>
                      Log out
                    </Button>
                  </div>
                </div>
                <Divider className="divier_edit" />
              </div>
              <Row>
                <Col span={24}>
                  <Typography className="detailsText_editProfile">
                    Child’s details
                  </Typography>
                </Col>
                <Col span={18} xs={24} md={18}>
                  <Row gutter={36}>
                    <Col span={12} md={12} xs={24}>
                      <Form.Item className="label" label="Name" hasFeedback>
                        <Input
                          name="name"
                          value={values.name}
                          className="input_editProfile"
                          onChange={handleChangeUser}
                          // style={{ borderRadius: "20px", height: "55px" }}
                          // placeholder="Basic usage"
                          aria-label="name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} md={12} xs={24}>
                      <Form.Item
                        className="label"
                        label="Level of Study"
                        hasFeedback
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Level"
                          className="select_editProfile"
                          optionFilterProp="s"
                          value={values.level}
                        >
                          {givenLevels.map((d, i) => (
                            <Select.Option
                              // className="input_editProfile"

                              key={d.value}
                              s={d.label}
                              value={d.value}
                              onClick={() =>
                                handleLevelDifficultyChange("level", d.value)
                              }
                            >
                              {d.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={36}>
                    <Col span={12} md={12} xs={24}>
                      <Row gutter={16}>
                        <Col span={12} md={12} xs={24}>
                          <Form.Item
                            className="label"
                            label="Goal for Math"
                            hasFeedback
                            rules={[
                              {
                                validator: validateGoal,
                              },
                            ]}
                          >
                            <Input
                              className="input_editProfile"
                              name="goalForMath"
                              value={values.goalForMath}
                              onChange={handleChangeUser}
                              type="number"
                              min={0}
                              max={100}
                              // style={{ borderRadius: "20px", height: "55px" }}
                              // placeholder="Basic usage"
                              aria-label="Goal for Math"
                              suffix="%"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24}>
                          <Form.Item
                            className="label"
                            label="Goal for English"
                            hasFeedback
                          >
                            <Input
                              name="goalForEnglish"
                              value={values.goalForEnglish}
                              onChange={handleChangeUser}
                              type="number"
                              min={0}
                              max={100}
                              className="input_editProfile"
                              // style={{ borderRadius: "20px", height: "55px" }}
                              // placeholder="Basic usage"
                              aria-label="name"
                              suffix="%"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} md={12} xs={24}>
                      <Row gutter={16}>
                        <Col span={12} md={12} xs={24}>
                          <Form.Item
                            className="label"
                            label="Goal for Science"
                            hasFeedback
                          >
                            <Input
                              name="goalForScience"
                              className="input_editProfile"
                              onChange={handleChangeUser}
                              value={values.goalForScience}
                              type="number"
                              min={0}
                              max={100}
                              // style={{ borderRadius: "20px", height: "55px" }}
                              // placeholder="Basic usage"
                              aria-label="name"
                              suffix="%"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24}>
                          <Form.Item
                            className="label"
                            label="Goal for Mother Tongue"
                            hasFeedback
                          >
                            <Input
                              disabled
                              className="input_editProfile"
                              // style={{ borderRadius: "20px", height: "55px" }}
                              // placeholder="Basic usage"
                              aria-label="name"
                              suffix="%"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row gutter={36}>
                    <Col span={12} md={12} xs={24}>
                      <Form.Item className="label" label="School" hasFeedback>
                        <Input
                          name="school"
                          value={values.school}
                          onChange={handleChangeUser}
                          className="input_editProfile"
                          aria-label="name"
                        />
                      </Form.Item>
                      {/* <Form.Item className="label" label="School" hasFeedback>
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          className="select_editProfile"
                          optionFilterProp="s"
                          value={values.school}
                        >
                          {givenSchools.map((d, i) => (
                            <Select.Option
                              // className="input_editProfile"
                              key={d.value}
                              s={d.label}
                              value={d.value}
                              onClick={() =>
                                handleLevelDifficultyChange("school", d.value)
                              }
                            >
                              {d.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item> */}
                    </Col>
                    <Col span={12} xs={24}>
                      <Row gutter={16}>
                        <Col md={12} xs={24}>
                          <Form.Item
                            className="label"
                            label="Countdown Timer"
                            hasFeedback
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              disabledDate={(current) => {
                                let customDate = moment().format("YYYY-MM-DD");
                                return (
                                  current &&
                                  current < moment(customDate, "YYYY-MM-DD")
                                );
                              }}
                              className="iputTime"
                              value={values.countDownTimer}
                              onChange={onChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                          <Form.Item
                            className="label"
                            label="Notes on countdown"
                            hasFeedback
                          >
                            <Input
                              name="notesOnCountDown"
                              value={values.notesOnCountDown}
                              onChange={handleChangeUser}
                              className="input_editProfile"
                              // style={{ borderRadius: "20px", height: "55px" }}
                              // placeholder="Basic usage"
                              aria-label="name"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Typography
                    style={{ fontSize: "18px" }}
                    className="detailsText_editProfile"
                  >
                    Select your tutor
                  </Typography>
                  <Row gutter={16}>
                    <Col span={12} md={8} xs={24}>
                      <Form.Item
                        className="label"
                        label="Mathematics Tutor"
                        hasFeedback
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Math tutor name"
                          className="select_editProfile"
                          optionFilterProp="s"
                          value={values.mathTutors.value}
                        >
                          {props?.subjectTutor?.teacherSubjects?.data?.mathTutors?.map(
                            (d, i) => (
                              <Select.Option
                                // className="input_editProfile"

                                key={d._id}
                                s={d.label}
                                value={d.value}
                                onClick={() =>
                                  handleLevelDifficultyChange("mathTutors", {
                                    _id: d._id,
                                    value: d.value,
                                  })
                                }
                              >
                                {d.label}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} md={8} xs={24}>
                      <Form.Item
                        className="label"
                        label="Science Tutor"
                        hasFeedback
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Sci tutor name"
                          className="select_editProfile"
                          optionFilterProp="s"
                          value={values.sciTutors.value}
                        >
                          {props?.subjectTutor?.teacherSubjects?.data?.sciTutors?.map(
                            (d, i) => (
                              <Select.Option
                                // className="input_editProfile"

                                key={d._id}
                                s={d.label}
                                value={d.value}
                                onClick={() =>
                                  handleLevelDifficultyChange("sciTutors", {
                                    _id: d._id,
                                    value: d.value,
                                  })
                                }
                              >
                                {d.label}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} md={8} xs={24}>
                      <Form.Item
                        className="label"
                        label="English Tutor"
                        hasFeedback
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Eng tutor name"
                          className="select_editProfile"
                          optionFilterProp="s"
                          value={values.engTutors.value}
                        >
                          {props?.subjectTutor?.teacherSubjects?.data?.engTutors?.map(
                            (d, i) => (
                              <Select.Option
                                // className="input_editProfile"

                                key={d.value}
                                s={d.label}
                                value={d.value}
                                onClick={() =>
                                  handleLevelDifficultyChange("engTutors", {
                                    _id: d._id,
                                    value: d.value,
                                  })
                                }
                              >
                                {d.label}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Divider />
            <div className="container_inner">
              <Row>
                <Col span={24}>
                  <Typography className="detailsText_editProfile">
                    Your Details
                  </Typography>
                </Col>
                <Col span={18} xs={24} md={18}>
                  <Row gutter={36}>
                    <Col span={12} md={12} xs={24}>
                      <Form.Item
                        className="label"
                        label="Email address"
                        hasFeedback
                      >
                        <Input
                          disabled
                          name="email"
                          value={values.email}
                          className="input_editProfile"
                          // style={{ borderRadius: "20px", height: "55px" }}
                          // placeholder="Basic usage"
                          aria-label="name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} md={12} xs={24}>
                      <Form.Item
                        className="label"
                        label="Postal Code"
                        hasFeedback
                      >
                        <Input
                          disabled
                          name="postalCode"
                          value={values?.postalCode && values?.postalCode}
                          className="input_editProfile"
                          // style={{ borderRadius: "20px", height: "55px" }}
                          // placeholder="Basic usage"
                          aria-label="postalCode"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={36}>
                    <Col span={12} md={12} xs={24}>
                      <Form.Item className="label" label="Password" hasFeedback>
                        <Input
                          className="input_editProfile"
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChangeUser}
                          // style={{ borderRadius: "20px", height: "55px" }}
                          // placeholder="Basic usage"
                          aria-label="name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} md={12} xs={24}>
                      <Form.Item
                        className="label"
                        label="Re-enter your password"
                        hasFeedback
                      >
                        <Input
                          className="input_editProfile"
                          name="reEnterPassword"
                          value={values.reEnterPassword}
                          onChange={handleChangeUser}
                          type="password"
                          // style={{ borderRadius: "20px", height: "55px" }}
                          // placeholder="Basic usage"
                          aria-label="name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "14px",
                      alignItems: "center",
                    }}
                  >
                    <Button className="EditProfileCancelButton">Cancel</Button>
                    <Button
                      className="EditProfileSaveButton"
                      onClick={handleOpen}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalOpen}
        footer={null}
        closable={false}
        centered
        onCancel={handleCancel}
        width={350}
        style={{ borderRadius: "25px", zIndex: 999999 }}
      >
        <p className="modalTitle">
          Are you sure you want to save the profile data?
        </p>
        <div className="modalButtons">
          <Button className="modalButtonNo" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="modalButtonNo" onClick={handleSubmit}>
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  subjectTutor: state.subjectTutor,
});

export default connect(mapStateToProps, {
  tutorSubject,
})(Profile);
