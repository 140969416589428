import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const RichTextEditor = ({ body, setBody }) => {
  // Add event listener options to handle the error
  const eventListenerOptions = { passive: false };

  if (!body) {
    return null; // Render nothing if body is not available
  }

  return (
    <div>
      <SunEditor
        height="200px"
        setContents={body}
        defaultValue={body}
        autoFocus={true}
        onChange={setBody}
        placeholder="Please Enter Body"
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            "/",
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link"],
            ["horizontalRule", "image", "video", "template"],
          ],
        }}
        eventListenerOptions={eventListenerOptions} // Pass the options to the SunEditor component
      />
    </div>
  );
};

export default RichTextEditor;
