import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Icon,
  Typography,
  Divider,
  Modal,
  // Popconfirm,
} from "antd";

import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { ChangeStudentTableData } from "../../../actions/adminAction";

import {
  ChangeAssignTestModalState,
  GetAllTest,
} from "../../../actions/trainerAction";
import "./student.css";
import { SecurePost } from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import Alert from "../../common/alert";
// import Alert from "../../../components/common/alert";
// import { SecureDelete, SecurePost } from "../../../services/axiosCall";
// import apis from "../../../services/Apis";
// // import NewTrainerForm from "../";

class Student extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TrainertableLoading: false,
      isModelOpen: false,
      selectedOption: "",
      studentId: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({ selectedOption: event.target.value });
  }
  openModal = (key) => {
    this.setState({
      isModelOpen: true,
      studentId: key,
    });
  };

  closeModal = () => {
    this.setState({
      isModelOpen: false,
      selectedOption: "",
      studentId: "",
    });
  };
  handleOk = async () => {
    try {
      const res = await SecurePost({
        url: `${apis.ASSIGN_TEST_STUDENT}`,
        data: {
          studentId: this.state.studentId,
          testId: this.state.selectedOption,
        },
      });

      if (res) {
        Alert("success", "Success", res.data.message);
        this.closeModal();
      }
    } catch (error) {
      console.log(error);
      const message = error.response
        ? error.response.data.message
        : error.message;
      return Alert("error", "Error!", message);
    }
  };
  componentDidMount() {
    this.props.ChangeStudentTableData();
    this.props.GetAllTest();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.props.admin.TrainersearchText]}
        autoEscape
        textToHighlight={text}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.props.ChangeTrainerSearchText(selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.props.ChangeTrainerSearchText("");
  };

  render() {
    const { Title } = Typography;
    const options = this.props.trainer.AllTests.map((option) => (
      <option key={option._id} value={option._id}>
        {option.title}
      </option>
    ));
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "25%",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Email Id",
        dataIndex: "emailid",
        key: "emailid",
        width: "25%",
        ...this.getColumnSearchProps("emailid"),
      },
      {
        title: "Contact Number",
        dataIndex: "contact",
        key: "contact",
        ...this.getColumnSearchProps("contact"),
      },
      {
        title: "Level",
        dataIndex: "level",
        key: "level",
        ...this.getColumnSearchProps("level"),
      },
      // {
      //   title: "Action",
      //   key: "_id",
      //   dataIndex: "_id",
      //   obj: "obj",
      //   render: (key, obj) => (
      //     <span>
      //       <Button
      //         type="primary"
      //         onClick={() => {
      //           this.openModal(key, "Save Changes");
      //         }}
      //       >
      //         Assign Test
      //       </Button>
      //       <Divider type="vertical" />
      //     </span>
      //   ),
      // },
    ];
    return (
      <div className="admin-table-container">
        <div className="register-trainer-form-header">
          <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
            List of Students
          </Title>
        </div>
        <Table
          bordered={true}
          columns={columns}
          dataSource={this.props.admin.studentTableData}
          size="medium"
          pagination={{ pageSize: 10 }}
          loading={this.props.admin.studentTableLoadingStatus}
          rowKey="_id"
          style={{ backgroundColor: "#fff", padding: "10px" }}
        />
        ;{console.log(this.state.isModelOpen)}
        <Modal
          visible={this.state.isModelOpen}
          title={"Assign Test"}
          onOk={this.handleOk}
          onCancel={this.closeModal}
          style={{
            top: "20px",
            padding: "0px",
            backgroundColor: "rgb(155,175,190)",
          }}
          width="40%"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <label htmlFor="select-option">Select an option:</label>
            <select
              id="select-option"
              value={this.state.selectedOption}
              onChange={this.handleChange}
              style={{
                fontSize: "16px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                backgroundColor: "#fff",
                boxShadow: "0 2px 2px rgba(0,0,0,0.1)",
                cursor: "pointer",
              }}
            >
              <option value="">-- Select an option --</option>
              {options}
            </select>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  admin: state.admin,
  trainer: state.trainer,
});

export default connect(mapStateToProps, {
  ChangeAssignTestModalState,
  ChangeStudentTableData,
  GetAllTest,
})(Student);
