import React from "react";
import { Table, Card, Typography } from "antd";
import { useHistory } from "react-router-dom";
import Edit from "../../../assets/edit.svg";

function Trainee(props) {
  const history = useHistory();
  let maxMarks = props.maxmMarks || 2;
  const columns = [
    {
      title: "Name",
      dataIndex: "userid.name",
      key: "userid.name",
    },
    {
      title: "Email Id",
      dataIndex: "userid.emailid",
      key: "userid.emailid",
    },
    {
      title: "Contact No",
      dataIndex: "userid.contact",
      key: "userid.contact",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Max Marks",
      dataIndex: "maxMarks",
      key: "_id",
      render: (tag) => <span>{maxMarks}</span>,
    },
    {
      title: "Details",
      dataIndex: "userid._id",
      key: "userid._id",
      render: (tag) => {
        return (
          <span>
            <button
              onClick={() => {
                history.push({
                  pathname: `/trainee/taketest`,
                  search: `?testid=${
                    props.id
                  }&traineeid=${tag}&isCorrect=${true}`,
                  state: { isCorrect: true },
                });
              }}
              style={{
                background: "transparent",
                border: "none",
                color: "blue",
                display: "flex",
                gap: "8px",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              View Answer Sheet
              <div className="EditBox">
                <img src={Edit} alt="" />
                <div className="progressInfo">
                  <Typography className="infoText">
                    Manual Marking Change the marks of individual questions
                  </Typography>
                </div>
              </div>
            </button>
          </span>
        );
      },
    },
  ];
  return (
    <div>
      <Card>
        <div className="download-section">
          <Table
            pagination={false}
            rowKey="_id"
            columns={columns}
            dataSource={props.stats}
            style={{ backgroundColor: "#fff", padding: "10px" }}
          />
        </div>
      </Card>
    </div>
  );
}

export default Trainee;
