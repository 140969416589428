import React, { useState } from "react";
import { Form, Input, Button, Icon, Checkbox } from "antd";
import "./login.css";
import { connect } from "react-redux";
import { login, logout } from "../../../actions/loginAction";
import auth from "../../../services/AuthServices";
import Alert from "../../common/alert";
import { Redirect, withRouter, Link } from "react-router-dom";

const Login = ({ form, history, user, login }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  // const [authType, setAuthType] = useState("STUDENT");

  const handleClick = () => {
    history.push("/register");
  };

  const handleLoginClick = () => {
    history.push("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        auth
          .LoginAuth(values.email, values.password, "STUDENT")
          .then((response) => {
            if (response.data.success) {
              login(response.data.user);
              localStorage.setItem("isRememberMe", isRememberMe);
              auth.storeToken(response.data.token);
              localStorage.setItem("type", "Student");
              setIsLoggedIn(true);
            } else {
              return Alert(
                "error",
                "Error!",
                response.data.message.replaceAll("TRAINER", "TEACHER")
              );
            }
          })
          .catch((error) => {
            console.log(error);
            const message = error.response
              ? error.response.data.message
              : error.message;
            return Alert("error", "Error!", message);
          });
      }
    });
  };

  // const changeAuthType = (selected) => {
  //   if (
  //     selected !== "ADMIN" &&
  //     selected !== "TRAINER" &&
  //     selected !== "STUDENT"
  //   )
  //     return;
  //   setAuthType(selected);
  // };

  const { getFieldDecorator } = form;
  if (isLoggedIn) {
    return <Redirect to={user.userOptions[0].link} />;
  } else {
    return (
      <div className="login-container">
        <div className="login-student-inner">
          <div className="space-btw">
            <Button className="login-btn" ghost onClick={handleLoginClick}>
              Sign In
            </Button>
            <Button className="register-btn" ghost onClick={handleClick}>
              Register
            </Button>
          </div>
          <div className="login-log">
            <img src="/assets/login-logo.png" alt="logo" height={"200px"} />
          </div>
          {/* <div className="register_login_switch">
            <span
              onClick={() => changeAuthType("STUDENT")}
              className={`${authType === "STUDENT" ? "selected" : ""}`}
            >
              Student
            </span>
            <span
              onClick={() => changeAuthType("TRAINER")}
              className={`${authType === "TRAINER" ? "selected" : ""}`}
            >
              Teacher
            </span>
            <span
              onClick={() => changeAuthType("ADMIN")}
              className={`${authType === "ADMIN" ? "selected" : ""}`}
            >
              Admin
            </span>
          </div> */}
          <Form onSubmit={handleSubmit}>
            <Form.Item label="Email" colon={false} hasFeedback>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ],
              })(
                <Input
                  // prefix={
                  //   <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  // }
                  placeholder="Email"
                />
              )}
            </Form.Item>
            <Form.Item label="Password" colon={false} hasFeedback>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ],
              })(
                <Input
                  // prefix={
                  //   <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  // }
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
            </Form.Item>
            <div className="space-btw">
              <Checkbox onChange={(e) => setIsRememberMe(e.target.checked)}>
                Remember me?
              </Checkbox>
              <Link to="/forgot-password">Forgot your password?</Link>
            </div>
            {/* {authType === "STUDENT" && ( */}
            {/* <Form.Item>
              <Button type="primary" block >
                Register
              </Button>
            </Form.Item> */}
            {/* )} */}
          </Form>
          <div className="login-footer">
            <Link to="/admin">Tutor/Admin Sign In</Link>
          </div>
        </div>
      </div>
    );
  }
};

const LoginForm = Form.create({ name: "login" })(Login);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { login, logout })(LoginForm)
);
