import React from "react";
import "./backbone.css";
import { connect } from "react-redux";
import AllTrainer from "../admin/allTrainer/alltrainer";
import AllStudent from "../admin/allStudent/allstudent";
import Student from "../trainee/students/student";
import AllTopics from "../admin/allTopics/alltopics.js";
import AllQuestions from "../trainer/allquestions/allquestion";
import AllTests from "../trainer/alltests/alltest";
import StudentHistorey from "../trainee/studentHistorey/studentHistorey";
import AllStudentTest from "../trainee/alltest/allStudentTest";
import ConductTest from "../trainer/conducttest/conducttest";
import NewTest from "../trainer/newtest/newtest";
import auth from "../../services/AuthServices";
import Welcome from "./welcome";
import ErrorPage from "./errorPage";
import { login, logout } from "../../actions/loginAction";
import { changeActiveRoute } from "../../actions/useraction";
import Alert from "../common/alert";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Layout, Menu, Button, Icon, Tooltip, Typography, Spin } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";

import main from "./main.png";
import SiderImage from "../../assets/sideImage.svg";
import SiderLogo from "../../assets/leagueonlinelogo.svg";
import Profile from "../trainee/profile/Profile";
import EditProfile from "../trainee/profile/EditProfile/EditProfile";
import Statistics from "../trainee/statistics/Statistics";
import QuesConsulation from "../trainee/QuestionConsultation/QuesConsulation";
import QuestionConsultation from "../trainer/questionconsultation/QuestionConsultation";
import ProfileIcom from "../../assets/sidebarIcons/iconmonstr-user-6.svg";
import { getActiveRoute } from "../trainee/utils";
import ClaimReward from "../trainee/claimrewards/ClaimReward.js";
import AllRewards from "../admin/rewards/AllRewards.js";
import AllActiveOrder from "../admin/activeOrder/AllActiveOrder.js";
import AllPastOrder from "../admin/pastOrder/AllPastOrder.js";
import Badges from "../trainee/Badges/Badges.js";

const { Header, Sider, Content } = Layout;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LocalIsLoggedIn: this.props.user.isLoggedIn,
      collapsed: false,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  logOut = () => {
    auth.deleteToken();
    window.location.href = "/";
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const path = window.location.href?.split("/").pop();
    const activeRouteIndex = getActiveRoute(path);
    var tt = activeRouteIndex;
    if (tt === -1) {
      window.location.href = `${this.props.user.userOptions[0].link}`;
    } else {
      this.props.changeActiveRoute(String(tt));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  };

  componentWillMount() {
    var t = auth.retriveToken();
    if (this.state.LocalIsLoggedIn) {
    } else if (t && t !== "undefined") {
      auth
        .FetchAuth(t)
        .then((response) => {
          this.props.login(response.data.user);
          this.setState({
            LocalIsLoggedIn: true,
          });
          var subUrl = this.props.match.params.options;
          // eslint-disable-next-line
          var obj = this.props.user.userOptions.find((o, i) => {
            if (o.link === `/user/${subUrl}`) {
              return o;
            }
          });
          // var tt = this.props.user.userOptions.indexOf(obj);

          const path = window.location.href?.split("/").pop();
          const activeRouteIndex = getActiveRoute(path);
          var tt = activeRouteIndex;
          if (tt === -1) {
            window.location.href = `${this.props.user.userOptions[0].link}`;
          } else {
            this.props.changeActiveRoute(String(tt));
          }
        })
        .catch((error) => {
          const message = error.response
            ? error.response.data.message
            : error.message;
          Alert("error", "Error!", message);
          auth.deleteToken();
          window.location.href = "/";
        });
    } else {
      window.location = "/";
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.options !== this.props.match.params.options) {
      var subUrl = this.props.match.params.options;
      // eslint-disable-next-line
      var obj = this.props.user.userOptions.find((o, i) => {
        if (o.link === `/user/${subUrl}`) {
          return o;
        }
      });
      var tt = this.props.user.userOptions.indexOf(obj);
      if (tt === -1) {
        window.location.href = `${this.props.user.userOptions[0].link}`;
      } else {
        this.props.changeActiveRoute(String(tt));
      }
    }
  }

  render() {
    let torender = null;

    if (this.props.match.params.options === "listtrainers") {
      torender = <AllTrainer />;
    } else if (this.props.match.params.options === "liststudents") {
      torender = <AllStudent />;
    } else if (this.props.match.params.options === "students") {
      torender = <Student />;
    } else if (this.props.match.params.options === "listsubjects") {
      torender = <AllTopics />;
    } else if (this.props.match.params.options === "rewards") {
      torender = <AllRewards />;
    } else if (this.props.match.params.options === "badges") {
      torender = <Badges />;
    } else if (this.props.match.params.options === "activeorders") {
      torender = <AllActiveOrder />;
    } else if (this.props.match.params.options === "pastorders") {
      torender = <AllPastOrder />;
    } else if (this.props.match.params.options === "listquestions") {
      torender = <AllQuestions />;
    } else if (this.props.match.params.options === "listtests") {
      torender = <AllTests />;
    } else if (this.props.match.params.options === "questionconsultation") {
      torender = <QuestionConsultation />;
    } else if (this.props.match.params.options === "testHistory") {
      torender = <StudentHistorey />;
    } else if (this.props.match.params.options === "livetest") {
      torender = <AllStudentTest />;
    } else if (this.props.match.params.options === "profile") {
      torender = <Profile />;
    } else if (this.props.match.params.options === "statistics") {
      torender = <Statistics />;
    } else if (this.props.match.params.options === "claimRewards") {
      torender = <ClaimReward />;
    } else if (this.props.match.params.options === "profileSettings") {
      torender = <EditProfile />;
    } else if (this.props.match.params.options === "questionConsultation") {
      torender = <QuesConsulation />;
    } else if (this.props.match.params.options === "home") {
      torender = <Welcome />;
    } else if (this.props.match.params.options === "newtest") {
      torender = <NewTest />;
    } else if (this.props.match.params.options === "conducttest") {
      let params = queryString.parse(this.props.location.search);
      torender = <ConductTest {...params} />;
    } else {
      torender = <ErrorPage />;
    }

    const { screenWidth, screenHeight } = this.state;

    return (
      <Layout>
        {this.props.user.isLoggedIn ? (
          <>
            {" "}
            {this.props?.user?.userDetails?.type === "STUDENT" ? (
              <>
                {" "}
                <Sider
                  trigger={null}
                  // collapsible
                  theme="light"
                  // collapsed={this.state.collapsed}
                  width={
                    screenWidth >= 860 ? 250 : !this.state.collapsed ? 0 : 250
                  }
                  style={{
                    overflow: "hidden",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    zIndex: 30,

                    background:
                      "linear-gradient(180deg, #020202 0%, rgba(59, 64, 70, 0) 100%)",
                  }}
                >
                  {/* <div className="logo11" /> */}{" "}
                  <div className="siderToggle">
                    <Icon
                      type="menu-unfold"
                      onClick={this.toggle}
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                        padding: "20px",
                        backgroundColor: "transparent",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100vh",
                      background:
                        "linear-gradient(180deg, #020202 0%, rgba(59, 64, 70, 0) 100%)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 12,
                        paddingTop: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img width={"80%"} src={SiderLogo} alt="" />
                      </div>

                      <Menu
                        defaultSelectedKeys={[this.props.user.activeRoute]}
                        mode="inline"
                        theme="dark"
                        style={{
                          // height: "100vh",
                          background: "transparent",
                          color: "white",
                        }}
                      >
                        {this.props.user.userOptions.map((d, i) => {
                          return (
                            <Menu.Item
                              key={i}
                              style={{
                                color: "white",
                                fill: "white",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor:
                                  this.props.user.activeRoute === String(i)
                                    ? "#777F7F"
                                    : "transparent",
                              }}
                              onClick={this.toggle}
                            >
                              <Icon
                                component={() => (
                                  <img
                                    width={"15px"}
                                    style={{
                                      fill: "white",
                                      filter: "invert(1)",
                                    }}
                                    src={require(`../../assets/sidebarIcons/${d?.icon}`)}
                                    alt=""
                                  />
                                )}
                              />
                              <span>{d.display}</span>
                              <Link to={d.link}></Link>
                            </Menu.Item>
                          );
                        })}
                      </Menu>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img width={"100%"} src={SiderImage} alt="" />
                    </div>
                  </div>
                </Sider>
                <Layout>
                  {/* <Header
            style={{
              position: "fixed",
              width: "100vw",
              paddingLeft: "10px",
              zIndex: "1000",
              background: "#465d72",
            }}
          >
            <Icon
              className="trigger"
              type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
              onClick={this.toggle}
              style={{ color: "#fff", fontSize: "20px", padding: "20px" }}
            />
            <ul className="user-options-list">
              <li>
                <Tooltip placement="bottom" title="Log Out">
                  <Button
                    type="primary"
                    size="large"
                    shape="circle"
                    onClick={this.logOut}
                    className="logout-button"
                  >
                    <Icon type="logout" />
                  </Button>
                </Tooltip>
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ padding: "5px", marginTop: 1 }}
                  src={main}
                  alt="company logo"
                  className="d-logo"
                />
              </li>
            </ul>
          </Header> */}
                  <Content
                    style={{
                      // margin: "24px 16px",
                      // padding: 24,
                      // marginTop: "80px",
                      backgroundColor:
                        "linear-gradient(180deg, #3b4046 0%, #7a7a7a 100%) !important",

                      // minHeight: "80vh",
                      minHeight: "100vh",

                      marginLeft:
                        screenWidth >= 860
                          ? 250
                          : !this.state.collapsed
                          ? 0
                          : 0,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        backgroundColor:
                          "linear-gradient(180deg, #3b4046 0%, #7a7a7a 100%) !important",
                      }}
                    >
                      <div className="sideOpenIcon">
                        <Icon
                          // className="trigger"
                          type="menu-fold"
                          onClick={this.toggle}
                          style={{
                            color: "#fff",
                            fontSize: "20px",
                            padding: "10px",
                            backgroundColor: "transparent",
                          }}
                        />
                      </div>
                      {torender}
                    </div>
                  </Content>
                </Layout>
              </>
            ) : (
              <>
                {" "}
                <Sider
                  trigger={null}
                  collapsible
                  theme="light"
                  collapsed={this.state.collapsed}
                  style={{
                    overflow: "hidden",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    zIndex: 5,
                  }}
                >
                  <div className="logo11" />
                  <Menu
                    defaultSelectedKeys={[this.props.user.activeRoute]}
                    mode="inline"
                    theme="light"
                  >
                    {this.props.user.userOptions.map((d, i) => {
                      return (
                        <Menu.Item key={i}>
                          {/* <img src={d.icon} alt="" /> */}
                          <Icon type={d?.icon} />
                          <span>{d.display}</span>
                          <Link to={d.link}></Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </Sider>
                <Layout>
                  <Header
                    style={{
                      position: "fixed",
                      width: "100vw",
                      paddingLeft: "10px",
                      zIndex: "1000",
                      background: "#465d72",
                    }}
                  >
                    <Icon
                      className="trigger"
                      type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                      onClick={this.toggle}
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                        padding: "20px",
                      }}
                    />
                    <ul className="user-options-list">
                      <li>
                        <Tooltip placement="bottom" title="Log Out">
                          <Button
                            type="primary"
                            size="large"
                            shape="circle"
                            onClick={this.logOut}
                            className="logout-button"
                          >
                            <Icon type="logout" />
                          </Button>
                        </Tooltip>
                      </li>
                      <li style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{ padding: "5px", marginTop: 1 }}
                          src={main}
                          alt="company logo"
                          className="d-logo"
                        />
                      </li>
                    </ul>
                  </Header>
                  <Content
                    style={{
                      margin: "24px 16px",
                      padding: 24,
                      marginTop: "80px",
                      background: "rgb(205,217,225)",
                      minHeight: "80vh",
                      // minHeight: "100vh",
                      marginLeft: "95px",
                    }}
                  >
                    <div style={{ width: "100%" }}>{torender}</div>
                  </Content>
                </Layout>
              </>
            )}
          </>
        ) : (
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  changeActiveRoute,
  login,
  logout,
})(Dashboard);
