import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Icon,
  Typography,
  Divider,
  Modal,
  Popconfirm,
  Row,
  Col,
} from "antd";

import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import {
  ChangeTrainerSearchText,
  ChangeStudentTableData,
  ChangeTrainerModalState,
} from "../../../actions/adminAction";
import "./allstudent.css";
import Alert from "../../../components/common/alert";
import { SecureDelete, SecurePost } from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import NewTrainerForm from "../newTrainer/newtrainer";
import CrossIcon from "../../../assets/crossIcon.svg";

class AllStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TrainertableLoading: false,
      isInfo: false,
      activeStudent: null,
    };
  }

  openModal = (id, mode) => {
    this.props.ChangeTrainerModalState(true, id, mode);
  };

  closeModal = () => {
    this.props.ChangeTrainerModalState(false, null, "Register");
  };
  handleAproved = async (id) => {
    try {
      const res = await SecurePost({
        url: `${apis.APROVE_STUDENT}`,
        data: {
          studentId: id,
        },
      });

      if (res) {
        this.props.ChangeStudentTableData();
      }
    } catch (error) {}
  };

  componentDidMount() {
    this.props.ChangeStudentTableData();
  }

  deleteStudent = (id) => {
    SecureDelete({
      url: `${apis.DELETE_STUDENT}${id}`,
    })
      .then((response) => {
        if (response.data.message) {
          Alert("success", "Success", response.data.message);
          this.props.ChangeStudentTableData();
        } else {
          return Alert("warning", "Warning!", response.data.message);
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.props.admin.TrainersearchText]}
        autoEscape
        textToHighlight={text?.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.props.ChangeTrainerSearchText(selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.props.ChangeTrainerSearchText("");
  };

  render() {
    const { Title } = Typography;
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "25%",
        render: (text) => text || "N/A",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Email Id",
        dataIndex: "emailid",
        key: "emailid",
        width: "25%",
        render: (text) => text || "N/A",
        ...this.getColumnSearchProps("emailid"),
      },
      {
        title: "Contact Number",
        dataIndex: "contact",
        key: "contact",
        render: (text) => text || "N/A",
        ...this.getColumnSearchProps("contact"),
      },
      {
        title: "Level",
        dataIndex: "level",
        key: "level",
        render: (text) => text || "N/A",
        ...this.getColumnSearchProps("level"),
      },
      {
        title: "Tutor Name",
        dataIndex: "tutorName",
        key: "tutorName",
        render: (text) => text || "N/A",
        ...this.getColumnSearchProps("tutorName"),
      },
      {
        title: "Action",
        key: "_id",
        dataIndex: "_id",
        obj: "obj",
        render: (key, obj) => (
          <span>
            <Button type="primary" onClick={() => this.handleAproved(key)}>
              {obj.approved ? "Approved" : "Pending"}
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure？"
              cancelText="No"
              okText="Yes"
              onConfirm={() => {
                this.deleteStudent(key);
              }}
              icon={<Icon type="delete" style={{ color: "red" }} />}
            >
              <Button type="danger" shape="circle" icon="delete" />
            </Popconfirm>
            <Divider type="vertical" />

            <Button
              type="primary"
              shape="circle"
              icon="info-circle"
              onClick={() =>
                this.setState({ isInfo: true, activeStudent: obj })
              }
            />
          </span>
        ),
      },
    ];
    return (
      <div className="admin-table-container">
        <div className="register-trainer-form-header">
          <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
            List of Students
          </Title>
        </div>
        <Table
          bordered={true}
          columns={columns}
          dataSource={this.props.admin.studentTableData}
          size="medium"
          pagination={{ pageSize: 10 }}
          loading={this.props.admin.studentTableLoadingStatus}
          rowKey="_id"
          style={{ backgroundColor: "#fff", padding: "10px" }}
        />
        <Modal
          visible={this.props.admin.TrainermodalOpened}
          title={false}
          onOk={this.handleOk}
          onCancel={this.closeModal}
          style={{
            top: "20px",
            padding: "0px",
            backgroundColor: "rgb(155,175,190)",
          }}
          width="40%"
          destroyOnClose={true}
          footer={[]}
        >
          <NewTrainerForm />
        </Modal>

        <Modal
          visible={this.state.isInfo}
          footer={null}
          closable={false}
          // centered
          onCancel={() => this.setState({ isInfo: false })}
          className="rewardMModalAdmin"
          width={650}
          style={{
            top: "30%",
            borderRadius: "25px",
            backgroundColor: "#ffff",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                src={CrossIcon}
                alt=""
                onClick={() => this.setState({ isInfo: false })}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                width: "100%",
              }}
            >
              <Typography style={{ padding: 0 }} className="cretaCateText">
                {this.state?.activeStudent?.name}
              </Typography>

              <Typography>
                Amount of points: {this.state?.activeStudent?.points}
                points
              </Typography>
              <Typography>Purchase History</Typography>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <Typography className="tableHeadTextInvoice">Name</Typography>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Typography className="tableHeadTextInvoice">
                    Invoice No.
                  </Typography>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Typography className="tableHeadTextInvoice">Cost</Typography>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Typography className="tableHeadTextInvoice">
                    Status
                  </Typography>
                </Col>
              </Row>
              {this.state?.activeStudent?.rewardHistory?.length !== 0 ? (
                this.state?.activeStudent?.rewardHistory?.map((item) => (
                  <Row gutter={16}>
                    <Col className="gutter-row" span={6}>
                      <Typography
                        style={{ fontSize: "12px" }}
                        className="tableHeadTextInvoice"
                      >
                        {item?.rewardId?.name}
                      </Typography>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Typography
                        style={{ fontSize: "12px" }}
                        className="tableHeadTextInvoice"
                      >
                        {item?.invoiceNumber}
                      </Typography>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Typography
                        style={{ fontSize: "12px" }}
                        className="tableHeadTextInvoice"
                      >
                        {item?.rewardId?.cost}
                      </Typography>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Typography
                        style={{ fontSize: "12px" }}
                        className="tableHeadTextInvoice"
                      >
                        {item?.status === "past" ? "Delivered" : "Un Deliver"}
                      </Typography>
                    </Col>
                  </Row>
                ))
              ) : (
                <Typography>No History Found</Typography>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, {
  ChangeTrainerSearchText,
  ChangeStudentTableData,
  ChangeTrainerModalState,
})(AllStudent);
