import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Input,
  Button,
  Icon,
  Typography,
  Divider,
  Tabs,
  Modal,
  Pagination,
  Spin,
} from "antd";
import Highlighter from "react-highlight-words";
import { connect, useDispatch } from "react-redux";
import {
  ChangeStudentTableData,
  ChangeTrainerSearchText,
} from "../../../../actions/adminAction";
import {
  GetTestTableData,
  ProceedtoTest,
  fetchTestdata,
  fetchTraineedata,
  setTestDetsils,
} from "../../../../actions/traineeAction";

import {
  ChangeAssignTestModalState,
  GetAllTest,
} from "../../../../actions/trainerAction";
import "../allStudentTest.css";
import { withRouter } from "react-router-dom";
import { CaretUpOutlined } from "@ant-design/icons";
import {
  givenExams,
  givenLevels,
  givenSchools,
  givenYears,
  paper,
  terms,
} from "../../../common/constants";
import { truncateString } from "../../examPortal/TestBoardHeaderTest";
import { useWindowDimensions } from "../../examPortal/testBoard";
import { getRowsTable } from "../../utils";
import DiamondIcon from "../../../../assets/diamond.svg";
import AuthServices from "../../../../services/AuthServices";
import { login } from "../../../../actions/loginAction";
import { Post } from "../../../../services/axiosCall";
import apis from "../../../../services/Apis";

const SubjectTestData = (props) => {
  const { trainee, GetTestTableData, title } = props;
  const searchInputRef = useRef(null);
  const [mainlink, setMainlink] = useState("");
  const { TabPane } = Tabs;
  const [showData, setShowData] = useState({});
  const [filterQuiries, setFilterQuiries] = useState([]);
  const [filterQuiries1, setFilterQuiries1] = useState([]);

  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [allStudentData, setAllStudentData] = useState(trainee?.allStudentTest);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [takeTestIdData, setTakeTestIdData] = useState({});
  const [isLoader, setIsLoader] = useState(false);

  const componentRef = useRef(null);

  const { height, width } = useWindowDimensions();
  const rows = getRowsTable(height);

  const handleOpen = (key, obj) => {
    console.log("click");
    setTakeTestIdData({ key, obj });
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleClick = (e) => {
    const btnId = e.target.dataset.id;
    setShowData({ [btnId]: true });
  };
  const handleFilterQuiries = (e, name) => {
    const filterString = e.target.dataset.id;
    setShowData({});
    setSortedData([]);

    // Check if a filter with the same name already exists
    const existingFilter = filterQuiries1.find(
      (filter) => Object.keys(filter)[0] === name
    );

    if (existingFilter) {
      // Update the value of the existing filter
      setFilterQuiries1(
        filterQuiries1.map((filter) =>
          Object.keys(filter)[0] === name
            ? { [name]: e.target.dataset.id }
            : filter
        )
      );
    } else {
      // Add a new entry for the filter
      setFilterQuiries1([...filterQuiries1, { [name]: e.target.dataset.id }]);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = rows;

  const data =
    sortedData.length > 0
      ? sortedData
      : trainee?.allStudentTest?.filter((record) =>
          filterQuiries1
            ?.map((obj) => Object.values(obj)[0])
            ?.every((filter) =>
              Object?.values(record)?.some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(filter?.toLowerCase())
              )
            )
        ) || [];

  // Function to handle pagination changes
  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const paginationConfig = {
    // showSizeChanger: true,
    defaultCurrent: 1,
    defaultPageSize: pageSize,
    pageSize: pageSize,
    total: data.length,
    current: currentPage,
    onChange: handlePaginationChange,
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleData = data.slice(startIndex, endIndex);
  const dispatch = useDispatch();

  const handleSort = (columnName) => {
    const sortedData = [...trainee.allStudentTest];
    setFilterQuiries1([]);

    sortedData.sort((a, b) => {
      const valueA = a[columnName] || "";
      const valueB = b[columnName] || "";

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });

    // Reverse the sorted data if the column is already sorted in ascending order
    if (sortOrder === "asc") {
      sortedData.reverse();
    }

    // Update the sorted data and column sortOrder in the state
    setSortedData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  useEffect(() => {
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined" && title) {
      AuthServices.FetchAuth(t, title).then((response) => {
        props.login(response.data.user);
      });
    } else {
      window.location = "/";
    }
  }, [title]);
  useEffect(() => {
    if (title) {
      GetTestTableData({ title: title });
    }

    // GetTestTableData();
    // if (trainee?.allStudentTest) {
    //   setAllStudentData(trainee?.allStudentTest);
    // }
    const link = window.location.href.split("/").splice(0, 3);
    let mainlink = "";
    link.forEach((d, i) => {
      mainlink = mainlink + d + "/";
    });
    setMainlink(mainlink);
  }, [title, GetTestTableData]);

  // useEffect(() => {
  //   let pAnswere = Post({
  //     url: `${apis.FETCH_TRAINEE_TEST_ANSWERSHEET}`,
  //     data: {
  //       testid: params.testid,
  //       userid: params.traineeid,
  //     },
  //   });

  //   let pquestions = Post({
  //     url: `${apis.FETCH_TRAINEE_TEST_QUESTION}`,
  //     data: {
  //       id: params.testid,
  //     },
  //   });

  //   Promise.all([pAnswere, pquestions])
  //     .then((r) => {
  //       if (r[0].data.success && r[1].data.success) {
  //         var d = r[0]?.data?.data?.answers?.map((d, i) => {
  //           if (d.chosenOption.length !== 0) {
  //             return { ...d, isMarked: false, isAnswered: true };
  //           } else if (
  //             d.customAnswer !== undefined &&
  //             d.customAnswer !== ""
  //           ) {
  //             return { ...d, isMarked: false, isAnswered: true };
  //           } else return { ...d, isMarked: false, isAnswered: false };
  //         });
  //         dispatch({
  //           type: "UPDATE_TRAINEE_TEST_ANSWERSHEET",
  //           payload: d,
  //         });
  //         dispatch({
  //           type: "UPDATE_TRAINEE_TEST_QUESTIONS",
  //           payload: r[1].data.data,
  //           payload1: r[1].data.title,
  //         });
  //         fetchTestdata(params.testid, params.traineeid);
  //         setLoading(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       const message = error.response
  //         ? error.response.data.message
  //         : error.message;
  //       // return Alert("error", "Error!", message);
  //     });
  // }, [params.testid, params.traineeid]);

  console.log(props.trainee);

  const handleFetchTestDataFirst = () => {
    props.ProceedtoTest(
      takeTestIdData?.key,
      takeTestIdData?.obj?.studentId,
      () => {
        props.fetchTestdata(
          takeTestIdData?.key,
          takeTestIdData?.obj?.studentId
        );
      }
    );
    props.setTestDetsils(takeTestIdData?.key, takeTestIdData?.obj?.studentId);
    props.fetchTraineedata(takeTestIdData?.obj?.studentId);
    setIsLoader(true);

    let pAnswere = Post({
      url: `${apis.FETCH_TRAINEE_TEST_ANSWERSHEET}`,
      data: {
        testid: takeTestIdData?.key,
        userid: takeTestIdData?.obj?.studentId,
      },
    });

    let pquestions = Post({
      url: `${apis.FETCH_TRAINEE_TEST_QUESTION}`,
      data: {
        id: takeTestIdData?.key,
      },
    });

    Promise.all([pAnswere, pquestions])
      .then((r) => {
        if (r[0].data.success && r[1].data.success) {
          var d = r[0]?.data?.data?.answers?.map((d, i) => {
            if (d.chosenOption.length !== 0) {
              return { ...d, isMarked: false, isAnswered: true };
            } else if (d.customAnswer !== undefined && d.customAnswer !== "") {
              return { ...d, isMarked: false, isAnswered: true };
            } else return { ...d, isMarked: false, isAnswered: false };
          });
          console.log("ddd", d);
          dispatch({
            type: "UPDATE_TRAINEE_TEST_ANSWERSHEET",
            payload: d || [],
          });
          dispatch({
            type: "UPDATE_TRAINEE_TEST_QUESTIONS",
            payload: r[1].data.data,
            payload1: r[1].data.title,
          });
          props.fetchTestdata(
            takeTestIdData?.key,
            takeTestIdData?.obj?.studentId
          );
          setTimeout(() => {
            setIsLoader(false);
            window.location.href = `${
              window.location.origin
            }/#/trainee/taketest?testid=${takeTestIdData?.key}&traineeid=${
              takeTestIdData?.obj?.studentId
            }&isCorrect=${true}`;
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error.response
          ? error.response.data.message
          : error.message;
        // return Alert("error", "Error!", message);
      });
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      const clickedText = e.target.innerText;
      const isInsideDropdown =
        ["Take Test", "Running"].includes(clickedText) ||
        givenLevels.some((item) => item.label === clickedText) ||
        givenYears.some((item) => item.label === clickedText) ||
        terms.some((item) => item.label === clickedText) ||
        givenSchools.some((item) => item.label === clickedText) ||
        paper.some((item) => item.label === clickedText);

      if (
        !isInsideDropdown &&
        componentRef.current &&
        !componentRef.current.contains(e.target)
      ) {
        setShowData({});
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: "18%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {truncateString(text, 25) || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("title"),
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{
            backgroundColor: "transparent",
            color: "white",
            textTransform: "capitalize",
          }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("level"),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("year"),
    },
    {
      title: "Term",
      dataIndex: "term",
      key: "term",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("term"),
    },
    {
      title: "School",
      dataIndex: "school",
      key: "school",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("school"),
    },
    {
      title: "Paper",
      dataIndex: "exam",
      key: "exam",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("paper"),
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {" "}
          + <img width={"16px"} src={DiamondIcon} alt="" /> {text || "N/A"}{" "}
          points
        </div>
      ),
      // ...getColumnSearchProps("paper"),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: "10%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text + " minutes" || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("duration"),
    },

    {
      title: "",
      key: "_id",
      dataIndex: "_id",
      render: (key, obj) => (
        <span>
          {key && (
            <Button
              style={{
                borderRadius: "35px",
                backgroundColor: obj.test_running ? "#3DD67A" : "#0082fb",
                borderColor: obj.test_running ? "#3DD67A" : "#0082fb",
              }}
              type="primary"
              onClick={() => {
                handleOpen(key, obj);
              }}
            >
              {obj.test_running ? "Running" : "Take Test"}
            </Button>
          )}
        </span>
      ),
    },
  ];

  // console.log("takeTestIdData", takeTestIdData);

  const rowClassNameFn = (record, index) => {
    return index % 2 === 0 ? "even_row" : "odd_row";
  };
  return (
    <>
      <div className="custom_trainee_table">
        <div className="sorting_Buttons">
          <div
            data-id={"Level"}
            className="sortButton"
            ref={componentRef}
            onClick={handleClick}
          >
            <Typography data-id={"Level"} className="sortText">
              {filterQuiries1?.find((item) => Object.keys(item)[0] === "Level")
                ? filterQuiries1?.find(
                    (item) => Object.keys(item)[0] === "Level"
                  ).Level
                : "Level"}
            </Typography>
            <div className="icons" onClick={() => handleSort("level")}>
              <Icon style={{ fontSize: "10px" }} type="up" />
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>
            {showData?.Level && (
              <div className="showData">
                {givenLevels.map((item) => (
                  <Typography
                    data-id={item.value}
                    onClick={(e) => handleFilterQuiries(e, "Level")}
                    name="Level"
                    style={{ color: "white" }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          <div
            data-id={"Year"}
            className="sortButton"
            ref={componentRef}
            onClick={handleClick}
          >
            <Typography data-id={"Year"} className="sortText">
              {filterQuiries1.find((item) => Object.keys(item)[0] === "Year")
                ? filterQuiries1.find((item) => Object.keys(item)[0] === "Year")
                    .Year
                : "Year"}
            </Typography>
            <div onClick={() => handleSort("year")} className="icons">
              <Icon style={{ fontSize: "10px" }} type="up" />{" "}
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>
            {showData?.Year && (
              <div className="showData">
                {givenYears.map((item) => (
                  <Typography
                    data-id={item.value}
                    onClick={(e) => handleFilterQuiries(e, "Year")}
                    name="Year"
                    style={{ color: "white" }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          <div
            data-id={"Term"}
            ref={componentRef}
            className="sortButton"
            onClick={handleClick}
          >
            <Typography data-id={"Term"} className="sortText">
              {filterQuiries1.find((item) => Object.keys(item)[0] === "Term")
                ? filterQuiries1.find((item) => Object.keys(item)[0] === "Term")
                    .Term
                : "Term"}
            </Typography>
            <div onClick={() => handleSort("term")} className="icons">
              <Icon style={{ fontSize: "10px" }} type="up" />{" "}
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>
            {showData?.Term && (
              <div className="showData">
                {terms.map((item) => (
                  <Typography
                    data-id={item.value}
                    name="Term"
                    onClick={(e) => handleFilterQuiries(e, "Term")}
                    style={{
                      color: "white",
                    }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          <div
            data-id={"School"}
            ref={componentRef}
            className="sortButton"
            onClick={handleClick}
          >
            <Typography data-id={"School"} className="sortText">
              {filterQuiries1.find((item) => Object.keys(item)[0] === "School")
                ? filterQuiries1.find(
                    (item) => Object.keys(item)[0] === "School"
                  ).School
                : "School"}
            </Typography>
            <div onClick={() => handleSort("school")} className="icons">
              <Icon style={{ fontSize: "10px" }} type="up" />{" "}
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>

            {showData?.School && (
              <div className="showData">
                {givenSchools.map((item) => (
                  <Typography
                    data-id={item.value}
                    name="School"
                    onClick={(e) => handleFilterQuiries(e, "School")}
                    style={{ color: "white" }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          <div
            className="sortButton"
            ref={componentRef}
            onClick={handleClick}
            data-id={"Paper"}
          >
            <Typography data-id={"Paper"} className="sortText">
              {filterQuiries1.find((item) => Object.keys(item)[0] === "Paper")
                ? filterQuiries1.find(
                    (item) => Object.keys(item)[0] === "Paper"
                  ).Paper
                : "Paper"}
            </Typography>
            <div className="icons" onClick={() => handleSort("exam")}>
              <Icon style={{ fontSize: "10px" }} type="up" />{" "}
              <Icon style={{ fontSize: "10px" }} type="down" />
            </div>
            {showData?.Paper && (
              <div className="showData">
                {paper.map((item) => (
                  <Typography
                    data-id={item.value}
                    name="Paper"
                    onClick={(e) => handleFilterQuiries(e, "Paper")}
                    style={{ color: "white" }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "55vh",
            height: "100%",
          }}
        >
          <Table
            columns={columns}
            dataSource={visibleData}
            size="medium"
            // pagination={{ pageSize: 4 }}
            loading={trainee.allStudentTestLoading}
            rowKey="_id"
            style={{
              backgroundColor: "transparent",
              color: "white !important",
            }}
            rowClassName={rowClassNameFn}
            components={{
              header: {
                cell: (props) => (
                  <th
                    className="custom_table_header"
                    style={{
                      backgroundColor: "transparent", // Set your desired header background color
                      color: "white", // Set the font color
                      fontWeight: "bold", // Set the font weight
                    }}
                    {...props}
                  />
                ),
              },
            }}
            className="custom_tables"
          />
          <Pagination
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "12px",
            }}
            {...paginationConfig}
          />
        </div>
      </div>
      <Modal
        visible={isModalOpen}
        footer={null}
        closable={false}
        onCancel={handleCancel}
        centered
        width={350}
        style={{ borderRadius: "25px" }}
      >
        {isLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <p className="modalTitle">
              {takeTestIdData?.obj?.test_running
                ? "Your test is currently running. Would you like to enter again?"
                : "Are you sure you want to start this assignment?"}
            </p>
            <div className="modalButtons">
              <Button
                className="modalButtonYes"
                // onClick={() => {
                //   props.ProceedtoTest(
                //     takeTestIdData?.key,
                //     takeTestIdData?.obj?.studentId,
                //     () => {
                //       props.fetchTestdata(
                //         takeTestIdData?.key,
                //         takeTestIdData?.obj?.studentId
                //       );
                //     }
                //   );
                //   setIsLoader(true);
                //   setTimeout(() => {
                //     setIsLoader(false);
                //     window.location.href = `${
                //       window.location.origin
                //     }/#/trainee/taketest?testid=${
                //       takeTestIdData?.key
                //     }&traineeid=${
                //       takeTestIdData?.obj?.studentId
                //     }&isCorrect=${true}`;
                //   }, 2000);
                // }}
                onClick={handleFetchTestDataFirst}
              >
                Yes
              </Button>
              <Button className="modalButtonNo" onClick={handleCancel}>
                No
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  trainer: state.trainer,
  trainee: state.trainee,
});

export default withRouter(
  connect(mapStateToProps, {
    ChangeAssignTestModalState,
    ChangeStudentTableData,
    GetTestTableData,
    ChangeTrainerSearchText,
    ProceedtoTest,
    fetchTraineedata,
    fetchTestdata,
    login,
    setTestDetsils,
  })(SubjectTestData)
);
