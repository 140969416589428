import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Icon,
  Typography,
  Modal,
  Tag,
  Popconfirm,
  Divider,
} from "antd";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import {
  ChangeTestSearchText,
  ChangeTestTableData,
  ChangeTestDetailsModalState,
} from "../../../actions/trainerAction";
import "./alltest.css";
import moment from "moment";

import TestDetails from "../testdetails/testdetails";
import { SecurePost } from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import Alert from "../../common/alert";

class AllTests extends Component {
  openModal = (id) => {
    this.props.ChangeTestDetailsModalState(true, id);
  };

  closeModal = () => {
    this.props.ChangeTestDetailsModalState(false, null);
  };
  componentDidMount() {
    this.props.ChangeTestTableData();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.props.trainer.TestsearchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  getColumnSubjectSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const subjects = record[dataIndex];
      return subjects.some((subject) =>
        subject.topic.toLowerCase().includes(value.toLowerCase())
      );
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.props.trainer.TestsearchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  getColumnDateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const createdAt = moment(record[dataIndex]).format("DD/MM/YYYY");
      return createdAt.toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.props.trainer.TestsearchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.props.ChangeTestSearchText(selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.props.ChangeTestSearchText("");
  };

  deleteTest = (id) => {
    SecurePost({
      url: `${apis.DELETE_TEST}`,
      data: { _id: id },
    })
      .then((response) => {
        if (response.data.success) {
          Alert("success", "Success", response.data.message);
          this.props.ChangeTestTableData();
        } else return Alert("warning", "Warning!", response.data.message);
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  render() {
    const { Title } = Typography;

    const columns = [
      {
        title: "Name",
        dataIndex: "title",
        key: "title",
        sorter: (a, b) => a.title > b.title,
        render: (text) => text || "N/A",
        ...this.getColumnSearchProps("title"),
      },
      {
        title: "Subjects",
        dataIndex: "subjects",
        key: "subjects.topic",
        sorter: (a, b) => {
          const topicA =
            a.subjects && a.subjects.length > 0 ? a.subjects[0].topic : "";
          const topicB =
            b.subjects && b.subjects.length > 0 ? b.subjects[0].topic : "";
          return topicA.localeCompare(topicB);
        },
        ...this.getColumnSubjectSearchProps("subjects"),
        render: (tags) =>
          (
            <span>
              {tags
                ? tags.map((tag, i) => {
                    let color = "geekblue";
                    return (
                      <Tag color={color} key={tag._id}>
                        {tag.topic.toUpperCase()}
                      </Tag>
                    );
                  })
                : "N/A"}
            </span>
          ) || "N/A",
      },

      {
        title: "Created on",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => a.createdAt > b.createdAt,
        ...this.getColumnDateSearchProps("createdAt"),
        render: (tags) =>
          <span>{moment(tags).format("DD/MM/YYYY")}</span> || "N/A",
      },
      {
        title: "Created By",
        dataIndex: "createdBy",
        key: "createdBy",
        render: (text) =>
          (
            <>
              <div>
                {text !== null
                  ? text?.name !== null
                    ? text?.name
                    : "N/A"
                  : "N/A"}
              </div>
              <div>
                {text !== null
                  ? text?.emailid !== null
                    ? text?.emailid
                    : ""
                  : ""}
              </div>
            </>
          ) || "N/A",
      },
      // {
      //   title: "Ended",
      //   dataIndex: "testconducted",
      //   key: "testconducted",
      //   render: (dead) => {
      //     return dead ? "Yes" : "No";
      //   },
      // },
      {
        title: "Action",
        key: "_id",
        dataIndex: "_id",
        render: (key) => (
          <span>
            <Button
              type="primary"
              shape="circle"
              icon="info-circle"
              onClick={() => this.openModal(key)}
            />
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure？"
              cancelText="No"
              okText="Yes"
              onConfirm={() => {
                this.deleteTest(key);
              }}
              icon={<Icon type="delete" style={{ color: "red" }} />}
            >
              <Button type="danger" shape="circle" icon="delete" />
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <div className="admin-table-container">
        <div className="register-trainer-form-header">
          <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
            List of Tests
          </Title>
        </div>
        <Table
          bordered={true}
          columns={columns}
          dataSource={this.props.trainer.TestTableData}
          size="medium"
          pagination={{ pageSize: 10 }}
          loading={this.props.trainer.TestTableLoading}
          rowKey="_id"
          style={{ backgroundColor: "#fff", padding: "10px" }}
        />
        <Modal
          visible={this.props.trainer.TestDetailsmodalOpened}
          title="Test details"
          onOk={this.handleOk}
          onCancel={this.closeModal}
          afterClose={this.closeModal}
          style={{
            top: "20px",
            padding: "0px",
            backgroundColor: "rgb(155,175,190)",
          }}
          width="80%"
          bodyStyle={{ maxHeight: "80vh", overflow: "auto" }}
          destroyOnClose={true}
          footer={[]}
        >
          <TestDetails />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trainer: state.trainer,
  };
};

export default connect(mapStateToProps, {
  ChangeTestSearchText,
  ChangeTestTableData,
  ChangeTestDetailsModalState,
})(AllTests);
