import React, { useEffect } from "react";
import { Table, Button, Typography, Divider, Modal } from "antd";

import { connect } from "react-redux";
import {
  ChangeTrainerSearchText,
  ChangeStudentTableData,
  ChangeTrainerModalState,
} from "../../../actions/adminAction";
import "../allStudent/allstudent.css";

import CrossIcon from "../../../assets/crossIcon.svg";
import { useState } from "react";
import AuthServices from "../../../services/AuthServices";

const AllPastOrder = () => {
  const [isInfo, setIsInfo] = useState(false);
  const [activeObject, setActiveObject] = useState();

  const [pastOrders, setPastOrders] = useState([]);

  useEffect(() => {
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      AuthServices.GetAllPastOrders(t).then((response) => {
        setPastOrders(response?.data?.data);
        // props.GetActiveOrders(response?.data?.data);
      });
    } else {
      window.location = "/";
    }
  }, []);

  const { Title } = Typography;
  const columns = [
    {
      title: "Name",
      dataIndex: "userId",
      key: "userId",
      width: "25%",
      obj: "obj",

      render: (key, obj) => obj?.userId?.name || "N/A",
      // ...getColumnSearchProps("name"),
    },
    {
      title: "Order name",
      dataIndex: "rewardId",
      key: "_id",
      obj: "obj",

      width: "25%",
      render: (key, obj) => obj?.rewardId?.name || "N/A",
      // ...getColumnSearchProps("emailid"),
    },
    {
      title: "Category",
      dataIndex: "rewardId",
      key: "rewardId",
      obj: "obj",

      render: (key, obj) => obj?.rewardId?.categoryId?.name || "N/A",
      // ...getColumnSearchProps("contact"),
    },
    {
      title: "Invoice number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (text) => text || "N/A",
      // ...getColumnSearchProps("level"),
    },
    {
      title: "Date Delivered ",
      dataIndex: "deliverDate",
      key: "deliverDate",
      render: (text) =>
        `${new Date(text).getMonth() + 1}/${new Date(
          text
        ).getDate()}/${new Date(text).getFullYear()}` || "N/A",
      // ...getColumnSearchProps("level"),
    },

    {
      title: "Action",
      key: "_id",
      dataIndex: "_id",
      obj: "obj",
      render: (key, obj) => (
        <span>
          <Button
            type="primary"
            shape="circle"
            icon="info-circle"
            onClick={() => {
              setActiveObject(obj);
              setIsInfo(true);
            }}
          />
          <Divider type="vertical" />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="admin-table-container">
        <div className="register-trainer-form-header">
          <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
            Past Orders
          </Title>
        </div>
        <Table
          bordered={true}
          columns={columns}
          dataSource={pastOrders}
          size="medium"
          pagination={{ pageSize: 10 }}
          rowKey="_id"
          style={{ backgroundColor: "#fff", padding: "10px" }}
        />
      </div>
      <Modal
        visible={isInfo}
        footer={null}
        closable={false}
        // centered
        onCancel={() => setIsInfo(false)}
        className="rewardMModalAdmin"
        width={350}
        style={{
          top: "30%",
          borderRadius: "25px",
          backgroundColor: "#ffff",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={CrossIcon}
              alt=""
              onClick={() => setIsInfo(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography style={{ padding: 0 }} className="cretaCateText">
              Invoice Number: {activeObject?.invoiceNumber}
            </Typography>
            <Typography>
              Customer Name: {activeObject?.userId?.name}{" "}
            </Typography>
            <Typography>
              Name:{activeObject?.rewardId?.categoryId?.name}
            </Typography>
            <Typography>
              Date Ordered:
              {`${new Date(activeObject?.date).getMonth() + 1}/${new Date(
                activeObject?.date
              ).getDate()}/${new Date(activeObject?.date).getFullYear()}`}
            </Typography>
            <Typography>
              Date Delivered:
              {`${
                new Date(activeObject?.deliverDate).getMonth() + 1
              }/${new Date(activeObject?.deliverDate).getDate()}/${new Date(
                activeObject?.deliverDate
              ).getFullYear()}`}
            </Typography>
            <Typography>Order Cost: {activeObject?.rewardId?.cost}</Typography>
          </div>
        </div>
      </Modal>{" "}
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, {
  ChangeTrainerSearchText,
  ChangeStudentTableData,
  ChangeTrainerModalState,
})(AllPastOrder);
