import { combineReducers } from "redux";
import openDrawer from "./opendrawer";
import adminAction from "./admin";
import userAction from "./loggedinuser";
import trainerAction from "./trainer";
import testAction from "./test";
import conductTest from "./conductTest";
import trainee from "./trainee";
import traineestate from "./traineestate";
import traineestatecomponent from "./traineestatecomponent";
import subjectTutor from "./subjectTutor";
import consultation from "./consultation";
import category from "./category";
import rewards from "./rewards";
import activeorders from "./activeorders";

export default combineReducers({
  drawer: openDrawer,
  admin: adminAction,
  activeorders: activeorders,
  rewards: rewards,
  category: category,
  user: userAction,
  trainer: trainerAction,
  test: testAction,
  conduct: conductTest,
  trainee: trainee,
  traineestate: traineestate,
  traineestatecomponent: traineestatecomponent,
  subjectTutor: subjectTutor,
  consultation: consultation,
});
