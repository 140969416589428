import React, { Component } from "react";
import "./newtrainer.css";
import { Form, Input, Button, Select } from "antd";
import { SecurePost } from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import { connect } from "react-redux";
import {
  ChangeTrainerConfirmDirty,
  ChangeTrainerModalState,
  ChangeTrainerTableData,
} from "../../../actions/adminAction";
import Alert from "../../../components/common/alert";
import { subjects } from "../../common/constants";
const { Option } = Select;
class NewTrainer extends Component {
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("passwords are not same !");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.admin.TrainerconfirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleSubjectChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        SecurePost({
          url: `${apis.CREATE_TRAINER}`,
          data: {
            _id: this.props.admin.trainerId,
            name: values.name,
            password: values.password,
            emailid: values.emailid,
            contact: values.prefix + values.contact,
            subject: values.subject,
          },
        })
          .then((response) => {
            if (response.data.success) {
              this.props.ChangeTrainerModalState(false, null, "Register");
              Alert("success", "Success", response.data.message);
              this.props.ChangeTrainerTableData();
            } else {
              this.props.ChangeTrainerModalState(false, null, "Register");
              return Alert("warning", "Warning!", response.data.message);
            }
          })
          .catch((error) => {
            this.props.ChangeTrainerModalState(false, null, "Register");
            const message = error.response
              ? error.response.data.message
              : error.message;
            return Alert("error", "Error!", message);
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: this.props.admin.trainerdetails.prefix || "+65",
      rules: [{ required: true, message: "Please enter contact no prefix" }],
    })(
      <Select style={{ width: 70 }}>
        <Option value="+65">+65</Option>
      </Select>
    );
    return (
      <div className="register-trainer-form">
        <div className="register-trainer-form-body">
          <Form onSubmit={this.handleSubmit}>
            <Form.Item
              label="Subject"
              hasFeedback
              className="input-admin-trainer"
            >
              {getFieldDecorator("subject", {
                initialValue: this.props.admin.trainerdetails.subject,

                rules: [
                  {
                    required: true,
                    message: "Please select any subject!",
                  },
                ],
              })(
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a subject"
                  optionFilterProp="s"
                >
                  {subjects.map((d, i) => (
                    <Option
                      key={d._id}
                      s={d.value}
                      value={d.value}
                      onClick={() => {
                        this.handleSubjectChange("subject", d.value);
                      }}
                    >
                      {d.value}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Name" hasFeedback className="input-admin-trainer">
              {getFieldDecorator("name", {
                initialValue: this.props.admin.trainerdetails.name,
                rules: [
                  {
                    required: true,
                    message: "Please input your name!",
                    whitespace: true,
                  },
                ],
              })(<Input />)}
            </Form.Item>

            {!this.props.admin.trainerId ? (
              <Form.Item
                label="E-mail"
                hasFeedback
                className="input-admin-trainer"
              >
                {getFieldDecorator("emailid", {
                  initialValue: this.props.admin.trainerdetails.emailid,
                  rules: [
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            ) : null}

            <Form.Item label="Phone Number" className="input-admin-trainer">
              {getFieldDecorator("contact", {
                initialValue: this.props.admin.trainerdetails.contact,
                rules: [
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    len: 8,
                    message: "Contact number must be 8 digit long",
                  },
                ],
              })(<Input addonBefore={prefixSelector} min={8} max={8} />)}
            </Form.Item>

            {!this.props.admin.trainerId ? (
              <div>
                <Form.Item
                  label="Password"
                  hasFeedback
                  className="input-admin-trainer"
                >
                  {getFieldDecorator("password", {
                    initialValue: this.props.admin.trainerdetails.password,
                    rules: [
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(<Input.Password />)}
                </Form.Item>

                <Form.Item
                  label="Confirm Password"
                  hasFeedback
                  className="input-admin-trainer"
                >
                  {getFieldDecorator("confirm", {
                    initialValue:
                      this.props.admin.trainerdetails.confirmpassword,
                    rules: [
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                </Form.Item>
              </div>
            ) : null}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {this.props.admin.Trainermode}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  admin: state.admin,
});

const NewTrainerForm = Form.create({ name: "register" })(NewTrainer);

export default connect(mapStateToProps, {
  ChangeTrainerConfirmDirty,
  ChangeTrainerModalState,
  ChangeTrainerTableData,
})(NewTrainerForm);
