/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import "./answer.css";
import "./answermobileview.css";
import "./individualquestion_mobileview.css";
import { Post } from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import Alert from "../../common/alert";
import {
  FeedbackStatus,
  fetchTraineeTestQuestionsResult,
} from "../../../actions/traineeAction";
import QuestionResult from "./QuestionResult";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AuthServices from "../../../services/AuthServices";

const Answer = ({ trainee, FeedbackStatus }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [role, setRole] = useState();

  const [totalScore, setTotalScore] = useState(null);
  const [visible, setVisible] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const [testName, setTestName] = useState(null);
  const [totalMarks, setTotaMarks] = useState();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isCorrectParam = searchParams.get("isCorrect");

  const traineeid = searchParams.get("traineeid");
  const testId = searchParams.get("testid");

  const isCorrect = isCorrectParam === "true";
  const [reFetch, setReFetch] = useState(false);

  useEffect(() => {
    // let { traineeid, testid } = trainee;
    const t = AuthServices.retriveToken();
    setLoading(true);
    // window.location.reload();

    if (traineeid && testId) {
      let p1;
      let p2;
      if (!isCorrect) {
        p1 = Post({
          url: apis.FETCH_OWN_RESULT,
          data: {
            userid: traineeid,
            testid: testId,
            isCorrect: isCorrect,
          },
          params: {
            Token: t,
          },
        });
        p2 = Post({
          url: `${apis.FETCH_TRAINEE_TEST_QUESTION}`,
          data: { id: testId, isCorrect: isCorrect, userId: traineeid },
        });
      } else {
        p1 = Post({
          url: apis.FETCH_OWN_RESULT,
          data: {
            userid: traineeid,
            testid: testId,
          },
          params: {
            Token: t,
          },
        });
        p2 = Post({
          url: `${apis.FETCH_TRAINEE_TEST_QUESTION}`,
          data: { id: testId },
        });
      }

      let p3 = Post({
        url: `${apis.FEEDBACK_STATUS_CHECK}`,
        data: { userid: traineeid, testid: testId },
      });

      Promise.all([p1, p2, p3])
        .then((d) => {
          setLoading(false);
          if (d[0].data.success && d[1].data.success) {
            fetchTraineeTestQuestionsResult(d[1].data.data);
            let v = d[1].data.data;
            let r = d[0].data.result.result.map((dd, i) => ({
              ...dd,
              ...v[i],
            }));
            setData(r);
            setTotalScore(d[0].data.result.score);
            setRole(d[0].data.role);
            setTestName(d[0].data.title);
            setTotaMarks(d[0].data.totalMarks);
            if (d[2].data.success) FeedbackStatus(d[2].data.status);
          } else {
            Alert(
              "error",
              "Error!",
              `${d[0].data.success ? "" : d[0].data.message} and ${
                d[1].data.success ? "" : d[1].data.message
              }`
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          const message = error.response
            ? error.response.data.message
            : error.message;
          // return Alert("error", "Error!", message);
        });
    }
  }, [trainee.traineeid, trainee.testid, reFetch]);

  let onOpen = () => setVisible(true);

  return (
    <div className="exam-dashboard-wrapper-new">
      <QuestionResult
        // mode={width > 768 ? "desktop" : "mobile"}
        reFetch={reFetch}
        setReFetch={setReFetch}
        role={role}
        data={data}
        isCorrect={isCorrect && isCorrect}
        triggerSidebar={onOpen}
        totalScore={totalScore}
        testName={testName}
        totalMarks={totalMarks}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default connect(mapStateToProps, {
  FeedbackStatus,
  fetchTraineeTestQuestionsResult,
})(Answer);
