import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Icon,
  Typography,
  Modal,
  Tag,
  Popconfirm,
  Divider,
  Row,
  Col,
  Form,
  Select,
} from "antd";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import {
  ChangeTestSearchText,
  ChangeConsultatationTableData,
  ChangeTestDetailsModalState,
} from "../../../actions/trainerAction";
import "./index.css";
import moment from "moment";
import UploadIcon from "../../../assets/fileUpload.svg";

import TestDetails from "../testdetails/testdetails";
import {
  SecureDelete,
  SecurePost,
  SecurePostMultipart,
} from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import Alert from "../../common/alert";
import {
  givenEnglishTopics,
  givenLevels,
  givenMathsTopics,
  givenScienceTopics,
} from "../../common/constants";
import AuthServices from "../../../services/AuthServices";

class QuestionConsultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModelOpen: false,
      question: {},
      file: null,
      values: {},
    };
  }
  openModal = (obj) => {
    this.setState({ isModelOpen: true, question: obj });
  };

  closeModal = () => {
    this.setState({ isModelOpen: false });
  };

  handleTopicChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.type.startsWith("video/")) {
        this.setState({ file: selectedFile });
      } else {
        Alert("warning", "Message", "Please select a valid video file.");
      }
    }
  };

  componentDidMount() {
    this.props.ChangeConsultatationTableData();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));
  };

  handleTopicChange = (name, value) => {
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value.value,
      },
    }));
  };

  handleSubmitResponse = () => {
    const t = AuthServices.retriveToken();
    const formDate = new FormData();

    if (!this.state.file) {
      Alert("warning", "Message", "Please Upload Video");
    }
    formDate.append("video", this.state.file);
    formDate.append("learningOutcomes", this.state.values.learningOutcomes);
    formDate.append("questionTopic", this.state.values.questionTopic);
    formDate.append("requestId", this.state.question._id);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (t && t !== "undefined") {
          this.submitConsultationResponse(t, formDate);
        } else {
          window.location = "/";
        }
      }
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.props.trainer.TestsearchText]}
        autoEscape
        textToHighlight={text?.toString()}
      />
    ),
  });

  getColumnDateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const createdAt = moment(record[dataIndex]).format("DD/MM/YYYY");
      return createdAt.toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.props.trainer.TestsearchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.props.ChangeTestSearchText(selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.props.ChangeTestSearchText("");
  };

  deleteTest = (id) => {
    SecurePost({
      url: `${apis.DELETE_CONSLTATION_QUESTION}`,
      data: { _id: id },
    })
      .then((response) => {
        if (response.data.success) {
          Alert("success", "Success", response.data.message);
          this.props.ChangeConsultatationTableData();
        } else return Alert("warning", "Warning!", response.data.message);
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  submitConsultationResponse = (t, data) => {
    SecurePostMultipart({
      url: `${apis.SUBMIT_CONSLTATION_QUESTION_RESPONSE}`,
      params: {
        Token: t,
      },
      data: data,
    })
      .then((response) => {
        if (response.data.success) {
          Alert("success", "Success", response.data.message);
          this.props.ChangeConsultatationTableData();
          this.setState({
            isModelOpen: false,
          });
        } else return Alert("warning", "Warning!", response.data.message);
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  render() {
    const { Title } = Typography;
    const { Option } = Select;
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.title > b.title,
        render: (text) => text || "N/A",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        // sorter: (a, b) => {
        //   const topicA =
        //     a.subjects && a.subjects.length > 0 ? a.subjects[0].topic : "";
        //   const topicB =
        //     b.subjects && b.subjects.length > 0 ? b.subjects[0].topic : "";
        //   return topicA.localeCompare(topicB);
        // },
        ...this.getColumnSearchProps("subject"),
        render: (tags) => <span>{tags ? tags : "N/A"}</span> || "N/A",
      },

      {
        title: "Created on",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => a.createdAt > b.createdAt,
        ...this.getColumnDateSearchProps("createdAt"),
        render: (tags) =>
          <span>{moment(tags).format("DD/MM/YYYY")}</span> || "N/A",
      },
      {
        title: "Created By",
        dataIndex: "student",
        key: "student",
        render: (text) =>
          (
            <>
              <div>
                {text !== null
                  ? text?.name !== null
                    ? text?.name
                    : "N/A"
                  : "N/A"}
              </div>
            </>
          ) || "N/A",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text) =>
          (
            <>
              <div>{text ? text : "N/A"}</div>
            </>
          ) || "N/A",
      },
      {
        title: "Action",
        key: "_id",
        dataIndex: "_id",
        render: (key, obj) => (
          <span>
            <Button
              type="primary"
              shape="circle"
              disabled={obj.status === "answer" ? true : false}
              icon="info-circle"
              onClick={() => this.openModal(obj)}
            />
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure？"
              cancelText="No"
              okText="Yes"
              onConfirm={() => {
                this.deleteTest(key);
              }}
              icon={<Icon type="delete" style={{ color: "red" }} />}
            >
              <Button type="danger" shape="circle" icon="delete" />
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <div className="admin-table-container">
        <div className="register-trainer-form-header">
          <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
            Question Consultation
          </Title>
        </div>
        <Table
          bordered={true}
          columns={columns}
          dataSource={this.props.trainer.ConsultationTabelData}
          size="medium"
          pagination={{ pageSize: 10 }}
          loading={this.props.trainer.TestTableLoading}
          rowKey="_id"
          style={{ backgroundColor: "#fff", padding: "10px" }}
        />
        <Modal
          visible={this.state.isModelOpen}
          title={this.state.question.name}
          onOk={this.handleOk}
          onCancel={this.closeModal}
          afterClose={this.closeModal}
          style={{
            top: "20px",
            padding: "0px",
            backgroundColor: "rgb(155,175,190)",
          }}
          width="80%"
          bodyStyle={{ maxHeight: "80vh", overflow: "auto" }}
          destroyOnClose={true}
          footer={[]}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Typography className="queryText">
                  {this.state?.question?.description}
                </Typography>
                <img
                  src={
                    apis.IMGAE_BASE_URL + this.state?.question?.questionImage
                  }
                  alt=""
                />
              </div>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={16}>
                <Col span={12} md={10} xs={24}>
                  <Form.Item label="Select learning outcome" hasFeedback>
                    {getFieldDecorator("query", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your query!",
                          whitespace: true,
                        },
                      ],
                    })(
                      <Input
                        name="learningOutcomes"
                        onChange={this.handleChange}
                        //   value={values.name}
                        //   className="input_editProfile"
                        //   onChange={handleChangeUser}
                        // style={{ borderRadius: "20px", height: "55px" }}
                        // placeholder="Basic usage"
                        aria-label="name"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} md={10} xs={24}>
                  <Form.Item label="Select question topic" hasFeedback>
                    {getFieldDecorator("topic", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your topic!",
                          whitespace: true,
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Level"
                        //   className="select_editProfile"
                        optionFilterProp="s"
                        //   value={values.level}
                      >
                        {this.state.question.subject &&
                        this.state.question.subject === "Mathematics"
                          ? givenMathsTopics.map((d, i) => (
                              <Option
                                key={d.value}
                                s={d.label}
                                value={d.value}
                                onClick={() =>
                                  this.handleTopicChange("questionTopic", {
                                    value: d.value,
                                  })
                                }
                              >
                                {d.label}
                              </Option>
                            ))
                          : this.state.question.subject &&
                            this.state.question.subject === "Science"
                          ? givenScienceTopics.map((d, i) => (
                              <Option
                                key={d.value}
                                s={d.label}
                                value={d.value}
                                onClick={() =>
                                  this.handleTopicChange("questionTopic", {
                                    value: d.value,
                                  })
                                }
                              >
                                {d.label}
                              </Option>
                            ))
                          : this.state.question.subject &&
                            this.state.question.subject === "English"
                          ? givenEnglishTopics.map((d, i) => (
                              <Option
                                key={d.value}
                                s={d.label}
                                value={d.value}
                                onClick={() =>
                                  this.handleTopicChange("questionTopic", {
                                    value: d.value,
                                  })
                                }
                              >
                                {d.label}
                              </Option>
                            ))
                          : null}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} md={4} xs={24}>
                  {this.state.file ? (
                    <Typography style={{ color: "black" }}>
                      {this.state.file.name}
                    </Typography>
                  ) : (
                    <label style={{ cursor: "pointer" }} className="fileUpload">
                      <img src={UploadIcon} alt="" />
                      <input
                        onChange={this.handleFileChange}
                        type="file"
                        accept="video/*"
                        style={{ width: "0%" }}
                      />
                    </label>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button type="primary" onClick={this.handleSubmitResponse}>
                Submit
              </Button>
            </div>
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trainer: state.trainer,
  };
};

const QuesConsulationForm = Form.create({ name: "QuestionConsultation" })(
  QuestionConsultation
);

export default connect(mapStateToProps, {
  ChangeTestSearchText,
  ChangeConsultatationTableData,
  ChangeTestDetailsModalState,
})(QuesConsulationForm);
