import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "antd";
import { SecurePost } from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import Alert from "../../common/alert";
import { Redirect } from "react-router-dom";
import { changeStep } from "../../../actions/testAction";
class FinalQuestionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testid: null,
    };
  }

  createtest = () => {
    SecurePost({
      url: apis.CREATE_TEST,
      data: {
        type: this.props.test.newtestFormData.testType,
        title: this.props.test.newtestFormData.testTitle,
        questions: this.props.test.newtestFormData.testQuestions,
        duration: this.props.test.newtestFormData.testDuration,
        subjects: this.props.test.newtestFormData.testSubject,
        organisation: this.props.test.newtestFormData.OrganisationName,
        level: this.props.test.newtestFormData.level,
        exam: this.props.test.newtestFormData.exam,
        school: this.props.test.newtestFormData.school,
        term: this.props.test.newtestFormData.term,
        year: this.props.test.newtestFormData.year,
      },
    })
      .then((response) => {
        if (response.data.success) {
          Alert(
            "success",
            "Test paper Created Successfully!",
            "Please wait, you will automatically be redirected to conduct test page."
          );
          setTimeout(() => {
            this.setState({
              testid: response.data.testid,
            });
            window.location.reload();
          }, 3000);
        } else {
          Alert("error", "Error!", response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  render() {
    if (this.state.testid) {
      return <Redirect to={`/user/conducttest?testid=${this.state.testid}`} />;
    } else {
      return (
        <div>
          {this.props?.test?.newtestFormData?.testQuestions.map((d, i) => {
            return <Q key={i + 1} _id={d} no={i + 1} />;
          })}
          <div style={{ width: "100%", padding: "10px" }}>
            <Button
              style={{ float: "right" }}
              type="primary"
              onClick={this.createtest}
            >
              Create Test
            </Button>
            <Button
              style={{ float: "right", marginRight: "1%" }}
              type="primary"
              onClick={() => this.props.changeStep(1)}
            >
              Previous
            </Button>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  test: state.test,
});
const mapDispatchToProps = {
  changeStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalQuestionView);

function QuestionView(props) {
  var _id = props._id;
  var no = props.no;
  var obj = props.test.questionsAvailablebasedonSubject.filter((hero) => {
    return hero._id === _id;
  });

  var oo = ["A", "B", "C", "D", "E"];

  const image =
    obj &&
    obj[0]?.quesimg &&
    obj[0]?.quesimg?.split("www.leagueonline.sg:5000");
  const path = apis.IMGAE_BASE_URL;
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  const videoExtensions = ["mp4", "avi", "mov", "mkv"];
  // Extract the file extension from the URL
  const extension =
    (obj && obj[0]?.quesimg == undefined) || (obj && obj[0]?.quesimg == null)
      ? ""
      : image && image[0] === "https://"
      ? image[1].split(".").pop().toLowerCase()
      : obj && obj[0].quesimg.split(".").pop().toLowerCase();

  return (
    <div style={{ marginBottom: "20px" }}>
      <div>
        <Row>
          <Col span={20}>
            <b style={{ float: "left" }}>Q. {no}&#41; &nbsp;</b>
            {/* {obj && obj[0].body} */}
            <div dangerouslySetInnerHTML={{ __html: obj && obj[0]?.body }} />
            {obj && obj[0]?.quesimg ? (
              <div className="questionDetailsImageContainer">
                {imageExtensions?.includes(extension) ? (
                  image && image[0] === "https://" ? (
                    <img src={path + image[1]} className="image-tag" alt="" />
                  ) : (
                    <img
                      src={path + "/" + obj[0]?.quesimg}
                      className="image-tag"
                      alt=""
                    />
                  )
                ) : videoExtensions?.includes(extension) ? (
                  image && image[0] === "https://" ? (
                    <video className="image-tag" controls>
                      <source src={path + image[1]} type="video/mp4" />
                    </video>
                  ) : (
                    <video className="image-tag" controls>
                      <source
                        src={path + "/" + obj[0]?.quesimg}
                        type="video/mp4"
                      />
                    </video>
                  )
                ) : null}
              </div>
            ) : null}
            <br />
            {obj && obj[0]?.isMcq ? (
              <div style={{ width: "100%" }}>
                {obj &&
                  obj[0].options.map((d, i) => {
                    return (
                      <Col key={i} span={12} style={{ padding: "10px 20px" }}>
                        <b style={{ marginLeft: 8 }}>{oo[i]} &#41; </b>{" "}
                        {d.optbody}
                        {d.optimg ? (
                          <img
                            crossorigin="anonymous"
                            alt="Option"
                            width="150px"
                            height="150px"
                            className="questionDetailsImage"
                            src={d.optimg}
                            style={{ marginLeft: "12px" }}
                          />
                        ) : null}
                      </Col>
                    );
                  })}
              </div>
            ) : (
              <Col style={{ padding: "10px 20px" }}></Col>
            )}
          </Col>
          <Col span={4}>
            <b style={{ float: "right" }}>
              Mark(s): {obj && obj[0]?.weightage}
            </b>
          </Col>
        </Row>
      </div>
    </div>
  );
}

var Q = connect(mapStateToProps, mapDispatchToProps)(QuestionView);
