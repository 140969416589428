import React from "react";
import { connect } from "react-redux";
import Alert from "../../common/alert";
import apis from "../../../services/Apis";
import BgImage from "../../../assets/testPageBg.svg";
import CorrectImg from "../../../assets/ticksymbol.svg";
import InCorrectImg from "../../../assets/cross.svg";
import RadioImage from "../../../assets/Radio.svg";
import { withRouter } from "react-router-dom";
import EditIcon from "../../../assets/editIcon.png";
import "../examPortal/testBoardHeader.css";

import { Button, Row, Col, Input, Spin, Typography, Modal } from "antd";
import {
  switchQuestion,
  updateIsMarked,
  fetchTestdata,
} from "../../../actions/traineeAction";
import {
  fetchTraineeTestQuestions,
  fetchTraineeTestAnswerSheet,
} from "../../../actions/traineeAction";
import "../examPortal/portal.css";
import TestBoardHeader from "../examPortal/TestBoardHeader";
import "./answer.css";
import AuthServices from "../../../services/AuthServices";
import { SecurePost } from "../../../services/axiosCall";

class SingleQuestionResult extends React.Component {
  constructor(props) {
    super(props);
    if (
      this.props?.trainee?.answers[this.props?.trainee?.activeQuestionIndex]
        ?.chosenOption?.length ===
      this?.props?.trainee?.questions[this.props?.trainee?.activeQuestionIndex]
        ?.anscount
    ) {
      this.state = {
        activeQuestionIndexs: 0,
        isMounted: false,
        data: this.props.data,
        isModalOpen: false,
        isMarkingModalOpen: false,
        questionMarks: 0,
        isQuestion: null,

        loading: true,
        AnswerSelected: true,
        width: window.innerWidth,
        CurrntQuestions:
          this.props.data[this.props?.trainee?.activeQuestionIndex],
        customAnswer:
          this.props?.trainee?.answers[this.props?.trainee?.activeQuestionIndex]
            ?.customAnswer === undefined
            ? ""
            : this.props?.trainee?.answers[
                this.props?.trainee?.activeQuestionIndex
              ]?.customAnswer,
        options:
          this.props?.trainee?.questions[
            this.props?.trainee?.activeQuestionIndex
          ]?.options,
        answers:
          this.props?.trainee?.answers[this.props?.trainee?.activeQuestionIndex]
            ?.chosenOption,
        ticked: 0,
        left: null,
        options1: [
          { id: 1, name: "Option 1" },
          { id: 2, name: "Option 2" },
          { id: 3, name: "Option 3" },
        ],
        right: null,
        options2: [
          { id: 1, name: "Option 1" },
          { id: 2, name: "Option 2" },
          { id: 3, name: "Option 3" },
        ],
      };
    } else {
      this.state = {
        loading: true,
        AnswerSelected: false,
        width: window.innerWidth,
        customAnswer: "",
        options:
          this?.props?.trainee?.questions[
            this.props?.trainee?.activeQuestionIndex
          ]?.options,
        answers:
          this?.props?.trainee?.answers[
            this.props?.trainee?.activeQuestionIndex
          ]?.chosenOption,
        ticked: 0,
      };
    }
  }

  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  handleMarkingOpen = () => {
    this.setState({ isMarkingModalOpen: true });
  };

  handleMarkingCancel = () => {
    this.setState({ isMarkingModalOpen: false });
  };

  handleQuestionTrue = () => {
    this.setState({ isQuestion: true });
  };
  handleQuestionFalse = () => {
    this.setState({ isQuestion: false });
  };

  handleMarkingSubnmit = () => {
    if (
      this.state.questionMarks >
      this.props?.trainee?.questions[this.props?.trainee?.activeQuestionIndex]
        ?.weightage
    ) {
      return Alert("error", "Error", "Are You OK?");
    }
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      SecurePost({
        url: apis.MANUAL_MARKING,
        data: {
          userid: this.props.traineeid,
          testid: this.props.testId,
          isCorrect: this.state.isQuestion,
          qid: this.props.data[this.props.trainee.activeQuestionIndex]._id,
          score: this.state.questionMarks,
        },
        params: {
          Token: t,
        },
      }).then((response) => {
        this.props.setReFetch(!this.props.reFetch);
      });
    }
    this.setState({ isMarkingModalOpen: false });
  };

  previous = () => {
    if (this.props.trainee.activeQuestionIndex > 0) {
      this.props.switchQuestion(this.props.trainee.activeQuestionIndex - 1);
    }
  };

  next = () => {
    this.setState({ loading: false });
    if (this.props.trainee.activeQuestionIndex >= this.props.data.length - 1) {
      this.props.switchQuestion(0);
      ///////////////////////yha pe Trainer ki jgah TRAINER AYE ga jub manual marking ka kam kry ge /////////////////////////////////////////
      this.props.history.push(
        this.props.role !== "TRAINER" ? "/user/testHistory" : "/user/listtests"
      );
    }
    if (this.state.AnswerSelected) {
      if (
        this.props.trainee.activeQuestionIndex <
        this.props.trainee.questions.length - 1
      ) {
        this.props.switchQuestion(this.props.trainee.activeQuestionIndex + 1);
      }
    } else {
      if (
        this.props.trainee.activeQuestionIndex <
        this.props.trainee.questions.length - 1
      ) {
        this.props.switchQuestion(this.props.trainee.activeQuestionIndex + 1);
      }
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
    this.setState({
      questionMarks:
        this.props?.trainee?.questions[this.props?.trainee?.activeQuestionIndex]
          ?.weightage,
    });
    this.props.trainee.socket !== undefined &&
      this.props.trainee.socket.current.disconnect();

    const isTrue =
      this.props.data[this.props.trainee.activeQuestionIndex]
        ?.givenAnswer[0] ===
      this.props.data[this.props.trainee.activeQuestionIndex]?.correctAnswer[0];
    if (isTrue) {
      this.setState({ isQuestion: true });
    } else {
      this.setState({ isQuestion: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    this.setState({
      activeQuestionIndexs: 0,
      isMounted: false,
      data: null,
      isModalOpen: false,
      isMarkingModalOpen: false,
      questionMarks: 0,
      isQuestion: null,
      loading: true,
      AnswerSelected: true,
      width: window.innerWidth,
      CurrntQuestions: null,
      customAnswer: "",
      options: null,
      answers: null,
      ticked: 0,
      left: null,
      options1: null,
      right: null,
      options2: null,
    });
  }

  handleWindowResize = () => {
    this.setState({
      width: window.innerWidth,
      // height: window.innerHeight,
    });
  };

  indexQuestion = (qno) => {
    this.props.switchQuestion(qno);
  };

  render() {
    let CurrntQuestion;
    const { width } = this.state;
    console.log("this.props.trainee_singleQustionResult", this.props.trainee);
    const { selectedOption, options } = this.state;
    let opts = ["A", "B", "C", "D", "E"];
    this.props.data.activeQuestionIndex = 0;
    const image =
      this.props.trainee.questions[this.props.trainee.activeQuestionIndex]
        ?.quesimg &&
      this.props.trainee.questions[
        this.props.trainee.activeQuestionIndex
      ]?.quesimg?.split("www.leagueonline.sg:5000");
    const path = apis.IMGAE_BASE_URL;
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const videoExtensions = ["mp4", "avi", "mov", "mkv"];
    const data = this.props.data.filter((item) => item.iscorrect === false);

    CurrntQuestion = this.props.data[this.props.trainee.activeQuestionIndex];

    const extension =
      this.props.trainee.questions[this.props.trainee.activeQuestionIndex]
        ?.quesimg == undefined ||
      this.props.trainee.questions[this.props.trainee.activeQuestionIndex]
        ?.quesimg == null
        ? ""
        : image && image[0] === "https://"
        ? image[1].split(".").pop().toLowerCase()
        : this.props.trainee.questions[
            this.props.trainee.activeQuestionIndex
          ].quesimg
            .split(".")
            .pop()
            .toLowerCase();

    return (
      <>
        {this.state.loading === false ? (
          <Spin />
        ) : (
          <div>
            <TestBoardHeader
              testName={this.props.testName}
              role={this.props.role}
              next={this.next}
              previous={this.previous}
              isCorrect={this.props?.isCorrect}
              isFinish={
                this.props.trainee.activeQuestionIndex ===
                this.props.data.length - 1
              }
            />

            <div
              style={{ height: "100%" }}
              className="Question-single-container-new"
            >
              <Row className="resultParrentWrapper" style={{ display: "flex" }}>
                <Col
                  className="leftColQuestionResult second"
                  span={width <= 1020 ? 24 : 17}
                >
                  <Row>
                    <Col className="mobileTool" span={24}>
                      {this.props.mode === "mobile" ? (
                        <Button
                          onClick={this.props.triggerSidebar}
                          style={{ background: "#009999", color: "#fff" }}
                        >
                          Tool
                        </Button>
                      ) : null}
                    </Col>
                  </Row>

                  <br />

                  <Row>
                    <Col span={24} style={{ fontSize: "1.2rem" }}>
                      <Typography
                        style={{ color: "#FD350A" }}
                        className="marksText"
                      >
                        <span className="questionNoText">
                          Question {this.props.trainee.activeQuestionIndex + 1}
                          &nbsp;
                        </span>
                        (
                        {this.props?.trainee?.questions[
                          this.props?.trainee?.activeQuestionIndex
                        ]?.weightage + " mark(s)"}
                        )
                      </Typography>
                      <div>
                        <div
                          className="questionBody"
                          dangerouslySetInnerHTML={{
                            __html:
                              this.props?.data[
                                this?.props?.trainee?.activeQuestionIndex
                              ]?.body,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="Question-single-body-holder">
                    {CurrntQuestion?.quesimg ? (
                      <div className="Single-question-body-image-container-new">
                        {imageExtensions?.includes(extension) ? (
                          image && image[0] === "https://" ? (
                            <img src={path + image[1]} className="image-tag" />
                          ) : (
                            <img
                              src={path + "/" + CurrntQuestion?.quesimg}
                              className="image-tag"
                            />
                          )
                        ) : videoExtensions?.includes(extension) ? (
                          image && image[0] === "https://" ? (
                            <video className="image-tag" controls>
                              <source src={path + image[1]} type="video/mp4" />
                            </video>
                          ) : (
                            <video className="image-tag" controls>
                              <source
                                src={path + "/" + CurrntQuestion?.quesimg}
                                type="video/mp4"
                              />
                            </video>
                          )
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <div className="Question-single-option-panel">
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "22px",
                      }}
                    >
                      {CurrntQuestion?.options ? (
                        CurrntQuestion?.options?.length > 0 ? (
                          CurrntQuestion?.options?.map((d, i) => {
                            const CurrntQuestion =
                              this.props?.data[
                                this.props?.trainee?.activeQuestionIndex
                              ];
                            return (
                              <Col
                                span={24}
                                key={i}
                                className="Single-option-new"
                              >
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                  }}
                                >
                                  <Col style={{ textAlign: "center" }}>
                                    <Typography className="questionBody">
                                      ({opts[i]})
                                    </Typography>
                                  </Col>
                                  <Col
                                    span={22}
                                    style={{
                                      // padding: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <h3 className="questionBody">
                                      {d.optbody}
                                    </h3>
                                    <div
                                      className={
                                        d.optimg
                                          ? "option-image-in-exam-panel-holder"
                                          : "option-image-in-exam-panel-holder-w0"
                                      }
                                    >
                                      {d.optimg ? (
                                        <img
                                          crossorigin="anonymous"
                                          alt="Unable to load"
                                          className="option-image-in-exam-panel"
                                          width="150px"
                                          height="150px"
                                          style={{ marginLeft: "12px" }}
                                          src={d.optimg}
                                        />
                                      ) : null}
                                    </div>

                                    {opts[i] ===
                                    CurrntQuestion?.correctAnswer[0] ? (
                                      <Typography className="correct-answere">
                                        {CurrntQuestion?.givenAnswer[0] ===
                                        CurrntQuestion?.correctAnswer[0]
                                          ? "(your answer)"
                                          : "(correct answer)"}
                                      </Typography>
                                    ) : opts[i] ===
                                      CurrntQuestion?.givenAnswer[0] ? (
                                      CurrntQuestion?.givenAnswer[0] ===
                                      CurrntQuestion?.correctAnswer[0] ? (
                                        ""
                                      ) : (
                                        <Typography className="your-answere">
                                          (your answer)
                                        </Typography>
                                      )
                                    ) : (
                                      <img src={RadioImage} alt="" />
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            );
                          })
                        ) : (
                          <Col span={24}>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                bottom: 0,
                                gap: 4,
                              }}
                            >
                              {this.props?.trainee?.questions[
                                this.props?.trainee?.activeQuestionIndex
                              ]?.position === "left" ? (
                                <Col>
                                  <Typography
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "22px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {
                                      this.props?.trainee?.questions[
                                        this.props?.trainee?.activeQuestionIndex
                                      ]?.unit
                                    }
                                  </Typography>
                                </Col>
                              ) : (
                                ""
                              )}

                              {CurrntQuestion?.iscorrect ? (
                                <>
                                  <Col>
                                    {CurrntQuestion?.position === "left" ? (
                                      <Col>
                                        <Typography
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "22px",
                                            textAlign: "end",
                                          }}
                                        >
                                          {CurrntQuestion?.unit}
                                        </Typography>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                  </Col>

                                  {CurrntQuestion.isLong ? (
                                    <Col span={18}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "6px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "6px",
                                          }}
                                        >
                                          <Typography className="questionBody">
                                            {CurrntQuestion?.correctAnswer[0]}
                                          </Typography>

                                          <Typography className="correct-answere">
                                            (model answer)
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "6px",
                                          }}
                                        >
                                          <Typography className="questionBody">
                                            {CurrntQuestion?.givenAnswer[0]}
                                          </Typography>

                                          <Typography className="correct-answere">
                                            (your answer)
                                          </Typography>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    <Col span={18}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "6px",
                                        }}
                                      >
                                        <Typography className="questionBody">
                                          {CurrntQuestion?.correctAnswer[0]}
                                        </Typography>
                                        <Typography className="correct-answere">
                                          {CurrntQuestion?.givenAnswer[0] ===
                                          CurrntQuestion?.correctAnswer[0]
                                            ? "(your answer)"
                                            : "(correct answer)"}
                                        </Typography>
                                      </div>
                                    </Col>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Col span={18}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4PX",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "6px",
                                        }}
                                      >
                                        {CurrntQuestion?.position === "left" ? (
                                          <Col>
                                            <Typography
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "22px",
                                                textAlign: "end",
                                              }}
                                            >
                                              {CurrntQuestion?.unit}
                                            </Typography>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                        {CurrntQuestion.isLong ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "8px",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "8px",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Typography className="questionBody">
                                                {
                                                  CurrntQuestion
                                                    ?.correctAnswer[0]
                                                }{" "}
                                              </Typography>
                                              <Typography className="correct-answere">
                                                (model answer)
                                              </Typography>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "8px",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Typography className="questionBody">
                                                {CurrntQuestion?.givenAnswer[0]}
                                              </Typography>
                                              <Typography className="correct-answere">
                                                (your answer)
                                              </Typography>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            {" "}
                                            <Typography className="questionBody">
                                              {CurrntQuestion?.correctAnswer[0]}
                                            </Typography>
                                            <Typography className="correct-answere">
                                              {CurrntQuestion
                                                ?.givenAnswer[0] ===
                                              CurrntQuestion?.correctAnswer[0]
                                                ? "(your answer)"
                                                : "(correct answer)"}
                                            </Typography>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "6px",
                                              }}
                                            >
                                              <Typography className="questionBody">
                                                {CurrntQuestion
                                                  ?.givenAnswer[0] ===
                                                CurrntQuestion?.correctAnswer[0]
                                                  ? ""
                                                  : CurrntQuestion
                                                      ?.givenAnswer[0]}
                                                {/* {CurrntQuestion?.givenAnswer[0] !==
                                          "null"
                                            ? CurrntQuestion?.givenAnswer[0]
                                            : "null"} */}
                                              </Typography>
                                              <Typography className="your-answere">
                                                {CurrntQuestion
                                                  ?.givenAnswer[0] ===
                                                CurrntQuestion?.correctAnswer[0]
                                                  ? ""
                                                  : "(your answer)"}
                                              </Typography>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              )}

                              {CurrntQuestion?.position === "right" ? (
                                <Col>
                                  <Typography
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {CurrntQuestion?.unit}
                                  </Typography>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Col>
                        )
                      ) : null}
                    </Row>
                  </div>
                </Col>
                <Col className="first" span={width <= 1020 ? 24 : 7}>
                  <div className="SiderQuestionResult">
                    <div
                      style={{
                        height: "100%",
                        maxHeight: "auto",
                        overflowY: "auto",
                        overflowX: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "center", paddingBottom: "10px" }}
                        className="questionAnsTitle"
                      >
                        {this.props?.data.find(
                          (item) =>
                            !item.isMarked && item.hasOwnProperty("isMarked")
                        )
                          ? "Final Score: Pending"
                          : ""}
                      </Typography>
                      <Row className="marksContainer">
                        <Col className="marksTitleO" span={12}>
                          <Typography>Your Score:</Typography>
                        </Col>
                        <Col className="marksTitleT" span={12}>
                          <Typography>Total:</Typography>
                        </Col>
                        <Col className="marksO" span={12}>
                          <Typography>{this.props?.totalScore}</Typography>
                        </Col>
                        <Col className="marksT" span={12}>
                          <Typography>{this.props?.totalMarks}</Typography>
                        </Col>
                      </Row>
                      <Row>
                        <Row gutter={16} style={{ padding: "5px" }}>
                          <Col className="questionAnsTitle" span={12}>
                            <Typography>Question No.</Typography>
                          </Col>
                          <Col className="questionAnsTitle" span={12}>
                            <Typography>Status</Typography>
                          </Col>
                        </Row>
                        {this.props?.data &&
                          this.props?.data?.map((item, index) => (
                            <>
                              {this.props.isCorrect ? (
                                <>
                                  {" "}
                                  <Row
                                    style={{
                                      padding: "0px 5px",
                                      borderBottom: "2px solid #B4B4B4",
                                    }}
                                  >
                                    <Col
                                      className="questionAns"
                                      span={12}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.indexQuestion(index)}
                                    >
                                      <Typography
                                        style={{
                                          fontWeight:
                                            this.props.trainee
                                              .activeQuestionIndex === index
                                              ? 900
                                              : 600,
                                        }}
                                      >
                                        {index + 1}
                                      </Typography>
                                    </Col>
                                    <Col className="questionAns" span={12}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "4px",
                                        }}
                                      >
                                        <Typography>
                                          {item.iscorrect
                                            ? "Correct"
                                            : !item.isMarked && item.isLong
                                            ? "pending"
                                            : "Incorrect"}
                                        </Typography>
                                        <img
                                          width={"10%"}
                                          src={
                                            item.iscorrect
                                              ? CorrectImg
                                              : !item.isMarked && item.isLong
                                              ? ""
                                              : InCorrectImg
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <>
                                  {!item?.iscorrect && (
                                    <Row
                                      style={{
                                        padding: "0px 5px",
                                        borderBottom: "2px solid #B4B4B4",
                                      }}
                                    >
                                      <Col
                                        className="questionAns"
                                        span={12}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.indexQuestion(index)
                                        }
                                      >
                                        <Typography
                                          style={{
                                            fontWeight:
                                              this.props?.trainee
                                                ?.activeQuestionIndex === index
                                                ? 900
                                                : 600,
                                          }}
                                        >
                                          {index + 1}
                                        </Typography>
                                      </Col>
                                      <Col className="questionAns" span={12}>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                          }}
                                        >
                                          <Typography>
                                            {item.iscorrect
                                              ? "Correct"
                                              : !item.isMarked && item.isLong
                                              ? "pending"
                                              : "Incorrect"}
                                          </Typography>
                                          <img
                                            width={"10%"}
                                            src={
                                              item.iscorrect
                                                ? CorrectImg
                                                : !item.isMarked && item.isLong
                                                ? ""
                                                : InCorrectImg
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                                </>
                              )}
                            </>
                          ))}
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="ImagebgTest">
                <img src={BgImage} alt="" />
              </div>

              {this.props.role !== "TRAINER" ? (
                CurrntQuestion?.explanation && (
                  <div className="explanationText">
                    <Typography
                      style={{
                        textDecoration: "underline",
                        fontWeight: 700,
                        color: "#0049D8",
                        fontSize: "22px",
                      }}
                      onClick={() => this.handleOpen()}
                    >
                      View Explanation
                    </Typography>
                  </div>
                )
              ) : (
                <>
                  {!CurrntQuestion.isMarked && (
                    <div className="editButton">
                      <div
                        style={{
                          backgroundColor: this.state.isMarkingModalOpen
                            ? "#C5C5C5"
                            : "#0082FB",
                        }}
                        className="editBox"
                        onClick={() => this.handleMarkingOpen()}
                      >
                        <img
                          width={"35px"}
                          height={"35px"}
                          src={EditIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <br />
            <Modal
              visible={this.state.isModalOpen}
              footer={null}
              closable={false}
              centered
              onCancel={this.handleCancel}
              width={350}
              className="explanationModal"
              style={{
                borderRadius: "25px",
                backgroundColor: "background: #D9D9D9",
                color: "black",
              }}
            >
              <div
                className="explanationModal"
                dangerouslySetInnerHTML={{
                  __html: CurrntQuestion?.explanation
                    ? CurrntQuestion?.explanation
                    : "",
                }}
              ></div>
            </Modal>
            <Modal
              visible={this.state.isMarkingModalOpen}
              footer={null}
              closable={false}
              // centered
              onCancel={this.handleMarkingCancel}
              width={350}
              className="explanationModal"
              style={{
                top: "50%",
                borderRadius: "25px",
              }}
            >
              <div className="MarkingModalBox">
                <Typography className="markingTitle">Edit Marking</Typography>
                <Typography
                  style={{
                    width: "100%",
                  }}
                  className="markingPara"
                >
                  current question marks{" "}
                  {
                    this.props?.trainee?.questions[
                      this.props?.trainee?.activeQuestionIndex
                    ]?.weightage
                  }
                </Typography>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={{ width: "80%" }} className="markingPara">
                    enter new question marks
                  </Typography>
                  <Input
                    min={0}
                    max={
                      this.props?.trainee?.questions[
                        this.props?.trainee?.activeQuestionIndex
                      ]?.weightage
                    }
                    type="number"
                    value={this.state.questionMarks}
                    name="answer"
                    className="ansInput"
                    style={{
                      width: "20%",

                      backgroundColor: "white",
                    }}
                    onChange={(e) =>
                      this.setState({ questionMarks: e.target.value })
                    }
                    required
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className="markingPara">Grading</Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <Typography
                      style={{
                        color: this.state.isQuestion ? "#00C51F" : "#8F8F8F",
                      }}
                      onClick={() => this.handleQuestionTrue()}
                      className="textToggele"
                    >
                      {" "}
                      CORRECT
                    </Typography>
                    <Typography
                      style={{ color: "#000" }}
                      className="textToggele"
                    >
                      {" "}
                      /{" "}
                    </Typography>
                    <Typography
                      style={{
                        color: !this.state.isQuestion ? "#FF0D0D" : "#8F8F8F",
                        cursor: "pointer",
                      }}
                      className="textToggele"
                      onClick={() => this.handleQuestionFalse()}
                    >
                      {" "}
                      WRONG
                    </Typography>
                  </div>
                </div>
                <Button
                  style={{ borderRadius: "30PX", marginTop: "40px" }}
                  className="testHeaderButton"
                  type="primary"
                  onClick={() => this.handleMarkingSubnmit()}
                >
                  Submit
                </Button>
              </div>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default withRouter(
  connect(mapStateToProps, {
    switchQuestion,
    updateIsMarked,
    fetchTestdata,
    fetchTraineeTestQuestions,
    fetchTraineeTestAnswerSheet,
  })(SingleQuestionResult)
);
