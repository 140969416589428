import React from "react";
import "../profile/profile.css";
import { Button, Typography } from "antd";
import AuthServices from "../../../services/AuthServices";
import DiamondIcon from "../../../assets/diamond.svg";
import { connect } from "react-redux";

const DashboardWrapper = (props) => {
  const { children, title, isReward, user } = props;

  const handleLogOut = () => {
    AuthServices.deleteToken();
    window.location.href = "/";
  };

  return (
    <div className="profile_wrapper">
      <div className="profile_containers">
        <div className="container_inner">
          <div className="space-between">
            <Typography className="profile_text">{title}</Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {!isReward && (
                <div className="rewardBox">
                  <img width="20px" src={DiamondIcon} alt="" />
                  <Typography style={{ color: "white" }}>
                    {user?.userDetails?.points?.toLocaleString()}
                  </Typography>
                </div>
              )}
              <Button type="primary" onClick={handleLogOut}>
                Log out
              </Button>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(DashboardWrapper);
