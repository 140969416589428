const Badges = "iconmonstr-award-24.svg";
const StatisticsIcon = "iconmonstr-chart-4.svg";
const HistoryIcon = "iconmonstr-file-10.svg";
const TakeTestIcon = "iconmonstr-pencil-9.svg";
const QuesConsultationIcon = "iconmonstr-help-5.svg";
const BadgesIcon = "iconmonstr-award-24.svg";
const CliamRewardsIcon = "iconmonstr-shop-13.svg";
const EditProfileIcon = "iconmonstr-gear-2.svg";
const ProfileIcon = "iconmonstr-user-6.svg";
const Rewards = "iconmonstr-reward.svg";

// import {
//   ProfileIcon,
//   StatisticsIcon,
//   HistoryIcon,
//   TakeTestIcon,
//   QuesConsultationIcon,
//   BadgesIcon,
//   CliamRewardsIcon,
//   EditProfileIcon,
// } from "./icons";

export const ADMIN_PERMISSIONS = [
  {
    display: "Welcome",
    icon: "home",
    link: "/user/home",
  },
  {
    display: "All Teachers",
    icon: "user",
    link: "/user/listtrainers",
  },
  {
    display: "All Students",
    icon: "user",
    link: "/user/liststudents",
  },
  {
    display: "All Courses",
    icon: "book",
    link: "/user/listsubjects",
  },
  {
    display: "Rewards",
    icon: "book",
    link: "/user/rewards",
  },
  {
    display: "Active Orders",
    icon: "book",
    link: "/user/activeorders",
  },
  {
    display: "Past Orders",
    icon: "book",
    link: "/user/pastorders",
  },
];

export const TRAINER_PERMISSIONS = [
  {
    display: "Welcome",
    icon: "home",
    link: "/user/home",
  },
  {
    display: "All Questions",
    icon: "form",
    link: "/user/listquestions",
  },
  {
    display: "All Tests",
    icon: "copy",
    link: "/user/listtests",
  },
  {
    display: "New Test",
    icon: "edit",
    link: "/user/newtest",
  },
  {
    display: "Conduct Test",
    icon: "edit",
    link: "/user/conducttest",
  },
  {
    display: "All Students",
    icon: "user",
    link: "/user/students",
  },
  {
    display: "Question Consultation",
    icon: "user",
    link: "/user/questionconsultation",
  },
];
export const STUDENT_PERMISSIONS = [
  {
    display: "My Profile",
    icon: ProfileIcon,
    link: "/user/profile",
  },
  {
    display: "My Statistics",
    icon: StatisticsIcon,
    link: "/user/statistics",
  },
  {
    display: "Test History",
    icon: HistoryIcon,
    link: "/user/testHistory",
  },
  {
    display: "Take Test",
    icon: TakeTestIcon,
    link: "/user/livetest",
  },
  {
    display: "Question Consultation",
    icon: QuesConsultationIcon,
    link: "/user/questionConsultation",
  },
  {
    display: "My Badges",
    icon: Badges,
    link: "/user/badges",
  },
  {
    display: "Claim Rewards",
    icon: Rewards,
    link: "/user/claimRewards",
  },
  {
    display: "Edit Profile",
    icon: EditProfileIcon,
    link: "/user/profileSettings",
  },
  // {
  //   display: "History",
  //   icon: "book",
  //   link: "/user/testHistory",
  // },
  // {
  //   display: "Test History",
  //   icon: "home",
  //   link: "/user/home",
  // },
];
