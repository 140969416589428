import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Icon,
  Typography,
  Popconfirm,
  Divider,
  Modal,
  Select,
  Row,
  Col,
  Form,
} from "antd";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import {
  ChangeQuestionModalState,
  ChangeQuestionTableData,
  ChangeQuestionSearchText,
  ChangeSelectedSubjects,
} from "../../../actions/trainerAction";
import { ChangeSubjectTableData } from "../../../actions/adminAction";
import "./allquestion.css";
import Alert from "../../../components/common/alert";
import { SecurePost } from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import NewQuestionForm from "../newquestion/newquestion";
import QuestionDetails from "../questionDetails/questiondetails";
import UpdateQuestion from "../updatequestion/updatequestion";
import {
  givenDifficulties,
  givenLevels,
  givenSchools,
  givenComponents,
  givenExams,
  givenYears,
  givenMathsTopics,
  givenEnglishTopics,
  givenScienceTopics,
} from "../../common/constants";

class AllQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questiondetailsId: null,
      questiondetailsModelVisible: false,
      questionUpdateModelVisible: false,
      pageSize: 5,
      page: 1,
      question: "",
      level: [],
      difficulty: [],
      school: [],
      year: [],
      component: [],
      exam: [],
    };
  }
  OpendetailsModal = (id) => {
    this.setState((previousState, previousProps) => {
      return {
        questiondetailsId: id,
        questiondetailsModelVisible: true,
      };
    });
  };
  ClosedetailsModal = () => {
    this.setState((previousState, previousProps) => {
      return {
        questiondetailsId: null,
        questiondetailsModelVisible: false,
      };
    });
  };

  OpenUpdateModal = (id) => {
    this.setState((previousState, previousProps) => {
      return {
        questiondetailsId: id,
        questionUpdateModelVisible: true,
      };
    });
  };
  CloseUpdateModal = () => {
    this.setState((previousState, previousProps) => {
      return {
        questiondetailsId: null,
        questionUpdateModelVisible: false,
      };
    });
  };

  componentDidMount() {
    this.props.ChangeSubjectTableData();
    this.props.ChangeQuestionTableData(this.props.trainer.selectedSubjects);
  }
  componentDidUpdate(prevProps, prevState) {
    // Check if props or state that affect the ChangeQuestionTableData function have changed
    if (
      this.props.trainer.selectedSubjects !==
        prevProps.trainer.selectedSubjects ||
      this.state.pageSize !== prevState.pageSize ||
      this.state.page !== prevState.page ||
      this.state !== prevState
    ) {
      // Call the ChangeQuestionTableData function with updated props and state
      this.props.ChangeQuestionTableData(
        this.props.trainer.selectedSubjects,
        this.state.pageSize,
        this.state.page,
        this.state
      );
    }
  }

  openNewModal = (mode) => {
    this.props.ChangeQuestionModalState(true);
  };

  closeNewModal = () => {
    this.props.ChangeQuestionModalState(false);
  };

  handleSubjectChange = (s) => {
    this.props.ChangeSelectedSubjects(s);
    this.props.ChangeQuestionTableData(s);
    this.setState({ page: 1, pageSize: 5 });
  };

  deleteQuestion = (id) => {
    SecurePost({
      url: `${apis.DELETE_QUESTION}`,
      data: { _id: id },
    })
      .then((response) => {
        if (response.data.success) {
          Alert("success", "Success", response.data.message);
          this.props.ChangeQuestionTableData(
            this.props.trainer.selectedSubjects
          );
        } else return Alert("warning", "Warning!", response.data.message);
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  handlePageSizeChange = (current, size) => {
    this.setState({ pageSize: size });
  };

  handlePageChange = (current, size) => {
    this.setState({ page: current });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.props.trainer.QuestionsearchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    console.log("selectedKeys", selectedKeys);
    this.setState({
      question: selectedKeys[0],
    });
    console.log("confirm();", confirm());

    confirm();
    this.props.ChangeQuestionSearchText(selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.props.ChangeQuestionSearchText("");
  };
  handleFilterChange = (name, val) => {
    this.setState({
      [name]: val,
    });
  };

  render() {
    const { Title } = Typography;
    console.log(this.state);

    const columns = [
      {
        title: "Subject",
        dataIndex: "subject.topic",
        key: "subject.topic",
        width: "100px",
        render: (text) => text || "",
      },
      {
        title: "Question",
        dataIndex: "body",
        key: "body",
        width: "150px",
        // ...this.getColumnSearchProps("body"),
        // render: (text) =>
        //   (
        //     <div
        //       dangerouslySetInnerHTML={{ __html: text }}
        //       id="listHTML"
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     ></div>
        //   ) || "",
      },
      {
        title: "Explanation",
        dataIndex: "explanation",
        key: "explanation",
        width: "200px",
        render: (text) =>
          (
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              id="listHTML"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          ) || "",
      },
      {
        title: "Level",
        dataIndex: "level",
        key: "level",
        width: "100px",
        sorter: (a, b) => {
          const levels = [
            "primary 1",
            "primary 2",
            "primary 3",
            "primary 4",
            "primary 5",
            "primary 6",
            "secondary 1",
            "secondary 2",
            "secondary 3",
            "secondary 4",
            "secondary 5",
          ];

          const indexA = levels.indexOf(a.level);
          const indexB = levels.indexOf(b.level);
          return indexA - indexB;
        },
        // render: (l) => {
        //   const columnData = givenLevels.find((lv) => lv.value === l);
        //   const lvl = columnData ? columnData.label : "";
        //   return <span>{lvl}</span>;
        // },
        // filters: givenLevels.map((lv) => {
        //   return {
        //     text: lv.label,
        //     value: lv.value,
        //   };
        // }),

        // onFilter: (value, record) => {
        //   this.handleFilterChange(value);
        //   // return false;
        // },
        // filterSearch: true,
      },
      {
        title: "Difficulty",
        dataIndex: "difficulty",
        key: "difficulty",
        width: "130px",
        sorter: (a, b) => {
          const difficulties = ["basic", "moderate", "challenging"];
          const indexA = difficulties.indexOf(a.difficulty);
          const indexB = difficulties.indexOf(b.difficulty);
          return indexA - indexB;
        },
        // render: (l) => {
        //   const columnData = givenDifficulties.find((df) => df.value === l);
        //   const d = columnData ? columnData.label : "";
        //   return <span>{d}</span>;
        // },
        // filters: givenDifficulties.map((df) => {
        //   return {
        //     text: df.label,
        //     value: df.value,
        //   };
        // }),
        // onFilter: (value, record) => {
        //   if (record.difficulty) {
        //     return record.difficulty
        //       .toLowerCase()
        //       .startsWith(value.toLowerCase());
        //   }
        //   return false;
        // },
        // filterSearch: true,
      },
      {
        title: "Topic",
        dataIndex: "topic",
        key: "topic",
        width: "130px",
        sorter: (a, b) => {
          const tpoics = [
            ...givenMathsTopics,
            ...givenEnglishTopics,
            ...givenScienceTopics,
          ];
          const indexA = tpoics.findIndex((topic) => topic.value === a.topic);
          const indexB = tpoics.findIndex((topic) => topic.value === b.topic);
          return indexA - indexB;
        },
        // render: (l) => {
        //   const columnData = [
        //     ...givenMathsTopics,
        //     ...givenEnglishTopics,
        //     ...givenScienceTopics,
        //   ].find((df) => df.value === l);
        //   const d = columnData ? columnData.label : "";
        //   return <span>{d}</span>;
        // },
        // filters: [
        //   ...givenMathsTopics,
        //   ...givenEnglishTopics,
        //   ...givenScienceTopics,
        // ].map((df) => {
        //   return {
        //     text: df.label,
        //     value: df.value,
        //   };
        // }),
        // onFilter: (value, record) => {
        //   if (record.topic) {
        //     return record.topic.toLowerCase().startsWith(value.toLowerCase());
        //   }
        //   return false;
        // },
        // filterSearch: true,
      },
      {
        title: "School",
        dataIndex: "school",
        key: "school",
        width: "120px",
        sorter: (a, b) => {
          if (a.school > b.school) return 1;
          if (a.school < b.school) return -1;
          return 0;
        },
        // render: (l) => {
        //   const columnData = givenSchools.find((df) => df.value === l);
        //   const d = columnData ? columnData.label : "";
        //   return <span>{d}</span>;
        // },
        // filters: givenSchools.map((df) => {
        //   return {
        //     text: df.label,
        //     value: df.value,
        //   };
        // }),
        // onFilter: (value, record) => {
        //   if (record.school) {
        //     return record.school.toLowerCase().startsWith(value.toLowerCase());
        //   }
        //   return false;
        // },
        // filterSearch: true,
      },
      {
        title: "Exam",
        dataIndex: "exam",
        key: "exam",
        width: "120px",
        sorter: (a, b) => {
          if (a.exam > b.exam) return 1;
          if (a.exam < b.exam) return -1;
          return 0;
        },
        // render: (l) => {
        //   const columnData = givenExams.find((df) => df.value === l);
        //   const d = columnData ? columnData.label : "";
        //   return <span>{d}</span>;
        // },
        // filters: givenExams.map((df) => {
        //   return {
        //     text: df.label,
        //     value: df.value,
        //   };
        // }),
        // onFilter: (value, record) => {
        //   if (record.exam) {
        //     return record.exam.toLowerCase().startsWith(value.toLowerCase());
        //   }
        //   return false;
        // },
        // filterSearch: true,
      },
      {
        title: "Component",
        dataIndex: "component",
        key: "component",
        width: "150px",
        sorter: (a, b) => {
          if (a.component > b.component) return 1;
          if (a.component < b.component) return -1;
          return 0;
        },

        // render: (l) => {
        //   const columnData = givenComponents.find((df) => df.value === l);
        //   const d = columnData ? columnData.label : "";
        //   return <span>{d}</span>;
        // },
        // filters: givenComponents.map((df) => {
        //   return {
        //     text: df.label,
        //     value: df.value,
        //   };
        // }),
        // onFilter: (value, record) => {
        //   if (record.component) {
        //     return record.component.toLowerCase() === value.toLowerCase();
        //   }
        //   return false;
        // },
        // filterSearch: true,
      },
      {
        title: "Year",
        dataIndex: "year",
        key: "year",
        width: "100px",
        sorter: (a, b) => {
          if (a.year > b.year) return 1;
          if (a.year < b.year) return -1;
          return 0;
        },
        // render: (l) => {
        //   const columnData = givenYears.find((df) => df.value === l);
        //   const d = columnData ? columnData.label : "";
        //   return <span>{d}</span>;
        // },
        // filters: givenYears.map((df) => {
        //   return {
        //     text: df.label,
        //     value: df.value,
        //   };
        // }),
        // onFilter: (value, record) => {
        //   if (record.year) {
        //     return record.year.toLowerCase().startsWith(value.toLowerCase());
        //   }
        //   return false;
        // },
        // filterSearch: true,
      },
      {
        title: "Image",
        dataIndex: "quesimg",
        key: "quesimg",
        width: "150px",
        render: (quesimg) => {
          const image = quesimg && quesimg.split("www.leagueonline.sg:5000");
          const path = apis.IMGAE_BASE_URL;
          const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
          const videoExtensions = ["mp4", "avi", "mov", "mkv"];
          // Extract the file extension from the URL
          const extension =
            quesimg == undefined || quesimg == null
              ? ""
              : image && image[0] === "https://"
              ? image[1].split(".").pop().toLowerCase()
              : quesimg.split(".").pop().toLowerCase();
          return (
            <div>
              {quesimg == undefined ||
              quesimg == null ? null : imageExtensions.includes(extension) ? (
                image && image[0] === "https://" ? (
                  <a href={path + image[1]} target="_blank">
                    <img src={path + image[1]} width={"100px"} />
                  </a>
                ) : (
                  <a href={path + "/" + quesimg} target="_blank">
                    <img src={path + "/" + quesimg} width={"100px"} />{" "}
                  </a>
                )
              ) : videoExtensions.includes(extension) ? (
                image && image[0] === "https://" ? (
                  <a href={path + image[1]} target="_blank">
                    <video width="100" controls>
                      <source src={path + image[1]} type="video/mp4" />
                    </video>
                  </a>
                ) : (
                  <a href={path + "/" + quesimg} target="_blank">
                    <video width="100" controls>
                      <source src={path + "/" + quesimg} type="video/mp4" />
                    </video>
                  </a>
                )
              ) : null}
            </div>
          );
        },
      },
      {
        title: "Action",
        key: "_id",
        dataIndex: "_id",
        width: "80px",
        render: (key) => (
          <span
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => this.OpendetailsModal(key)}
              icon="info-circle"
            />
            <Divider type="vertical" />
            <Button
              type="primary"
              shape="circle"
              onClick={() => this.OpenUpdateModal(key)}
              icon="edit"
            />
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure？"
              cancelText="No"
              okText="Yes"
              onConfirm={() => {
                this.deleteQuestion(key);
              }}
              icon={<Icon type="delete" style={{ color: "red" }} />}
            >
              <Button type="danger" shape="circle" icon="delete" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    const { pageSize } = this.state;
    return (
      <div className="admin-table-container">
        <div>
          <Row
            offset={1}
            span={9}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <Form.Item label="Level" hasFeedback>
              <Select
                showSearch
                mode="multiple"
                style={{ width: 150 }}
                placeholder="Select Level"
                optionFilterProp="s"
                allowClear={true}
                onChange={(value) => {
                  this.handleFilterChange("level", value);
                }}
              >
                {givenLevels.map((d, i) => (
                  <Select.Option key={d.value} s={d.label} value={d.value}>
                    {d.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Difficulty" hasFeedback>
              <Select
                showSearch
                mode="multiple"
                style={{ width: 150 }}
                placeholder="Select Difficulty"
                optionFilterProp="s"
                onChange={(value) => {
                  this.handleFilterChange("difficulty", value);
                }}
              >
                {givenDifficulties.map((d, i) => (
                  <Select.Option key={d.value} s={d.label} value={d.value}>
                    {d.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="School" hasFeedback>
              <Select
                showSearch
                mode="multiple"
                style={{ width: 150 }}
                placeholder="Select School"
                optionFilterProp="s"
                onChange={(value) => {
                  this.handleFilterChange("school", value);
                }}
              >
                {givenSchools.map((d, i) => (
                  <Select.Option key={d.value} s={d.label} value={d.value}>
                    {d.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Year" hasFeedback>
              <Select
                showSearch
                mode="multiple"
                style={{ width: 150 }}
                placeholder="Select Year"
                optionFilterProp="s"
                onChange={(value) => {
                  this.handleFilterChange("year", value);
                }}
              >
                {givenYears.map((d, i) => (
                  <Select.Option key={d.value} s={d.label} value={d.value}>
                    {d.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Component" hasFeedback>
              <Select
                showSearch
                mode="multiple"
                style={{ width: 150 }}
                placeholder="Select Component"
                optionFilterProp="s"
                onChange={(value) => {
                  this.handleFilterChange("component", value);
                }}
              >
                {givenComponents.map((d, i) => (
                  <Select.Option key={d.value} s={d.label} value={d.value}>
                    {d.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Exam" hasFeedback>
              <Select
                showSearch
                mode="multiple"
                style={{ width: 150 }}
                placeholder="Select Exam"
                optionFilterProp="s"
                onChange={(value) => {
                  this.handleFilterChange("exam", value);
                }}
              >
                {givenExams.map((d, i) => (
                  <Select.Option key={d.value} s={d.label} value={d.value}>
                    {d.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Button
                type="primary"
                icon="question-circle"
                style={{ marginBottom: "10px" }}
                onClick={() => this.openNewModal("Add New Question")}
              >
                Add New Question
              </Button>
            </Col>
            <Col span={12}>
              <Select
                mode="multiple"
                placeholder="Select one or more subjects"
                defaultValue={this.props.trainer.selectedSubjects}
                onChange={this.handleSubjectChange}
                style={{ width: "100%" }}
                allowClear={true}
                optionFilterProp="s"
              >
                {this.props.admin.subjectTableData.map((item) => (
                  <Select.Option key={item._id} value={item._id} s={item.topic}>
                    {item.topic}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
        <div className="register-trainer-form-header">
          <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
            List of Questions
          </Title>
        </div>
        <Table
          bordered={true}
          columns={columns}
          dataSource={this.props.trainer.QuestionTableData}
          size="medium"
          pagination={{
            pageSize: pageSize,
            pageSizeOptions: ["5", "10", "20", "30", "50"],
            showSizeChanger: true,
            onShowSizeChange: this.handlePageSizeChange,
            current: this.state.page,
            onChange: this.handlePageChange,
            total: this.props.trainer.totalCountQuestionTableData,
          }}
          loading={this.props.trainer.QuestionTableLoading}
          rowKey="_id"
          render={function (html) {
            return <div dangerouslySetInnerHTML={{ __html: html }} />;
          }}
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            overflowY: "hidden",
          }}
          filterDropdown={(props) => console.log("props", props)}
        />
        <Modal
          visible={this.props.trainer.NewQuestionmodalOpened}
          // visible={true}
          title="New Question"
          onCancel={this.closeNewModal}
          style={{
            top: "20px",
            padding: "0px",
            backgroundColor: "rgb(155,175,190)",
          }}
          width="80%"
          destroyOnClose={true}
          footer={<></>}
          bodyStyle={{ maxHeight: "75vh", overflow: "auto" }}
        >
          <NewQuestionForm />
        </Modal>

        <Modal
          visible={this.state.questiondetailsModelVisible}
          title="Question Details"
          onCancel={this.ClosedetailsModal}
          style={{
            top: "20px",
            padding: "0px",
            backgroundColor: "rgb(155,175,190)",
          }}
          width="70%"
          destroyOnClose={true}
          footer={[]}
        >
          <QuestionDetails id={this.state.questiondetailsId} />
        </Modal>
        <Modal
          visible={this.state.questionUpdateModelVisible}
          title="Question Details"
          onCancel={this.CloseUpdateModal}
          style={{
            top: "20px",
            padding: "0px",
            backgroundColor: "rgb(155,175,190)",
          }}
          width="90%"
          destroyOnClose={true}
          footer={[]}
        >
          <UpdateQuestion
            id={this.state.questiondetailsId}
            CloseUpdateModal={this.CloseUpdateModal}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  trainer: state.trainer,
  admin: state.admin,
});

export default connect(mapStateToProps, {
  ChangeQuestionModalState,
  ChangeQuestionTableData,
  ChangeQuestionSearchText,
  ChangeSelectedSubjects,
  ChangeSubjectTableData,
})(AllQuestions);
