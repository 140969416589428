import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../examPortal/portal.css";
import {
  fetchTraineeTestQuestions,
  fetchTraineeTestAnswerSheet,
} from "../../../actions/traineeAction";
import SingleQuestionResult from "./SingleQuestionResult";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";

const QuestionResult = ({
  trainee,
  fetchTraineeTestQuestions,
  fetchTraineeTestAnswerSheet,
  mode,
  triggerSidebar,
  totalScore,
  testName,
  totalMarks,
  data,
  isCorrect,
  reFetch,
  setReFetch,
  role,
}) => {
  const [shouldRenderQuestion, setShouldRenderQuestion] = useState(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isCorrectParam = searchParams.get("isCorrect");
  const traineeid = searchParams.get("traineeid");
  const testId = searchParams.get("testid");

  console.log("reFetch", data);

  useEffect(() => {
    fetchTraineeTestQuestions(testId);
    fetchTraineeTestAnswerSheet(testId, traineeid);

    const timeoutId = setTimeout(() => {
      setShouldRenderQuestion(true);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [testId, traineeid, reFetch]);

  return (
    <div className="question-holder-new">
      <div className="single-question-container-new">
        {shouldRenderQuestion &&
        trainee?.answers?.length > 0 &&
        trainee?.questions?.length > 0 ? (
          <SingleQuestionResult
            reFetch={reFetch}
            setReFetch={setReFetch}
            traineeid={traineeid}
            testId={testId}
            role={role}
            isCorrect={isCorrect && isCorrect}
            totalScore={totalScore}
            testName={testName}
            totalMarks={totalMarks}
            mode={mode}
            data={data}
            triggerSidebar={triggerSidebar}
            key={trainee.activeQuestionIndex}
          />
        ) : (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            size="large"
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default connect(mapStateToProps, {
  fetchTraineeTestQuestions,
  fetchTraineeTestAnswerSheet,
})(QuestionResult);
