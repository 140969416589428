const envoirnment = process.env.NODE_ENV;
/*const apis={
    BASE_LOCAL_URL:'http://localhost:3000',
    BASE : "http://localhost:5000",
    LOGIN : "/api/v1/login/",
    GETDETAILSUSER : "/api/v1/user/details",
    GET_ALL_TRAINER :'/api/v1/admin/trainer/details/all',
    GET_SINGLE_TRAINER_DETAILS : '/api/v1/admin/trainer/details',
    CREATE_TRAINER : '/api/v1/admin/trainer/create',
    DELETE_TRAINER : '/api/v1/admin/trainer/remove',
    GET_ALL_SUBJECTS : '/api/v1/subject/details/all',
    GET_SINGLE_SUBJECT_DETAILS : '/api/v1/subject/details',
    CREATE_SUBJECT : '/api/v1/subject/create',
    GET_ALL_QUESTIONS : '/api/v1/questions/details/all',
    DELETE_QUESTION:'/api/v1/questions/delete',
    FETCH_SINGLE_QUESTION:'/api/v1/questions/details',
    CREATE_QUESTIONS :'/api/v1/questions/create',
    FILE_UPLOAD:'/api/v1/upload',
    CREATE_TEST : '/api/v1/test/create',
    GET_ALL_TESTS:'/api/v1/test/details/all',
    GET_SINGLE_TEST:'/api/v1/test/trainer/details',
    REGISTER_TRAINEE_FOR_TEST:'/api/v1/trainee/enter',
    RESEND_TRAINER_REGISTRATION_LINK: '/api/v1/trainee/resend/testlink',
    GET_SINGLE_TEST_DETAILS_BASIC:'/api/v1/test/basic/details',
    STOP_REGISTRATION :'/api/v1/trainer/registration/stop',
    START_TEST_BY_TRAINER:'/api/v1/test/begin',
    GET_TEST_CANDIDATES :'/api/v1/test/candidates',
    GET_TEST_QUESTIONS :'/api/v1/test/questions',
    FETCH_TRAINEE_DETAILS:'/api/v1/trainee/details',
    FETCH_TRAINEE_TEST_DETAILS:'/api/v1/trainee/flags',
    PROCEED_TO_TEST:'/api/v1/trainee/answersheet',
    FETCH_TRAINEE_TEST_QUESTION:'/api/v1/trainee/paper/questions',
    FETCH_TRAINEE_TEST_ANSWERSHEET:'/api/v1/trainee/chosen/options',
    UPDATE_ANSWERS:'/api/v1/trainee/update/answer',
    END_TEST : '/api/v1/trainee/end/test',
    FETCH_OWN_RESULT:'/api/v1/final/results',
    FETCH_SINGLE_QUESTION_BY_TRAINEE:'/api/v1/trainee/get/question',
    END_TEST_BY_TRAINER:'/api/v1/test/end',
    FEEDBACK_STATUS_CHECK:'/api/v1/trainee/feedback/status',
    GIVE_FEEDBACK:'/api/v1/trainee/feedback',
    GET_STATS:'/api/v1/test/candidates/details',
    GET_EXCEL:'/api/v1/trainer/result/download',
    MAX_MARKS_FETCH:'/api/v1/test/max/marks'
}*/

export const IMGAE_BASE_URL = "https://league-online.b-cdn.net";

const apis = {
  // BASE_LOCAL_URL:
  //   envoirnment === "development"
  //     ? "https://wv9pfwh9-5000.inc1.devtunnels.ms"
  //     : "https://wv9pfwh9-5000.inc1.devtunnels.ms",
  // BASE:
  //   envoirnment === "development"
  //     ? "https://wv9pfwh9-5000.inc1.devtunnels.ms"
  //     : "https://wv9pfwh9-5000.inc1.devtunnels.ms",

  BASE_LOCAL_URL:
    envoirnment === "development"
      ? "https://www.leagueonline.sg:5000"
      : "https://www.leagueonline.sg:5000",
  BASE:
    envoirnment === "development"
      ? "https://www.leagueonline.sg:5000"
      : "https://www.leagueonline.sg:5000",

  IMGAE_BASE_URL: "https://league-online.b-cdn.net",

  LOGIN: "/api/v1/auth/login/",
  FORGOT_PASSWORD: "/api/v1/auth/forgotPassword",
  RESET_PASSWORD: "/api/v1/auth/resetPassword",
  GETDETAILSUSER: "/api/v1/user/details",
  GETDETAILTEACHER: "/api/v1/student/tutorsBySubject",

  GETDETAILSUSERSTATISTICS: "/api/v1/user/topicStatistics",

  GETDETAILSUSERSTATISTICSCOMPONENT: "/api/v1/user/papersStatistics",
  GENERATEPAPER: "/api/v1/worksheet",

  UPDATEPROFILE: "/api/v1/user/update",
  CONSULTATION_QUESTION: "/api/v1/questionConsultation",
  GET_CONSULTATION_QUESTION: "/api/v1/questionConsultation/student",
  GET_ALL_TRAINER: "/api/v1/admin/trainer/details/all",
  GET_SINGLE_TRAINER_DETAILS: "/api/v1/admin/trainer/details",
  CREATE_TRAINER: "/api/v1/admin/trainer/create",
  DELETE_TRAINER: "/api/v1/admin/trainer/remove",
  GET_ALL_STUDENT: "/api/v1/admin/student/all",
  APROVE_STUDENT: "/api/v1/admin/student/approve",
  DELETE_STUDENT: "/api/v1/admin/student/delete/",
  ASSIGN_TEST_STUDENT: "/api/v1/questions/assignTestToStudent",
  ALL_TEST_STUDENT: "/api/v1/test/student",
  GET_ALL_SUBJECTS: "/api/v1/subject/details/all",
  GET_SINGLE_SUBJECT_DETAILS: "/api/v1/subject/details",
  CREATE_SUBJECT: "/api/v1/subject/create",
  GET_ALL_QUESTIONS: "/api/v1/questions/details/all",
  GET_ALL_LEVEL_QUESTIONS: "/api/v1/questions/questionByLevel",
  DELETE_QUESTION: "/api/v1/questions/delete",
  DELETE_TEST: "/api/v1/test/delete",
  FETCH_SINGLE_QUESTION: "/api/v1/questions/details",
  CREATE_QUESTIONS: "/api/v1/questions/create",
  UPDATE_QUESTIONS: "/api/v1/questions/updateQuestion",
  FILE_UPLOAD: "/api/v1/upload",
  CREATE_TEST: "/api/v1/test/create",
  GET_ALL_TESTS: "/api/v1/test/details/all",
  GET_ALL_CONSULTATION_TUTOR: "/api/v1/questionConsultation/tutor",
  DELETE_CONSLTATION_QUESTION: "/api/v1/questionConsultation/delete",
  SUBMIT_CONSLTATION_QUESTION_RESPONSE: "/api/v1/questionConsultation/submit",
  GET_ALL_TESTS_NAME: "/api/v1/test/details/all/titles",
  GET_SINGLE_TEST: "/api/v1/test/trainer/details",
  GET_SINGLE_QUESTION: "/api/v1/questions/detail",
  REGISTER_TRAINEE_FOR_TEST: "api/v1/student/register",
  RESEND_TRAINER_REGISTRATION_LINK: "/api/v1/trainee/resend/testlink",
  GET_SINGLE_TEST_DETAILS_BASIC: "/api/v1/test/basic/details",
  STOP_REGISTRATION: "/api/v1/trainer/registration/stop",
  START_TEST_BY_TRAINER: "/api/v1/test/begin",
  GET_TEST_CANDIDATES: "/api/v1/test/candidates",
  GET_TEST_QUESTIONS: "/api/v1/test/questions",
  FETCH_TRAINEE_DETAILS: "/api/v1/trainee/details",
  FETCH_TRAINEE_TEST_DETAILS: "/api/v1/trainee/flags",
  PROCEED_TO_TEST: "/api/v1/trainee/answersheet",
  FETCH_TRAINEE_TEST_QUESTION: "/api/v1/trainee/paper/questions",
  FETCH_TRAINEE_TEST_ANSWERSHEET: "/api/v1/trainee/chosen/options",
  UPDATE_ANSWERS: "/api/v1/trainee/update/answer",
  END_TEST: "/api/v1/trainee/end/test",
  FETCH_OWN_RESULT: "/api/v1/final/results",
  FETCH_SINGLE_QUESTION_BY_TRAINEE: "/api/v1/trainee/get/question",
  END_TEST_BY_TRAINER: "/api/v1/test/end",
  FEEDBACK_STATUS_CHECK: "/api/v1/trainee/feedback/status",
  GIVE_FEEDBACK: "/api/v1/trainee/feedback",
  GET_STATS: "/api/v1/test/candidates/details",
  GET_EXCEL: "/api/v1/trainer/result/download",
  MAX_MARKS_FETCH: "/api/v1/test/max/marks",
  GET_FEEDBACKS: "/api/v1/trainer/get/feedbacks",
  CHECK_TEST_NAME: "/api/v1/test/new/name/check",
  ALL_TUTOR_NAME: "/api/v1/student/search",
  STUDENT_HISTOREY: "/api/v1/test/student/history",

  //////////////REWARD APIS END POINTS //////////////////////

  CREATE_CATEGORY: "/api/v1/rwrdcat/create",
  GET_CATEGORY: "/api/v1/rwrdcat",
  CREATE_REWARD: "/api/v1/reward/create",
  GET_REWARD: "/api/v1/reward",
  GET_ACTIVE_ORDER: "/api/v1/rewardHistory/claim",
  GET_PAST_ORDER: "/api/v1/rewardHistory/past",
  DELETE_REWARD: "/api/v1/reward",
  GET_REWARD_CATEGORY: "/api/v1/reward",
  GET_BADGES: "/api/v1/badge/",
  GET_REWARD_BADGE: "/api/v1/badge/user",

  CLAIM_REWARD: "/api/v1/rewardHistory/create",
  STATUS_ORDER_REWARD: "/api/v1/rewardHistory/deliver",

  ///////////////////MANUAL MARKING /////////////////
  MANUAL_MARKING: "/api/v1/marking/trainer",
};

export default apis;
