const initialState = {
  userStateComponent: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "STATECOMPONENT":
      return {
        ...state,
        userStateComponent: {
          ...action.payload,
          ...action.payload2,
        },
      };

    default:
      return state;
  }
};
