import React, { useEffect } from "react";
import { Table, Button, Typography, Divider, Modal } from "antd";

import { connect } from "react-redux";

import "../allStudent/allstudent.css";

import Alert from "../../../components/common/alert";
import { SecurePost } from "../../../services/axiosCall";
import apis from "../../../services/Apis";
import CrossIcon from "../../../assets/crossIcon.svg";
import { useState } from "react";
import AuthServices from "../../../services/AuthServices";
import { GetActiveOrders } from "../../../actions/GetActiveOrders";

const AllActiveOrder = (props) => {
  const { activeorders } = props;

  const [isInfo, setIsInfo] = useState(false);
  const [activeObject, setActiveObject] = useState();
  const [reFectch, setReFectch] = useState(false);

  const handleDeliverOrder = () => {
    SecurePost({
      url: apis.STATUS_ORDER_REWARD,
      data: {
        deliverDate: new Date(),
        rewardHistoryId: activeObject._id,
      },
    })
      .then((response) => {
        if (response.data.message) {
          Alert("success", "Success", response.data.message);
          setReFectch(!reFectch);
          setIsInfo(false);
        } else {
          return Alert("warning", "Warning!", response.data.message);
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  useEffect(() => {
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      AuthServices.GetAllActiveOrders(t).then((response) => {
        props.GetActiveOrders(response?.data?.data);
      });
    } else {
      window.location = "/";
    }
  }, [reFectch]);

  const { Title } = Typography;
  const columns = [
    {
      title: "Name",
      dataIndex: "userId",
      key: "userId",
      width: "25%",
      obj: "obj",

      render: (key, obj) => obj?.userId?.name || "N/A",
      // ...getColumnSearchProps("name"),
    },
    {
      title: "Order name",
      dataIndex: "rewardId",
      key: "_id",
      obj: "obj",

      width: "25%",
      render: (key, obj) => obj?.rewardId?.name || "N/A",
      // ...getColumnSearchProps("emailid"),
    },
    {
      title: "Category",
      dataIndex: "rewardId",
      key: "rewardId",
      obj: "obj",

      render: (key, obj) => obj?.rewardId?.categoryId?.name || "N/A",
      // ...getColumnSearchProps("contact"),
    },
    {
      title: "Invoice number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (text) => text || "N/A",
      // ...getColumnSearchProps("level"),
    },
    {
      title: "Date Ordered",
      dataIndex: "date",
      key: "date",
      render: (text) =>
        `${new Date(text).getMonth() + 1}/${new Date(
          text
        ).getDate()}/${new Date(text).getFullYear()}` || "N/A",
      // ...getColumnSearchProps("level"),
    },

    {
      title: "Action",
      key: "_id",
      dataIndex: "_id",
      obj: "obj",
      render: (key, obj) => (
        <span>
          <Button
            type="primary"
            shape="circle"
            icon="info-circle"
            onClick={() => {
              setActiveObject(obj);
              setIsInfo(true);
            }}
          />
          <Divider type="vertical" />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="admin-table-container">
        <div className="register-trainer-form-header">
          <Title level={4} style={{ color: "#fff", textAlign: "center" }}>
            Active Orders
          </Title>
        </div>
        <Table
          bordered={true}
          columns={columns}
          dataSource={activeorders}
          size="medium"
          pagination={{ pageSize: 10 }}
          rowKey="_id"
          style={{ backgroundColor: "#fff", padding: "10px" }}
        />
      </div>
      <Modal
        visible={isInfo}
        footer={null}
        closable={false}
        // centered
        onCancel={() => setIsInfo(false)}
        className="rewardMModalAdmin"
        width={350}
        style={{
          top: "30%",
          borderRadius: "25px",
          backgroundColor: "#ffff",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={CrossIcon}
              alt=""
              onClick={() => setIsInfo(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography style={{ padding: 0 }} className="cretaCateText">
              Invoice Number: {activeObject?.invoiceNumber}
            </Typography>
            <Typography>
              Customer Name: {activeObject?.userId?.name}{" "}
            </Typography>
            <Typography>Name:{activeObject?.rewardId?.name}</Typography>
            <Typography>
              Date Ordered:
              {`${new Date(activeObject?.date).getMonth() + 1}/${new Date(
                activeObject?.date
              ).getDate()}/${new Date(activeObject?.date).getFullYear()}`}
            </Typography>
            <Typography>Order Cost: {activeObject?.rewardId?.cost}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Button
              style={{ width: "120px", borderRadius: "60PX" }}
              type="primary"
              onClick={() => handleDeliverOrder()}
            >
              Deliver Order
            </Button>
          </div>
        </div>
      </Modal>{" "}
    </>
  );
};

const mapStateToProps = (state) => ({
  activeorders: state?.activeorders?.activeOrders,
});

export default connect(mapStateToProps, {
  GetActiveOrders,
})(AllActiveOrder);
