import React from "react";
import { connect } from "react-redux";
import Alert from "../../common/alert";
import apis from "../../../services/Apis";
import { Post, SecurePost } from "../../../services/axiosCall";
import {
  Button,
  Row,
  Col,
  Radio,
  Spin,
  Typography,
  Modal,
  Progress,
} from "antd";
import {
  switchQuestion,
  updateIsMarked,
  fetchTestdata,
} from "../../../actions/traineeAction";
import {
  fetchTraineeTestQuestions,
  fetchTraineeTestAnswerSheet,
} from "../../../actions/traineeAction";
import "./portal.css";
import TestBoardHeaderTest from "./TestBoardHeaderTest";
import { withRouter } from "react-router-dom";
import diamond from "../../../assets/diamond.svg";
import AuthServices from "../../../services/AuthServices";
import InfoIcon from "../../../assets/quickResultInfo.svg";

class SingleQuestion extends React.Component {
  constructor(props) {
    super(props);
    if (
      this.props.trainee?.answers[this.props.trainee.activeQuestionIndex]
        ?.chosenOption?.length ===
      this.props?.trainee?.questions[this.props.trainee.activeQuestionIndex]
        ?.anscount
    ) {
      this.state = {
        loading: true,
        AnswerSelected: true,
        isModalOpen: false,
        isSubmitModalOpen: false,
        isQuickResultModalOpen: false,
        isCorrectionReview: false,
        isOpenLink: false,
        singleQuestion: null,
        singleQuestionLoading: false,
        qickResult: {},
        badge: [],
        customAnswer:
          this.props.trainee?.answers[this.props.trainee.activeQuestionIndex]
            ?.customAnswer === undefined
            ? ""
            : this.props.trainee?.answers[
                this.props.trainee.activeQuestionIndex
              ]?.customAnswer,
        options:
          this.props?.trainee?.questions[this.props.trainee.activeQuestionIndex]
            .options,
        answers:
          this.props.trainee?.answers[this.props.trainee.activeQuestionIndex]
            ?.chosenOption,
        ticked: 0,
        left: null,
        options1: [
          { id: 1, name: "Option 1" },
          { id: 2, name: "Option 2" },
          { id: 3, name: "Option 3" },
        ],
        right: null,
        options2: [
          { id: 1, name: "Option 1" },
          { id: 2, name: "Option 2" },
          { id: 3, name: "Option 3" },
        ],
      };
    } else {
      this.state = {
        loading: true,
        AnswerSelected: false,
        isOpenLink: false,
        badge: [],
        singleQuestion: null,
        singleQuestionLoading: false,

        customAnswer:
          this.props.trainee?.answers[this.props.trainee.activeQuestionIndex]
            ?.customAnswer === undefined
            ? ""
            : this.props.trainee?.answers[
                this.props.trainee.activeQuestionIndex
              ]?.customAnswer,

        options:
          this.props?.trainee?.questions[this.props.trainee.activeQuestionIndex]
            .options,
        answers:
          this.props.trainee?.answers[this.props.trainee.activeQuestionIndex]
            ?.chosenOption,
        ticked: 0,
        isModalOpen: false,
        isSubmitModalOpen: false,
        isQuickResultModalOpen: false,
        isCorrectionReview: false,

        qickResult: {},
      };
    }
  }

  handleFetchSingleQuestion = () => {
    this.setState({
      singleQuestionLoading: true,
    });
    SecurePost({
      url: `${apis.GET_SINGLE_QUESTION}`,
      data: {
        _id: this.props?.trainee?.questions[
          this.props.trainee.activeQuestionIndex
        ]._id,
      },
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            singleQuestion: response?.data?.data,
            singleQuestionLoading: false,
          });
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        // return Alert("error", "Error!", message);
      });
  };

  componentDidMount() {
    this.handleFetchSingleQuestion();
  }

  handleOpenLink = () => {
    this.setState({ isOpenLink: true });
  };

  handleCancelLink = () => {
    this.setState({ isOpenLink: false });
  };

  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  handleSubmitOpen = () => {
    this.setState({ isSubmitModalOpen: true });
    if (this.state.AnswerSelected) {
      this.SaveTocloud();
    }
  };

  handleSubmitCancel = () => {
    this.setState({ isSubmitModalOpen: false });
  };

  handleQuickResultOpen = () => {
    this.setState({ isQuickResultModalOpen: true });
    this.endTest();
    this.props.trainee.socket.current.disconnect();
  };

  componentWillMount() {
    this.setState((PState, Pprops) => {
      let t = 0;
      var s = PState?.options
        ? PState?.options?.map((d, i) => {
            for (var ii = 0; ii < PState?.answers?.length; ii++) {
              if (PState?.answers[ii] === d._id) {
                t += 1;
                return { ...d, checked: true };
              }
            }
            return { ...d, checked: false };
          })
        : null;
      return { ticked: t, options: s };
    });
  }

  componentDidCatch() {
    this.setState({
      customAnswer:
        this.props.trainee?.answers[this.props.trainee.activeQuestionIndex]
          ?.customAnswer === undefined
          ? ""
          : this.props.trainee?.answers[this.props.trainee.activeQuestionIndex]
              ?.customAnswer,
    });
  }

  componentWillUnmount() {
    if (this.state.AnswerSelected) {
      this.SaveTocloud();
    }
  }

  SaveTocloud = () => {
    Post({
      url: `${apis.UPDATE_ANSWERS}`,
      data: {
        testid: this.props.trainee.testid,
        userid: this.props.trainee.traineeid,
        qid: this.props.trainee.questions[
          this.props.trainee.activeQuestionIndex
        ]._id,
        newAnswer: this.state?.answers,
        customAnswer:
          (this.state?.left || "") +
          (this.state.customAnswer || "") +
          (this.state?.right || ""),
        isMcq: this.state?.options.length === 0 ? false : true,
      },
    })
      .then((response) => {
        if (response.data.success) {
          // this.setState({ loading: response.data.success });

          if (this.state.customAnswer === "") {
            var t = [...this.props.trainee.answers];
            t[this.props.trainee.activeQuestionIndex] = {
              ...t[this.props.trainee.activeQuestionIndex],
              chosenOption: this.state.answers,
              isAnswered: true,
            };
            this.props.updateIsMarked(t);
            // this.props.fetchTraineeTestQuestions(this.props.trainee.testid);
            this.props.fetchTraineeTestAnswerSheet(
              this.props.trainee.testid,
              this.props.trainee.traineeid
            );
          } else {
            var t = [...this.props.trainee.answers];
            t[this.props.trainee.activeQuestionIndex] = {
              ...t[this.props.trainee.activeQuestionIndex],
              customAnswer: this.state.customAnswer,
              isAnswered: true,
            };
            this.props.updateIsMarked(t);
            // this.props.fetchTraineeTestQuestions(this.props.trainee.testid);
            this.props.fetchTraineeTestAnswerSheet(
              this.props.trainee.testid,
              this.props.trainee.traineeid
            );
          }
        } else {
          this.props.fetchTestdata(
            this.props.trainee.testid,
            this.props.trainee.traineeid
          );
          // return Alert("error", "Error!", response.data.message);
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        // return Alert("error", "Error!", message);
      });
  };

  previous = () => {
    if (this.state.AnswerSelected) {
      this.SaveTocloud();
    }
    if (this.props.trainee.activeQuestionIndex > 0) {
      this.props.switchQuestion(this.props.trainee.activeQuestionIndex - 1);
    }
  };
  autoSave = () => {
    if (this.state.AnswerSelected) {
      this.SaveTocloud();
    }
  };
  next = () => {
    this.setState({ loading: false });
    if (this.state.AnswerSelected) {
      this.SaveTocloud();
      if (
        this.props.trainee.activeQuestionIndex <
        this.props.trainee.questions.length - 1
      ) {
        this.props.switchQuestion(this.props.trainee.activeQuestionIndex + 1);
      }
    } else {
      if (
        this.props.trainee.activeQuestionIndex <
        this.props.trainee.questions.length - 1
      ) {
        this.props.switchQuestion(this.props.trainee.activeQuestionIndex + 1);
      }
    }
  };
  mark = () => {
    let aa = [...this.props.trainee.answers];
    let c = aa[this.props.trainee.activeQuestionIndex];
    c.isMarked =
      !this.props.trainee.answers[this.props.trainee.activeQuestionIndex]
        .isMarked;
    aa[this.props.trainee.activeQuestionIndex] = c;
    this.props.updateIsMarked(aa);
  };

  handleTestComplete = () => {
    this.props.fetchTestdata(
      this.props.trainee.testid,
      this.props.trainee.traineeid
    );
  };

  handleReveiwOpen = () => {
    this.setState({ isCorrectionReview: true });
    this.setState({ isQuickResultModalOpen: false });
  };

  handleReveiwClose = () => {
    this.setState({ isCorrectionReview: false });
    this.setState({ isQuickResultModalOpen: true });
  };

  onAnswerChange = (d1, d2, d3) => {
    var ansCount =
      this.props.trainee.questions[this.props.trainee.activeQuestionIndex]
        .anscount;
    if (d2) {
      var op1 = this.state.options?.map((option, index) =>
        index === d1
          ? { ...option, checked: true }
          : { ...option, checked: false }
      );
      var op2 = [d3];
      this.setState({
        AnswerSelected: true,
        ticked: 1,
        options: op1,
        answers: op2,
      });
    } else {
      op1 = this.state.options?.map((option, index) =>
        index === d1 ? { ...option, checked: false } : option
      );
      op2 = [];
      this.setState({
        AnswerSelected: false,
        ticked: 0,
        options: op1,
        answers: op2,
      });
    }
  };

  endTest = () => {
    SecurePost({
      url: `${apis.END_TEST}`,
      data: {
        testid: this.props.trainee.testid,
        userid: this.props.trainee.traineeid,
      },
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({ qickResult: response.data.data });
        } else {
          return Alert("error", "Error!", response.data.message);
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      SecurePost({
        url: apis.GET_REWARD_BADGE,
        params: {
          Token: t,
        },
      }).then((response) => {
        this.setState({ badge: response.data });

        // setRewardBadgesData(response?.data);
        // setIsLoading(true);
      });
    }
  };

  render() {
    const { history } = this.props;
    let opts = ["A", "B", "C", "D", "E"];
    const image =
      this.state?.singleQuestion?.quesimg &&
      this.state?.singleQuestion?.quesimg?.split("www.leagueonline.sg:5000");
    const path = apis.IMGAE_BASE_URL;
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const videoExtensions = ["mp4", "avi", "mov", "mkv"];
    // Extract the file extension from the URL

    const extension =
      this.state?.singleQuestion?.quesimg == undefined ||
      this.state?.singleQuestion?.quesimg == null
        ? ""
        : image && image[0] === "https://"
        ? image[1].split(".").pop().toLowerCase()
        : this.state?.singleQuestion?.quesimg.split(".").pop().toLowerCase();

    return (
      <>
        {this.state.loading === false ? (
          <Spin />
        ) : (
          <div>
            <TestBoardHeaderTest
              testName={this.props.trainee.title}
              next={this.next}
              previous={this.previous}
              isCorrect={this.props?.isCorrect}
              isSubmitted={
                this.props.trainee.activeQuestionIndex ===
                this.props.trainee.questions.length - 1
              }
              handleSubmitOpen={this.handleSubmitOpen}
            />
            {this.state.singleQuestionLoading ? (
              <div
                style={{
                  padding: "10px 20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
                className="Question-single-container-new"
              >
                <Spin size="large" />
              </div>
            ) : (
              <>
                <div
                  style={{
                    padding: "10px 20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  className="Question-single-container-new"
                >
                  <div
                    style={{
                      zIndex: 999,
                    }}
                  >
                    <Row>
                      <Col className="leftColQuestionResult" md={0} xs={24}>
                        {this.props.mode === "mobile" ? (
                          <Button
                            onClick={this.props.triggerSidebar}
                            style={{ background: "#009999", color: "#fff" }}
                          >
                            Tool
                          </Button>
                        ) : null}
                      </Col>
                    </Row>

                    <br />

                    <Row>
                      <Col span={24} style={{ fontSize: "1.2rem" }}>
                        <Typography
                          style={{ color: "#FD350A" }}
                          className="marksText"
                        >
                          <span className="questionNoText questionBody">
                            Question{" "}
                            {this.props.trainee.activeQuestionIndex + 1}
                            &nbsp;
                          </span>
                          {this.state?.singleQuestion?.weightage + " mark(s)"}
                        </Typography>
                        <div>
                          <div
                            className="questionBody"
                            dangerouslySetInnerHTML={{
                              __html: this.state?.singleQuestion?.body,
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="Question-single-body-holder">
                      {this.state?.singleQuestion?.quesimg !== null ? (
                        <div className="Single-question-body-image-container-new">
                          {imageExtensions.includes(extension) ? (
                            image && image[0] === "https://" ? (
                              <>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={path + image[1]}
                                  className="image-tag"
                                  onClick={this.handleOpen}
                                />
                                <Typography className="zoomInText">
                                  Click on the image to zoom in.
                                </Typography>
                              </>
                            ) : (
                              <>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={
                                    path +
                                    "/" +
                                    this.state?.singleQuestion?.quesimg
                                  }
                                  className="image-tag"
                                  onClick={this.handleOpen}
                                />
                                <Typography>
                                  Click on the image to zoom in.
                                </Typography>
                              </>
                            )
                          ) : videoExtensions.includes(extension) ? (
                            image && image[0] === "https://" ? (
                              <video className="image-tag" controls>
                                <source
                                  src={path + image[1]}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              <video className="image-tag" controls>
                                <source
                                  src={
                                    path +
                                    "/" +
                                    this.state?.singleQuestion?.quesimg
                                  }
                                  type="video/mp4"
                                />
                              </video>
                            )
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="Question-single-option-panel">
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {this.state?.options &&
                        this.state?.options?.length > 0 &&
                        this.state?.options?.map((d, i) => {
                          return (
                            <Col
                              span={12}
                              key={i}
                              className="Single-option-new"
                            >
                              <Row
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Col
                                  span={2}
                                  style={{ textAlign: "center", width: "auto" }}
                                >
                                  {/* <Button
                                  style={{
                                    background: "#009999",
                                    color: "#fff",
                                  }}
                                  shape="circle"
                                >
                                </Button> */}
                                  <Typography className="questionBody">
                                    ({opts[i]})
                                  </Typography>
                                  {/* <Checkbox
                                  checked={d.checked}
                                  onChange={(e) => {
                                    this.onAnswerChange(
                                      i,
                                      e.target.checked,
                                      d._id
                                    );
                                  }}
                                /> */}
                                </Col>
                                <Col
                                  span={22}
                                  style={{
                                    padding: "4px",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <h3
                                    className="questionBody"
                                    style={{
                                      margin: 0,
                                    }}
                                  >
                                    {d.optbody}
                                  </h3>
                                  <div
                                    className={
                                      d.optimg
                                        ? "option-image-in-exam-panel-holder"
                                        : "option-image-in-exam-panel-holder-w0"
                                    }
                                  >
                                    {d.optimg ? (
                                      <img
                                        crossorigin="anonymous"
                                        alt="Unable to load"
                                        className="option-image-in-exam-panel"
                                        width="150px"
                                        height="150px"
                                        style={{ marginLeft: "12px" }}
                                        src={d.optimg}
                                      />
                                    ) : null}
                                  </div>
                                  <Radio
                                    checked={d.checked}
                                    size="large"
                                    buttonStyle="solid"
                                    className="radioQuestion"
                                    onChange={(e) => {
                                      this.onAnswerChange(
                                        i,
                                        e.target.checked,
                                        d._id
                                      );
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                      {this.state?.singleQuestion?.quesimgUrl && (
                        <Typography
                          className="openlink"
                          onClick={this.handleOpenLink}
                        >
                          open link
                        </Typography>
                      )}
                      {this.state?.singleQuestion?.isLong ? (
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px 20px",
                          }}
                          span={24}
                        >
                          <textarea
                            value={this.state.customAnswer}
                            name="answer"
                            className="ansInput"
                            rows={5}
                            style={{
                              backgroundColor: "white",
                              width: "inherit",
                            }}
                            onChange={(e) =>
                              this.setState({
                                customAnswer: e.target.value,
                                AnswerSelected: true,
                              })
                            }
                            required
                          />
                        </Col>
                      ) : this.state?.singleQuestion?.isShort ? (
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          span={23}
                        >
                          <Row
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              bottom: 0,
                              gap: 4,
                            }}
                          >
                            {this.state?.singleQuestion?.position === "left" ? (
                              <Col>
                                <Typography
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "22px",
                                    textAlign: "end",
                                  }}
                                >
                                  {this.state?.singleQuestion?.unit}
                                </Typography>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col
                              // style={{ width: "180px" }}
                              className="ansInputp"
                              span={18}
                            >
                              <textarea
                                width={"100%"}
                                value={this.state.customAnswer}
                                name="answer"
                                rows={1}
                                className="ansInput"
                                style={{
                                  backgroundColor: "white",
                                }}
                                onChange={(e) =>
                                  this.setState({
                                    customAnswer: e.target.value,
                                    AnswerSelected: true,
                                  })
                                }
                                required
                              />
                            </Col>

                            {this.state?.singleQuestion?.position ===
                            "right" ? (
                              <Col>
                                <Typography
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "22px",
                                  }}
                                >
                                  {this.state?.singleQuestion?.unit}
                                </Typography>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                </div>
              </>
            )}

            <br />
          </div>
        )}
        <Modal
          visible={this.state.isOpenLink}
          footer={null}
          closable={false}
          centered
          onCancel={this.handleCancelLink}
          width={650}
          className="modalLink"
          bodyStyle={{ padding: 0 }}
          style={{
            borderRadius: "25px",
            zIndex: 9999999999999,
          }}
        >
          <div
            style={{
              maxHeight: "70vh",
              overflowY: "auto",
            }}
            className="openLinkModalWrapper"
          >
            <img
              style={{ cursor: "pointer" }}
              src={this.state?.singleQuestion?.quesimgUrl}
              width={"100%"}
              // height={"100%"}
            />
          </div>
        </Modal>
        <Modal
          visible={this.state.isModalOpen}
          footer={null}
          closable={false}
          centered
          onCancel={this.handleCancel}
          width={750}
          bodyStyle={{ height: "100%", padding: 0 }}
          style={{ borderRadius: "25px", zIndex: 99999999 }}
        >
          <div>
            {this.state?.singleQuestion?.quesimg ? (
              <div>
                {imageExtensions.includes(extension) ? (
                  image && image[0] === "https://" ? (
                    <>
                      <img
                        style={{ cursor: "pointer" }}
                        src={path + image[1]}
                        // className="image-tag"
                        width={"100%"}
                        height={"100%"}
                        onClick={this.handleOpen}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        style={{ cursor: "pointer" }}
                        src={path + "/" + this.state?.singleQuestion?.quesimg}
                        width={"100%"}
                        height={"100%"}
                        // className="image-tag"
                        onClick={this.handleOpen}
                      />
                    </>
                  )
                ) : null}
              </div>
            ) : null}
          </div>
        </Modal>
        <Modal
          visible={this.state.isSubmitModalOpen}
          footer={null}
          closable={false}
          centered
          onCancel={this.handleSubmitCancel}
          width={350}
          style={{ borderRadius: "25px", zIndex: 999999 }}
        >
          <p className="modalTitle">
            Are you sure you want to submit this assignment?
          </p>
          <div className="modalButtons">
            <Button
              className="modalButtonYes"
              onClick={this.handleQuickResultOpen}
            >
              Yes
            </Button>
            <Button className="modalButtonNo" onClick={this.handleSubmitCancel}>
              No
            </Button>
          </div>
        </Modal>
        <Modal
          visible={this.state.isQuickResultModalOpen}
          footer={null}
          closable={false}
          centered
          // onCancel={this.handleQuickResultCancel}
          width={550}
          style={{ borderRadius: "25px", zIndex: 999999 }}
        >
          {Object.keys(this.state?.qickResult).length !== 0 ? (
            <div
              style={{
                widthL: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Typography
                style={{ fontWeight: 600, fontSize: "18px" }}
                className="quickResultText"
              >
                {this.props.trainee.questions.find((item) => item?.isLong)
                  ? "Final Results Pending"
                  : ""}
              </Typography>
              {this.props.trainee.questions.find((item) => item?.isLong) ? (
                <>
                  <Row>
                    <Col span={24}>
                      <div className="quickResultModalParrent">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            cursor: "pointer",

                            justifyContent: "center",
                          }}
                        >
                          <Typography className="quickResultText">
                            Graded <br />
                            Component <br />
                            Marks
                          </Typography>
                          <div className="quickResultHoverInfoBox">
                            <img className="infoIcon" src={InfoIcon} alt="" />
                            <div className="quickResultInfo">
                              <Typography className="quickResultInfoText">
                                Due to the inclusion of open-ended questions in
                                this worksheet, this paper requires manual
                                review. The results shown are based on the
                                questions that have been automatically graded.
                                When your paper has been reviewed, you will
                                receive an update to your registered email
                                address informing that you can view your
                                results. We will do our best to review your
                                paper within 48 hours.
                              </Typography>
                            </div>
                          </div>
                        </div>
                        {/* <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="quickResultText">
                        Graded Component Marks
                      </Typography>
                    </div> */}

                        <div
                          className={
                            Object.keys(this.state?.qickResult).length !== 0
                              ? "custom-progress"
                              : "custom-progress curcularProgressEmptyFill"
                          }
                        >
                          <Progress
                            type="circle"
                            percent={
                              Object.keys(this.state?.qickResult).length !== 0
                                ? this.state?.qickResult?.studentScorePercentage
                                : 0
                            }
                            strokeColor={
                              this.state?.qickResult?.studentScorePercentage <
                                this.state?.qickResult
                                  ?.allStudentsMarksPercent &&
                              this.state?.qickResult?.studentScorePercentage >
                                "50"
                                ? "#FAFF1C"
                                : this.state?.qickResult
                                    ?.studentScorePercentage >=
                                    this.state?.qickResult
                                      ?.allStudentsMarksPercent &&
                                  this.state?.qickResult
                                    ?.studentScorePercentage > "50"
                                ? "#67FF0B"
                                : this.state?.qickResult
                                    ?.studentScorePercentage < "50%"
                                ? "red"
                                : "red"
                            } // Change the color of the progress
                            strokeWidth={10} // Change the width of the progress
                            trailColor="#d3d3d3" // Change the color of the trail
                            trailWidth={10} // Change the width of the trail
                            strokeStyle={{
                              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow to the progress
                            }}
                            format={() => (
                              <span className="progress-text-quickResult">
                                {Object.keys(this.state?.qickResult).length !==
                                0
                                  ? this.state?.qickResult?.studentScore +
                                    "/" +
                                    this.state?.qickResult?.totalMarks
                                  : "N/A"}
                              </span>
                            )}
                          />
                        </div>
                        <Typography
                          style={{ fontWeight: 400 }}
                          className="quickResultText"
                        >
                          {Object.keys(this.state?.qickResult).length !== 0
                            ? this.state?.qickResult?.studentScorePercentage +
                              "%"
                            : "N/A"}
                          <br />
                          {Object.keys(this.state?.qickResult).length !== 0
                            ? this.state?.qickResult?.studentGrade
                            : "N/A"}
                        </Typography>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col span={12}>
                      <div className="quickResultModalParrent">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            cursor: "pointer",

                            justifyContent: "center",
                          }}
                        >
                          <Typography className="quickResultText">
                            Your <br />
                            Marks
                          </Typography>
                        </div>
                        <div
                          className={
                            Object.keys(this.state?.qickResult).length !== 0
                              ? "custom-progress"
                              : "custom-progress curcularProgressEmptyFill"
                          }
                        >
                          <Progress
                            type="circle"
                            percent={
                              Object.keys(this.state?.qickResult).length !== 0
                                ? this.state?.qickResult?.studentScorePercentage
                                : 0
                            }
                            strokeColor={
                              this.state?.qickResult?.studentScorePercentage <
                                this.state?.qickResult
                                  ?.allStudentsMarksPercent &&
                              this.state?.qickResult?.studentScorePercentage >
                                "50"
                                ? "#FAFF1C"
                                : this.state?.qickResult
                                    ?.studentScorePercentage >=
                                    this.state?.qickResult
                                      ?.allStudentsMarksPercent &&
                                  this.state?.qickResult
                                    ?.studentScorePercentage > "50"
                                ? "#67FF0B"
                                : this.state?.qickResult
                                    ?.studentScorePercentage < "50%"
                                ? "red"
                                : "red"
                            } // Change the color of the progress
                            strokeWidth={10} // Change the width of the progress
                            trailColor="#d3d3d3" // Change the color of the trail
                            trailWidth={10} // Change the width of the trail
                            strokeStyle={{
                              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow to the progress
                            }}
                            format={() => (
                              <span className="progress-text-quickResult">
                                {Object.keys(this.state?.qickResult).length !==
                                0
                                  ? this.state?.qickResult?.studentScore +
                                    "/" +
                                    this.state?.qickResult?.totalMarks
                                  : "N/A"}
                              </span>
                            )}
                          />
                        </div>
                        <Typography
                          style={{ fontWeight: 400 }}
                          className="quickResultText"
                        >
                          {Object.keys(this.state?.qickResult).length !== 0
                            ? this.state?.qickResult?.studentScorePercentage +
                              "%"
                            : "N/A"}
                          <br />
                          {Object.keys(this.state?.qickResult).length !== 0
                            ? this.state?.qickResult?.studentGrade
                            : "N/A"}
                        </Typography>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="quickResultModalParrent">
                        <Typography className="quickResultText">
                          Average <br /> Marks
                        </Typography>
                        <div
                          className={
                            Object.keys(this.state?.qickResult).length !== 0
                              ? "custom-progress"
                              : "custom-progress curcularProgressEmptyFill"
                          }
                        >
                          <Progress
                            type="circle"
                            percent={
                              Object.keys(this.state?.qickResult).length !== 0
                                ? this.state?.qickResult
                                    ?.allStudentsMarksPercent
                                : 0
                            }
                            strokeColor="#fff" // Change the color of the progress
                            strokeWidth={10} // Change the width of the progress
                            trailColor="#d3d3d3" // Change the color of the trail
                            trailWidth={10} // Change the width of the trail
                            strokeStyle={{
                              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow to the progress
                            }}
                            format={() => (
                              <span className="progress-text-quickResult">
                                {Object.keys(this.state?.qickResult).length !==
                                0
                                  ? this.state?.qickResult
                                      ?.allStudentsMarksAverage +
                                    "/" +
                                    this.state?.qickResult?.totalMarks
                                  : "N/A"}
                              </span>
                            )}
                          />
                        </div>
                        <Typography
                          style={{ fontWeight: 400 }}
                          className="quickResultText"
                        >
                          {Object.keys(this.state?.qickResult).length !== 0
                            ? this.state?.qickResult?.allStudentsMarksPercent +
                              "%"
                            : "N/A"}
                          <br />
                          {Object.keys(this.state?.qickResult).length !== 0
                            ? this.state?.qickResult?.totalGrade
                            : "N/A"}
                        </Typography>
                      </div>{" "}
                    </Col>
                  </Row>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "12px",
                }}
              >
                <Typography
                  style={{ fontWeight: 400 }}
                  className="quickResultText"
                >
                  Points earned:
                </Typography>
                {this.state?.qickResult?.monthlyCapReached ? (
                  <Typography
                    style={{ fontWeight: 400 }}
                    className="quickResultText"
                  >
                    (Monthly Point Cap Reached)
                  </Typography>
                ) : (
                  <div>
                    <Typography
                      style={{ fontWeight: 400 }}
                      className="quickResultText"
                    >
                      + <img width={"22px"} src={diamond} alt="" />{" "}
                      {this.state?.qickResult?.earnPoint} points
                      <sub style={{ fontSize: "14px" }}>(completion)</sub>
                    </Typography>
                    <Typography
                      style={{ fontWeight: 400 }}
                      className="quickResultText"
                    >
                      + <img width={"22px"} src={diamond} alt="" />{" "}
                      {this.state?.qickResult?.performancePoint} Points{" "}
                      <sub style={{ fontSize: "14px" }}>
                        (performance bonus)
                      </sub>
                    </Typography>
                  </div>
                )}

                <div>
                  <Typography
                    style={{ fontWeight: 700, color: "#888888" }}
                    className="quickResultText"
                  >
                    <img width={"22px"} src={diamond} alt="" />{" "}
                    {this.state?.qickResult?.totalPoints} points
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 400,
                      color: "#BABABA",
                      fontSize: "18px",
                    }}
                    className="quickResultText"
                  >
                    Points Accumulated
                  </Typography>
                </div>
              </div>
              <Row justify="center">
                <Col offset={5} span={14}>
                  <div className="modalButtons">
                    <Button
                      className="modalButtonNo"
                      onClick={this.handleReveiwOpen}
                    >
                      Review Test
                    </Button>
                    <Button
                      className="modalButtonNo"
                      onClick={() => {
                        this.handleTestComplete();
                        this.props.switchQuestion(0);

                        history.push("/user/livetest");
                      }}
                    >
                      Return to Take Test page
                    </Button>
                    <Button
                      className="modalButtonNo"
                      onClick={() => {
                        this.handleTestComplete();
                        this.props.switchQuestion(0);
                        history.push("/user/testHistory");
                      }}
                    >
                      Return to Test History
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" />
            </div>
          )}
        </Modal>
        <Modal
          visible={this.state.isCorrectionReview}
          footer={null}
          closable={false}
          centered
          onCancel={this.handleReveiwClose}
          width={350}
          style={{ borderRadius: "25px", zIndex: 999999 }}
        >
          <p className="modalTitle">
            Which review option do you wish to choose:
          </p>
          <div className="modalButtons">
            <Button
              className="modalButtonNo"
              onClick={() => {
                this.handleTestComplete();
                this.props.switchQuestion(0);
              }}
            >
              Full Review
            </Button>
            <Button
              className="modalButtonNo"
              onClick={() => {
                this.handleTestComplete();
                this.props.switchQuestion(0);
                history.push({
                  pathname: `/trainee/taketest`,
                  search: `?testid=${this.props.trainee.testid}&traineeid=${
                    this.props.trainee.traineeid
                  }&isCorrect=${false}`,
                  state: { isCorrect: false },
                });
              }}
            >
              Correction Review
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default withRouter(
  connect(mapStateToProps, {
    switchQuestion,
    updateIsMarked,
    fetchTestdata,
    fetchTraineeTestQuestions,
    fetchTraineeTestAnswerSheet,
  })(SingleQuestion)
);
