import React, { Component } from "react";
import apis from "../../../services/Apis";
import { SecurePost } from "../../../services/axiosCall";
import Alert from "../../common/alert";
import { Row, Col } from "antd";
import "./conducttes.css";

export default class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.refreshquestionList();
  }

  refreshquestionList = () => {
    this.setState({
      loading: true,
    });
    SecurePost({
      url: `${apis.GET_TEST_QUESTIONS}`,
      data: {
        id: this.props.id,
      },
    })
      .then((response) => {
        if (response.data.success) {
          this.props.updateQuestiosnTest(response.data.data);
        }
        // else {
        //   Alert("error", "Error!", response.data.message);
        // }
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        Alert("error", "Error!", message);
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const aMap = ["A", "B", "C", "D", "E"];
    return (
      <div>
        {this.props.questionsOfTest
          ? this.props.questionsOfTest.map((d, i) => {
              const image =
                d.quesimg && d.quesimg.split("www.leagueonline.sg:5000");
              const path = apis.IMGAE_BASE_URL;
              const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
              const videoExtensions = ["mp4", "avi", "mov", "mkv"];
              // Extract the file extension from the URL
              const extension =
                d.quesimg == undefined || d.quesimg == null
                  ? ""
                  : image && image[0] === "https://"
                  ? image[1].split(".").pop().toLowerCase()
                  : d.quesimg.split(".").pop().toLowerCase();
              return (
                <div key={i} style={{ paddingBottom: "50px" }}>
                  <Row>
                    <Col span={20}>
                      <b>Q {i + 1}&#41; &nbsp;</b>
                      {/* <b>{d.body}</b> */}
                      <div dangerouslySetInnerHTML={{ __html: d.body }}></div>
                      <br />
                      {d.quesimg && (
                        <div className="questionDetailsImageContainer">
                          {imageExtensions.includes(extension) ? (
                            image && image[0] === "https://" ? (
                              <img
                                src={path + image[1]}
                                className="image-tag"
                              />
                            ) : (
                              <img
                                src={path + "/" + d.quesimg}
                                className="image-tag"
                              />
                            )
                          ) : videoExtensions.includes(extension) ? (
                            image && image[0] === "https://" ? (
                              <video className="image-tag" controls>
                                <source
                                  src={path + image[1]}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              <video className="image-tag" controls>
                                <source
                                  src={path + "/" + d.quesimg}
                                  type="video/mp4"
                                />
                              </video>
                            )
                          ) : null}
                        </div>
                        // <img
                        //   crossorigin="anonymous"
                        //   alt="unable to load"
                        //   className="questionDetailsImage"
                        //   src={d.quesimg}
                        //   // style={{ width: "100%" }}
                        // />
                      )}
                    </Col>
                    <Col span={4}>This question is worth: {d.weightage}</Col>
                    <Col span={20}>
                      {d.options
                        ? d.options.map((dd, ii) => {
                            return (
                              <Col
                                key={ii}
                                span={12}
                                style={{ padding: "10px 20px" }}
                              >
                                <b>{aMap[ii]} &#41; </b> {dd.optbody}
                                {dd.optimg ? (
                                  <img
                                    crossorigin="anonymous"
                                    className="questionDetailsImage"
                                    width="200"
                                    height="200"
                                    alt="Option"
                                    style={{ marginLeft: "12px" }}
                                    src={dd.optimg}
                                  />
                                ) : null}
                              </Col>
                            );
                          })
                        : null}
                    </Col>
                  </Row>
                </div>
              );
            })
          : null}
      </div>
    );
  }
}
