import React, { useState } from "react";
import { Tabs } from "antd";
import { connect } from "react-redux";
import {
  ChangeStudentTableData,
  ChangeTrainerSearchText,
} from "../../../actions/adminAction";
import { GetTestTableData } from "../../../actions/traineeAction";
import { ChangeAssignTestModalState } from "../../../actions/trainerAction";
import "./allStudentTest.css";
import { withRouter } from "react-router-dom";

import SubjectTestData from "./SubjectTestData/SubjectTestData";
import DashboardWrapper from "../shared/DashboardWrapper";

const Student = () => {
  const { TabPane } = Tabs;

  const [tabKey, setTabKey] = useState("Mathematics");

  return (
    <>
      <DashboardWrapper title="TAKE TEST">
        <div className="tabs">
          <Tabs
            tabBarStyle={{
              borderBottom: "none",
            }}
            onTabClick={(e) => setTabKey(e)}
          >
            <TabPane tab="MATHEMATICS" key="Mathematics">
              <SubjectTestData title={tabKey} />
            </TabPane>
            <TabPane tab="ENGLISH" key="English">
              <SubjectTestData title={tabKey} />
            </TabPane>
            <TabPane tab="SCIENCE" key="Science">
              <SubjectTestData title={tabKey} />
            </TabPane>
            <TabPane tab="MOTHER TONGUE" key="all"></TabPane>
          </Tabs>
        </div>
      </DashboardWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  trainer: state.trainer,
  trainee: state.trainee,
});

export default withRouter(
  connect(mapStateToProps, {
    ChangeAssignTestModalState,
    ChangeStudentTableData,
    GetTestTableData,
    ChangeTrainerSearchText,
  })(Student)
);
