/* eslint-disable no-unused-expressions */
import { Button, Divider, Modal, Typography } from "antd";
import React from "react";
import "./testBoardHeader.css";
import { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { switchQuestion } from "../../../actions/traineeAction";
import { useWindowDimensions } from "./testBoard";
import { truncateString } from "./TestBoardHeaderTest";
const TestBoardHeader = (props) => {
  const { testName, next, previous, isCorrect, isFinish, role } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const { width } = useWindowDimensions();

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleFinish = () => {
    props.switchQuestion(0);
    // props.trainee?.socket?.current?.disconnect();

    history.push(role !== "TRAINER" ? "/user/testHistory" : "/user/listtests");
  };
  console.log("props_TestHeader", props.trainee);

  return (
    <>
      <div className="headerParrentWrapper">
        <div className="headerLeft">
          <Typography
            style={{ cursor: "pointer" }}
            className="exit"
            onClick={() => handleOpen()}
          >
            {width >= 500
              ? role !== "TRAINER"
                ? "exit assessment"
                : "exit manual marking"
              : "exit"}
          </Typography>
          <Button
            style={{ borderRadius: "30PX" }}
            type="primary"
            onClick={() => previous()}
            className="testHeaderButton"
          >
            Previous
          </Button>
        </div>
        <div className="headerInner">
          <Typography className="hederTestName">
            {testName
              ? width <= 600
                ? truncateString(testName, 15)
                : testName
              : null}{" "}
          </Typography>
          <Divider className="testHeaderDivider" />
          <Typography className="testHeaderTimeText">
            {role !== "TRAINER"
              ? isCorrect
                ? "FULL REVIEW"
                : "CORRECTION REVIEW"
              : "MANUAL MARKING"}
          </Typography>
        </div>
        <div className="headerRight">
          <Button
            style={{ borderRadius: "30PX" }}
            type="primary"
            onClick={isFinish ? () => handleFinish() : () => next()}
            className="testHeaderButtonFinish"
          >
            {isFinish ? (width <= 600 ? "Finish" : "Finish Review") : "Next >"}
          </Button>
        </div>
      </div>
      <Modal
        visible={isModalOpen}
        footer={null}
        closable={false}
        centered
        onCancel={handleCancel}
        width={350}
        style={{ borderRadius: "25px", zIndex: 999999 }}
      >
        <p className="modalTitle">
          Are you sure you want to exit the{" "}
          {role !== "TRAINER" ? " assessment?" : "manual marking assessment?"}
        </p>
        <div className="modalButtons">
          <Button className="modalButtonNo" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="modalButtonNo"
            onClick={() => {
              props.switchQuestion(0);
              history.push(
                role !== "TRAINER" ? "/user/testHistory" : "/user/listtests"
              );
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default withRouter(
  connect(mapStateToProps, {
    switchQuestion,
  })(TestBoardHeader)
);
