import React, { useState, useEffect } from "react";
import "../../basic/login/login.css";
import { Form, Icon, Input, Button, Select } from "antd";
import queryString from "query-string";
import apis from "../../../services/Apis";
import { Redirect } from "react-router-dom";
import { Post } from "../../../services/axiosCall";
import Alert from "../../common/alert";
import { SearchTutor } from "../../../actions/traineeAction";
import { connect } from "react-redux";
import { givenLevels } from "../../common/constants";
import { useHistory } from "react-router-dom";
// const { Option } = Select;

const TraineeRegisterForm = (props) => {
  const [inform, setInform] = useState(true);
  // const [testid, setTestId] = useState(null);
  // const [user, setUser] = useState(null);
  const [link, setLink] = useState(null);
  const [tutorName, setTutorName] = useState("");
  const history = useHistory();
  const [expandOptions, setExpandOptions] = useState(false); // State for expand button
  const handleExpandOptions = () => {
    setExpandOptions(!expandOptions);
  };

  const { getFieldDecorator, validateFields, resetFields } = props.form;

  // const handleTutorChange = (event) => {
  //   const name = event.target.value;
  //   if (name.trim()) {
  //     props.SearchTutor(name);
  //   }
  //   setTutorName(name);
  // };

  // useEffect(() => {
  //   let params = queryString.parse(props.location.search);
  //   setTestId(params.testid);
  // }, [props.location.search]);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        // if (tutorName === "") {
        //   Alert("info", "Error!", "Tutor Name is Required");
        //   return;
        // }
        Post({
          url: apis.REGISTER_TRAINEE_FOR_TEST,
          data: {
            name: values.name,
            emailid: values.email,
            // contact: `${values.prefix}${values.contact}`,
            // organisation: values.organisation,
            // testid: testid,
            // location: values.location,
            password: values.password,
            postelCode: values.postalCode,
            // tutorName: tutorName,
            level: values.level,
          },
        })
          .then((data) => {
            if (data.data.success) {
              setInform(false);
              // setUser(data.data.user);
              setLink(data.data.testLink);
              setTutorName("");
            } else {
              Alert("info", "Error!", data.data.message);
              // setInform(false);
              // setLink(data.data.testLink);
              // setTutorName("");
              // props.SearchTutor(tutorName);
            }
          })
          .catch((error) => {
            console.log(error);
            resetFields();
            const message = error.response
              ? error.response.data.message
              : error.message;
            return Alert("error", "Error!", message);
          });
      }
    });
  };

  // const prefixSelector = getFieldDecorator("prefix", {
  //   initialValue: "+65",
  //   rules: [{ required: true, message: "Please enter contact no prefix" }],
  // })(
  //   <Select style={{ width: 70 }}>
  //     <Option value="+65">+65</Option>
  //   </Select>
  // );

  const handleClick = () => {
    history.push("/register");
  };

  const handleLoginClick = () => {
    history.push("/");
  };

  return (
    <div className="home-page-container">
      <div className="parallax">
        {inform ? (
          <div className="login-container">
            <div className="login-student-inner">
              <div className="space-btw">
                <Button
                  className="register-btn"
                  ghost
                  onClick={handleLoginClick}
                >
                  Sign In
                </Button>
                <Button className="login-btn" ghost onClick={handleClick}>
                  Register
                </Button>
              </div>
              <div className="login-log">
                <img
                  src="/assets/leagueonlinelogo2.png"
                  alt="logo"
                  height={"70px"}
                />
              </div>
              <Form onSubmit={handleSubmit} className="login-form">
                <Form.Item label="Email" colon={false} hasFeedback>
                  {getFieldDecorator("email", {
                    rules: [
                      { type: "email", message: "This is not valid E-mail!" },
                      {
                        required: true,
                        message: "Please enter your E-mail!",
                      },
                    ],
                  })(
                    <Input
                      // prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
                      placeholder="Email"
                    />
                  )}
                </Form.Item>
                <div style={{ gap: "12px" }} className="space-btw-registor">
                  <Form.Item
                    label="Child’s Level of Study"
                    colon={false}
                    hasFeedback
                    style={{
                      width: "100%",
                    }}
                    required
                  >
                    {getFieldDecorator("level", {
                      rules: [
                        {
                          required: true,
                          message: "Please select Child’s Level of Study",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        placeholder="Select Level"
                        optionFilterProp="s"
                        onClick={handleExpandOptions} // Trigger expandOptions state on click
                      >
                        {givenLevels.map((d, i) => (
                          <Select.Option
                            key={d.value}
                            s={d.label}
                            value={d.value}
                          >
                            {d.label}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item
                    style={{
                      width: "100%",
                    }}
                    label="Child’s First Name"
                    colon={false}
                    hasFeedback
                  >
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Child’s First Name",
                        },
                      ],
                    })(
                      <Input
                        // prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                        placeholder="Child’s First Name"
                      />
                    )}
                  </Form.Item>
                </div>

                <Form.Item label="Postal Code" colon={false} hasFeedback>
                  {getFieldDecorator("postalCode", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter your postal code",
                      },
                      {
                        pattern: /^[0-9]{1,6}$/, // Enforce numbers and limit to 1-6 digits
                        message:
                          "Please enter a valid postal code with up to 6 digits",
                      },
                    ],
                  })(<Input type="number" placeholder="Postal Code" />)}
                </Form.Item>

                <Form.Item label="Password" colon={false} hasFeedback>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter your password",
                      },
                    ],
                  })(
                    <Input.Password
                      // prefix={<Icon type="password" style={{ color: "rgba(0,0,0,.25)" }} />}
                      placeholder="Password"
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label="Re-enter your Password"
                  colon={false}
                  hasFeedback
                >
                  {getFieldDecorator("retypePassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please re-enter your password",
                      },
                      {
                        validator: (_, value, callback) => {
                          const { getFieldValue } = props.form;
                          if (value && value !== getFieldValue("password")) {
                            callback("Passwords do not match");
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  })(
                    <Input.Password
                      // prefix={<Icon type="password" style={{ color: "rgba(0,0,0,.25)" }} />}
                      placeholder="Re-enter your Password"
                    />
                  )}
                </Form.Item>
                {/* <Form.Item label="Phone Number" hasFeedback>
                    {getFieldDecorator("contact", {
                      rules: [
                        { required: true, message: "Please enter your phone number!" },
                        { len: 8, message: "Contact number must be 8 digit long" },
                      ],
                    })(
                      <Input
                        addonBefore={prefixSelector}
                        min={8}
                        max={8}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Tutor Name" hasFeedback>
                    <Input
                      prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} />}
                      placeholder="Tutor Name"
                      onChange={handleTutorChange}
                      value={tutorName}
                    />
                  </Form.Item>
                  <div
                    style={{
                      backgroundColor: "#D3D3D3",
                      color: "black",
                      borderRadius: "5px",
                    }}
                  >
                    {props.trainee.allTutor.map((item) => (
                      <h4
                        style={{
                          padding: "2px",
                          margin: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTutorName(item.name);
                          props.SearchTutor(item.name);
                        }}
                      >
                        {item.name}
                      </h4>
                    ))}
                  </div> */}
                {/* <Form.Item label="Level" hasFeedback>
{getFieldDecorator("level", {
rules: [
{ required: true, message: "Please select a level" },
],
})(
<Select placeholder="Select level">
<Option value="Level 1">Level 1</Option>
<Option value="Level 2">Level 2</Option>
<Option value="Level 3">Level 3</Option>
<Option value="Level 4">Level 4</Option>
<Option value="Level 5">Level 5</Option>
<Option value="Level 6">Level 6</Option>
</Select>
)}
</Form.Item> */}

                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Register
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : (
          <Redirect to={link} />
        )}
      </div>
    </div>
  );
};

const TraineeRegister = Form.create({ name: "Trainee Registration" })(
  TraineeRegisterForm
);

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default connect(mapStateToProps, { SearchTutor })(TraineeRegister);
