const initialState = {
  userState: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "STATE":
      return {
        ...state,
        userState: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
