import apis from "../services/Apis";
import Alert from "../components/common/alert";
import { Get, Post, SecureGet, SecurePost } from "../services/axiosCall";

let parse_time = (d) => {
  var m_left = Math.floor(d / 60);
  var s_left =
    Math.floor(d % 60) < 10 ? "0" + Math.floor(d % 60) : Math.floor(d % 60);
  return {
    m_left: m_left,
    s_left: s_left,
  };
};

export const setTestDetsils = (d1, d2) => {
  return {
    type: "SET_TRAINEE_TEST_DETAILS",
    payload1: d1,
    payload2: d2,
  };
};
export const LocaltestDone = (d) => (dispatch) => {
  dispatch({
    type: "TEST_DONE_LOCAL",
  });
};

export const fetchTraineedata = (d) => (dispatch) => {
  console.log("Fetching traineedata");
  Post({
    url: apis.FETCH_TRAINEE_DETAILS,
    data: {
      _id: d,
    },
  }).then((response) => {
    if (response.data.success) {
      dispatch({
        type: "FETCH_LOGGED_IN_TRAINEE",
        payload: response.data.data,
      });
    } else {
      Alert("error", "Error!", response.data.message);
    }
  });
};

export const fetchPeanding = (data, socket) => (dispatch) => {
  if (data?.pending) {
    let t = parse_time(data.pending);
    dispatch({
      type: "FETCH_TEST_FLAG",
      payload1: data.testbegins,
      payload2: data.startedWriting,
      payload3: data.testconducted,
      payload4: data.completed,
      payload5: t.m_left,
      payload6: t.s_left,
      payload7: socket,
    });
  }
};

export const fetchTestdata = (d1, d2) => (dispatch) => {
  Post({
    url: apis.FETCH_TRAINEE_TEST_DETAILS,
    data: {
      testid: d1,
      traineeid: d2,
    },
  })
    .then((response) => {
      if (response.data.success) {
        if (
          response.data.data.completed ||
          !response.data.data.startedWriting
        ) {
          dispatch({
            type: "FETCH_TEST_FLAG",
            payload1: response.data.data.testbegins,
            payload2: response.data.data.startedWriting,
            payload3: response.data.data.testconducted,
            payload4: response.data.data.completed,
            // payload5: 0,
            // payload6: 0,
          });
        } else {
          let t = parse_time(response.data.data.pending);
          dispatch({
            type: "FETCH_TEST_FLAG",
            payload1: response.data.data.testbegins,
            payload2: response.data.data.startedWriting,
            payload3: response.data.data.testconducted,
            payload4: response.data.data.completed,
            // payload5: t.m_left,
            // payload6: t.s_left,
          });
        }
      } else {
        dispatch({
          type: "invalidUrl",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "invalidUrl",
      });
    });
};

export const ProceedtoTest = (d1, d2, d3) => (dispatch) => {
  dispatch({
    type: "PROCEEDING_TO_TEST",
    payload: true,
  });
  Post({
    url: `${apis.PROCEED_TO_TEST}`,
    data: { testid: d1, userid: d2 },
  })
    .then((response) => {
      if (!response.data.success) {
        Alert("error", "Error!", response.data.message);
      }
      d3();
      dispatch({
        type: "PROCEEDING_TO_TEST",
        payload: false,
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: "PROCEEDING_TO_TEST",
        payload: false,
      });
      const message = error.response
        ? error.response.data.message
        : error.message;
      return Alert("error", "Error!", message);
    });
};

export const fetchTraineeTestQuestionsResult = (data, title) => (dispatch) => {
  dispatch({
    type: "UPDATE_TRAINEE_TEST_QUESTIONS_RESULT",
    payload: data,
  });
};

export const fetchTraineeTestQuestions = (tid) => (dispatch) => {
  Post({
    url: `${apis.FETCH_TRAINEE_TEST_QUESTION}`,
    data: {
      id: tid,
    },
  })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "UPDATE_TRAINEE_TEST_QUESTIONS",
          payload: response.data.data,
          payload1: response.data.title,
        });
        return response;
      } else {
        Alert("error", "Error!", response.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      const message = error.response
        ? error.response.data.message
        : error.message;
      return Alert("error", "Error!", message);
    });
};

export const fetchTraineeTestAnswerSheet = (tid, uid) => (dispatch) => {
  Post({
    url: `${apis.FETCH_TRAINEE_TEST_ANSWERSHEET}`,
    data: {
      testid: tid,
      userid: uid,
    },
  })
    .then((response) => {
      if (response.data.success) {
        var d = response?.data?.data?.answers?.map((d, i) => {
          if (d.chosenOption.length !== 0) {
            return { ...d, isMarked: false, isAnswered: true };
          } else if (d.customAnswer !== undefined && d.customAnswer !== "") {
            return { ...d, isMarked: false, isAnswered: true };
          } else return { ...d, isMarked: false, isAnswered: false };
        });
        dispatch({
          type: "UPDATE_TRAINEE_TEST_ANSWERSHEET",
          payload: d,
        });
      } else {
        Alert("error", "Error!", response.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      const message = error.response
        ? error.response.data.message
        : error.message;
      return Alert("error", "Error!", message);
    });
};

export const switchQuestion = (d1) => {
  return {
    type: "SWITCH_QUESTION",
    payload: d1,
  };
};

export const updateIsMarked = (d1) => {
  return {
    type: "UPDATE_TRAINEE_TEST_ANSWERSHEET",
    payload: d1,
  };
};

export const FeedbackStatus = (s) => {
  return {
    type: "SET_HAS_GIVEN_FEEDBACK",
    payload: s,
  };
};

export const GetTestTableData = (data) => (dispatch) => {
  dispatch({
    type: "CHANGE_STUDENT_TEST_TABLE_LOADING_STATUS",
    payload1: true,
    payload2: [],
  });
  SecurePost({
    url: `${apis.ALL_TEST_STUDENT}`,
    data: data,
  })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "CHANGE_STUDENT_TEST_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: response.data.data,
        });
      } else {
        Alert("error", "Error!", response.data.message);
        dispatch({
          type: "CHANGE_STUDENT_TEST_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: [],
        });
      }
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.message
        : error.message;
      Alert("error", "Error!", message);
      dispatch({
        type: "CHANGE_STUDENT_TEST_TABLE_LOADING_STATUS",
        payload1: false,
        payload2: [],
      });
    });
};

export const SearchTutor = (text) => (dispatch) => {
  dispatch({
    type: "CHANGE_STUDENT_SEARCH_TUTOR_LOADING_STATUS",
    payload1: true,
    payload2: [],
  });
  Get({
    url: `${apis.ALL_TUTOR_NAME}?search=${text}`,
  })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "CHANGE_STUDENT_SEARCH_TUTOR_LOADING_STATUS",
          payload1: false,
          payload2: response.data.data,
        });
      } else {
        Alert("error", "Error!", response.data.message);
        dispatch({
          type: "CHANGE_STUDENT_SEARCH_TUTOR_LOADING_STATUS",
          payload1: false,
          payload2: [],
        });
      }
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.message
        : error.message;
      Alert("error", "Error!", message);
      dispatch({
        type: "CHANGE_STUDENT_SEARCH_TUTOR_LOADING_STATUS",
        payload1: false,
        payload2: [],
      });
    });
};

export const StudentHistoreyTableData = (data) => (dispatch) => {
  dispatch({
    type: "CHANGE_STUDENT_HISTOREY_TEST_TABLE_LOADING_STATUS",
    payload1: true,
    payload2: [],
  });
  SecurePost({
    url: `${apis.STUDENT_HISTOREY}`,
    data: data,
  })
    // SecureGet({
    //   url: `${apis.STUDENT_HISTOREY}`,
    // })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "CHANGE_STUDENT_HISTOREY_TEST_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: response.data.data,
        });
      } else {
        Alert("error", "Error!", response.data.message);
        dispatch({
          type: "CHANGE_STUDENT_HISTOREY_TEST_TABLE_LOADING_STATUS",
          payload1: false,
          payload2: [],
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: "CHANGE_STUDENT_HISTOREY_TEST_TABLE_LOADING_STATUS",
        payload1: false,
        payload2: [],
      });
      const message = error.response
        ? error.response.data.message
        : error.message;
      return Alert("error", "Error!", message);
    });
};

export const ChangeTestSearchText = (d) => (dispatch) => {
  dispatch({
    type: "CHANGE_TEST_SEARCH_TEXT",
    payload: d,
  });
};

export const ChangeTestDetailsModalState = (d1, d2) => (dispatch) => {
  dispatch({
    type: "CHANGE_TEST_DETAILS_MODAL_STATE",
    payload1: d1,
    payload2: d2,
  });
};
