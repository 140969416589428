import { Modal } from "antd";

export default async function Alert(s = "warning", h, b) {
  if (s === "success") {
    return await Modal.success({
      title: h,
      content: b,
    });
  } else if (s === "error") {
    return await Modal.error({
      title: h,
      content: b,
    });
  } else if (s === "message") {
    return await Modal.success({
      title: h,
      content: b,
    });
  } else {
    return await Modal.warning({
      title: h,
      content: b,
    });
  }
}
