import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import {
  changeStep,
  changeBasicNewTestDetails,
} from "../../../actions/testAction";
import GeneraterandomQuestion from "./generaterandomquestion";
import "./newtest.css";
import Alert from "../../common/alert";

function SelectQuestion(props) {
  const [nextFormData, setNextFormData] = useState([]);

  const handlePrevious = () => {
    props.changeStep(0);
  };

  const handleNext = () => {
    if (nextFormData.length !== 0) {
      props.changeStep(2);
    } else {
      Alert("error", "Error!", "Please Select Questions");
    }
  };

  return (
    <div>
      <GeneraterandomQuestion mode="manual" setNextFormData={setNextFormData} />
      <div style={{ width: "100%", padding: "10px" }}>
        <Button style={{ float: "right" }} type="primary" onClick={handleNext}>
          Next
        </Button>
        <Button
          style={{ float: "right", marginRight: "1%" }}
          type="primary"
          onClick={handlePrevious}
        >
          Previous
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  test: state.test,
});

const mapDispatchToProps = {
  changeStep,
  changeBasicNewTestDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectQuestion);
