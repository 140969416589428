import React, { useEffect, useState } from "react";
import "../profile/profile.css";
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";

import { connect } from "react-redux";
import AuthServices from "../../../services/AuthServices";
import ImageConsoulation from "../../../assets/questionconsoulation.png";
import "./index.css";
import UploadIcon from "../../../assets/fileUpload.svg";
import TextArea from "antd/lib/input/TextArea";
import { subjects } from "../../common/constants";
import Alert from "../../common/alert";
import { GetStudentConsultation } from "../../../actions/consultationAction";
import apis from "../../../services/Apis";
import { PlayCircleOutlined } from "@ant-design/icons";
import "../examPortal/testBoardHeader.css";
import DiamondIcon from "../../../assets/diamond.svg";

const QuesConsulation = (props) => {
  const [image, setImage] = useState();
  const [values, setValues] = useState({ query: "", subject: "" });
  const [refetch, setRefetch] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [singleQuestion, setSingleQuestion] = useState({});
  const { getFieldDecorator } = props.form;
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    const video = document.getElementById("videoPlayer");

    if (!video) return;

    if (!isPlaying) {
      video.play();
    } else {
      video.pause();
    }
  };

  const handleImageChage = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.type.startsWith("image/")) {
        setImage(selectedFile);
      } else {
        Alert("warning", "message", "Please select a valid image file.");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubjectChange = (name, value) => {
    setValues({
      ...values,
      [name]: value.value,
    });
  };
  const handleLogOut = () => {
    AuthServices.deleteToken();
    window.location.href = "/";
  };

  const uploadQuestionConsultation = () => {
    const t = AuthServices.retriveToken();
    const formDate = new FormData();
    formDate.append("image", image);
    formDate.append("subject", values?.subject);
    formDate.append("query", values?.query);
    if (!image) {
      Alert("warning", "Message", "Please upload image ");
    }
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (t && t !== "undefined") {
          AuthServices.UploadQuestionForConsulatation(t, formDate).then(
            (response) => {
              Alert("success", "Success", response?.data?.message);
              setValues({ query: "", subject: "" });
              props.form.setFieldsValue({ query: "", subject: "" });
              setImage(null);
              setRefetch(!refetch);
            }
          );
        } else {
          window.location = "/";
        }
      }
    });
  };

  useEffect(() => {
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      AuthServices.GetQuestionForConsulatation(t).then((response) => {
        props.GetStudentConsultation(response?.data?.data);
      });
    } else {
      window.location = "/";
    }
  }, [refetch]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("title"),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: "25%",

      render: (text) => (
        <div
          className="tableDataText"
          style={{
            backgroundColor: "transparent",
            color: "white",
            textTransform: "capitalize",
          }}
        >
          {text || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("level"),
    },
    {
      title: "Tutor",
      dataIndex: "tutor",
      key: "tutor",
      width: "25%",

      render: (text) => (
        <div
          className="tableDataText"
          style={{ backgroundColor: "transparent", color: "white" }}
        >
          {text.name || "N/A"}
        </div>
      ),
      // ...getColumnSearchProps("year"),
    },

    {
      title: "Show solution",
      key: "_id",
      // width: "25%",
      dataIndex: "_id",
      render: (key, obj) => (
        <span>
          {key && (
            <Button
              disabled={obj.status === "unanswer" ? true : false}
              style={{
                borderRadius: "35px",
                width: "auto",
                backgroundColor:
                  obj.status === "unanswer" ? "#A7A7A7" : "#0082fb",
                borderColor: obj.status === "unanswer" ? "#A7A7A7" : "#0082fb",
              }}
              type="primary"
              onClick={() => {
                setIsModelOpen(true);
                setSingleQuestion(obj);
              }}
            >
              {obj.status === "unanswer" ? "submitted" : "view solution"}
            </Button>
          )}
        </span>
      ),
    },
  ];
  const rowClassNameFn = (record, index) => {
    return index % 2 === 0 ? "even_row" : "odd_row";
  };

  return (
    <>
      <div className="profile_wrapper">
        <div className="profile_containers">
          <div className="container">
            <div className="container_inner">
              <div className="edit_profile_container">
                <div className="space-between">
                  <Typography className="profile_text">
                    QUESTION CONSULTATION
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="rewardBox">
                      <img width="20px" src={DiamondIcon} alt="" />
                      <Typography style={{ color: "white" }}>
                        {props.user?.userDetails?.points?.toLocaleString()}
                      </Typography>
                    </div>
                    <Button type="primary" onClick={handleLogOut}>
                      Log out
                    </Button>
                  </div>
                </div>
                <Row
                  // className="question_consultation_container"
                  style={{ width: "95%", margin: "auto", padding: "20px 0px" }}
                  gutter={16}
                >
                  <Col xs={24} md={12}>
                    <div>
                      <div>
                        <div style={{ position: "relative", width: "100%" }}>
                          <img
                            style={{ zIndex: 0 }}
                            width={"100%"}
                            src={ImageConsoulation}
                            alt=""
                          />
                          <div className="uploadButton">
                            <Typography className="uploadButtonText">
                              Upload question
                            </Typography>
                          </div>
                        </div>
                        <div
                          style={{ paddingTop: "25px" }}
                          className="justify-between"
                        >
                          <Typography className="uploadText">
                            Upload image of your question
                          </Typography>
                          {/* {image ? (
                            <Typography className="imageName">
                              {image.name}
                            </Typography>
                          ) : (
                            <label
                              style={{ cursor: "pointer" }}
                              className="fileUpload"
                            >
                              <img src={UploadIcon} alt="" />
                              <input
                                onChange={handleImageChage}
                                type="file"
                                accept="image/*"
                                style={{ width: "0%" }}
                              />
                            </label>
                          )} */}
                        </div>
                        <div
                          style={{
                            // paddingTop: "25px",
                            borderRadius: "20px",
                            backgroundColor: "#F1F1F1",
                          }}
                          className="justify-between"
                        >
                          <Typography className="uploadTextNew">
                            {image ? image.name : "Choose files to upload"}
                          </Typography>
                          <label
                            style={{ cursor: "pointer" }}
                            className="fileUploadNew"
                          >
                            Browse Files
                            <input
                              onChange={handleImageChage}
                              type="file"
                              accept="image/*"
                              style={{ width: "0%" }}
                            />
                          </label>
                          {/* {image ? (
                            <Typography className="imageName">
                              {image.name}
                            </Typography>
                          ) : (
                            <label
                              style={{ cursor: "pointer" }}
                              className="fileUpload"
                            >
                              <img src={UploadIcon} alt="" />
                              <input
                                onChange={handleImageChage}
                                type="file"
                                accept="image/*"
                                style={{ width: "0%" }}
                              />
                            </label>
                          )} */}
                        </div>
                        <div className="stacks">
                          <Typography className="queryLabel">
                            Enter your query
                          </Typography>
                          <Form.Item hasFeedback>
                            {getFieldDecorator("query", {
                              initialValue: values.query,

                              rules: [
                                {
                                  required: true,
                                  message: "Please input your query!",
                                  whitespace: true,
                                },
                              ],
                            })(
                              <TextArea
                                style={{ borderRadius: "20px" }}
                                className="ansInput"
                                rows={5}
                                name="query"
                                // value={values.query}
                                onChange={handleChange}
                              />
                            )}
                          </Form.Item>
                        </div>
                        <div className="flex-gap">
                          <Typography className="subjectText">
                            Subject
                          </Typography>
                          <Form.Item className="label" hasFeedback>
                            {getFieldDecorator("subject", {
                              initialValue: values.subject,

                              rules: [
                                {
                                  required: true,
                                  message: "Please input your subject!",
                                  whitespace: true,
                                },
                              ],
                            })(
                              <Select
                                showSearch
                                style={{ width: "225px" }}
                                placeholder="Eng tutor name"
                                className="select_editProfile"
                                optionFilterProp="s"
                                name="subject"
                                // value={values.subject}
                              >
                                {subjects.map((d, i) => (
                                  <Select.Option
                                    key={d.value}
                                    s={d.label}
                                    value={d.value}
                                    onClick={() =>
                                      handleSubjectChange("subject", {
                                        value: d.value,
                                      })
                                    }
                                  >
                                    {d.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </div>

                      <div style={{ paddingTop: "10px" }} className="center">
                        <Button
                          className="testHeaderButton"
                          type="primary"
                          style={{ borderRadius: "30PX" }}
                          onClick={() => uploadQuestionConsultation()}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div
                      style={{
                        border: "1px solid #fff",
                        padding: "10px",
                        borderRadius: "20PX",
                        maxHeight: "75vh",
                        height: "100%",
                        overflowY: "auto",
                      }}
                      className="container_inner"
                    >
                      <div className="edit_profile_container">
                        <div className="space-between">
                          <Typography className="list_Question">
                            List of questions
                          </Typography>
                        </div>
                        <Divider className="divier_edit" />
                        <Table
                          columns={columns}
                          dataSource={props?.consultation?.studentConsultation}
                          size="medium"
                          // pagination={{ pageSize: 4 }}
                          // loading={trainee.allStudentTestLoading}
                          rowKey="_id"
                          style={{
                            backgroundColor: "transparent",
                            color: "white !important",
                          }}
                          rowClassName={rowClassNameFn}
                          components={{
                            header: {
                              cell: (props) => (
                                <th
                                  className="custom_table_header"
                                  style={{
                                    backgroundColor: "transparent", // Set your desired header background color
                                    color: "white", // Set the font color
                                    fontWeight: "bold", // Set the font weight
                                  }}
                                  {...props}
                                />
                              ),
                            },
                          }}
                          className="custom_table_consultation"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={isModelOpen}
        footer={null}
        closable={false}
        centered
        onCancel={() => setIsModelOpen(false)}
        width={800}
        className="explanationModal"
        style={{
          borderRadius: "15px",
          backgroundColor: "background: #D9D9D9",
          color: "black",
        }}
      >
        <div style={{ position: "relative" }}>
          <video
            width={"100%"}
            id="videoPlayer"
            controls
            src={apis.IMGAE_BASE_URL + singleQuestion.explainationVideo}
            onClick={togglePlay}
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  subjectTutor: state.subjectTutor,
  consultation: state.consultation,
});

const QuesConsulationForm = Form.create({ name: "QuesConsulation" })(
  QuesConsulation
);

export default connect(mapStateToProps, {
  GetStudentConsultation,
})(QuesConsulationForm);
