import auth from "../services/AuthServices";

export const login = (details) => (dispatch) => {
  dispatch({
    type: "LOGIN",
    payload: details,
  });
};

export const statetistics = (details) => (dispatch) => {
  dispatch({
    type: "STATE",
    payload: details,
  });
};

export const statetisticsComponent = (details, details2) => (dispatch) => {
  dispatch({
    type: "STATECOMPONENT",
    payload: details,
    payload1: details2,
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: "LOGOUT",
    payload1: "Manual Logout",
  });
};

export const forgotpassword = (details) => (dispatch) => {
  dispatch({
    type: "FORGOT",
    payload: details,
  });
};

export const resetpassword = (details) => (dispatch) => {
  dispatch({
    type: "RESET",
    payload: details,
  });
};

export const wakeUp = () => (dispatch) => {
  var t = auth.retriveToken() || null;
  if (t && t !== "undefined") {
    auth
      .wakeUp(t)
      .then((res) => {
        dispatch({
          type: "LOGIN",
          payload1: t,
          payload2: res.data.user,
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: "LOGOUT",
            payload1: "Token Expired",
          });
        }
      });
  } else {
    dispatch({
      type: "LOGOUT",
      payload1: "No Token",
    });
  }
};
