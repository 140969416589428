import React, { useState, useEffect } from "react";
import "./portal.css";
import Sidepanel from "./sidepanel";
import Question from "./question";
import { Drawer, Typography } from "antd";
import BgImage from "../../../assets/testPageBg.svg";
import TestBoardHeader from "./TestBoardHeader";
import SiderQImage from "../../../assets/siderQImage.svg";
import Operations from "./operations";
import Clock from "./clock";

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export default function TestBoard(props) {
  const {
    // height,
    width,
  } = useWindowDimensions();
  const [visible, setVisible] = useState(false);
  const [sideQuestion, setSideQuestion] = useState(false);

  const handleToggleQuestion = () => {
    setSideQuestion(!sideQuestion);
  };

  let onClose = () => setVisible(false);
  let onOpen = () => setVisible(true);
  if (width > 768) {
    return (
      <div className="exam-dashboard-wrapper-new">
        <Question
          mode={width > 768 ? "desktop" : "mobile"}
          triggerSidebar={onOpen}
          loading={props.loading}
        />
        <div className="side_panel">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              marginTop: "20%",
            }}
          >
            <img
              width={"80%"}
              style={{ cursor: "pointer" }}
              src={SiderQImage}
              alt=""
              onClick={() => handleToggleQuestion()}
            />
          </div>
          {sideQuestion && (
            <div className="side_Question">
              <Operations />
            </div>
          )}
        </div>
        {/* <div className="clock">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "44px",
              justifyContent: "center",
            }}
          >
            <Typography className="testHeaderTimeText">Time Left:</Typography>
            <Clock />
          </div>
        </div> */}
      </div>
    );
  } else {
    return (
      <div className="exam-dashboard-wrapper-new">
        <Question
          mode={width > 768 ? "desktop" : "mobile"}
          triggerSidebar={onOpen}
          loading={props.loading}
        />

        <Drawer
          title="Toolbar"
          placement="right"
          closable={true}
          onClose={onClose}
          visible={visible}
          width="100%"
        >
          <Sidepanel mode={width > 768 ? "desktop" : "mobile"} />
        </Drawer>
      </div>
    );
  }
}
