import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Typography, Skeleton } from "antd";
import "./portal.css";
import Instruction from "./instruction";
import TestBoard from "./testBoard";
import Answer from "../answersheet/answer";
import {
  fetchTraineedata,
  setTestDetsils,
  fetchTestdata,
  fetchPeanding,
  fetchTraineeTestQuestions,
  fetchTraineeTestAnswerSheet,
} from "../../../actions/traineeAction";
import queryString from "query-string";
import { io } from "socket.io-client";
import apis from "../../../services/Apis";
import { Post } from "../../../services/axiosCall";
import Alert from "../../common/alert";
import { useState } from "react";

const { Title } = Typography;

function MainPortal(props) {
  const {
    location,
    fetchTraineedata,
    setTestDetsils,
    fetchTestdata,
    fetchPeanding,
  } = props;

  const params = queryString.parse(location.search);
  const socket = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    socket.current = io(apis.BASE_LOCAL_URL);
  }, []);

  useEffect(() => {
    socket.current.on("connect", () => {
      console.log("connection established");
      // setLoading(true);
    });
    if (loading) {
      socket.current.emit("join_test", {
        testid: params.testid,
        traineeid: params.traineeid,
        socketid: socket.current.id,
      });
      socket.current.on("pending", (data) => {
        fetchPeanding(data, socket);
      });
    }
  }, [socket, loading]);

  // useEffect(() => {
  //   let pAnswere = Post({
  //     url: `${apis.FETCH_TRAINEE_TEST_ANSWERSHEET}`,
  //     data: {
  //       testid: params.testid,
  //       userid: params.traineeid,
  //     },
  //   });

  //   Promise.all([pAnswere])
  //     .then((r) => {
  //       if (r[0].data.success) {
  //         var d = r[0]?.data?.data?.answers?.map((d, i) => {
  //           if (d.chosenOption.length !== 0) {
  //             return { ...d, isMarked: false, isAnswered: true };
  //           } else if (d.customAnswer !== undefined && d.customAnswer !== "") {
  //             return { ...d, isMarked: false, isAnswered: true };
  //           } else return { ...d, isMarked: false, isAnswered: false };
  //         });
  //         dispatch({
  //           type: "UPDATE_TRAINEE_TEST_ANSWERSHEET",
  //           payload: d,
  //         });

  //         setLoading(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       const message = error.response
  //         ? error.response.data.message
  //         : error.message;
  //       // return Alert("error", "Error!", message);
  //     });
  // }, [params.testid, params.traineeid]);

  // useEffect(() => {
  //   let pquestions = Post({
  //     url: `${apis.FETCH_TRAINEE_TEST_QUESTION}`,
  //     data: {
  //       id: params.testid,
  //     },
  //   });

  //   Promise.all([pquestions])
  //     .then((r) => {
  //       if (r[0].data.success) {
  //         dispatch({
  //           type: "UPDATE_TRAINEE_TEST_QUESTIONS",
  //           payload: r[0].data.data,
  //           payload1: r[0].data.title,
  //         });
  //         fetchTestdata(params.testid, params.traineeid);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);

  //       // return Alert("error", "Error!", message);
  //     });
  // }, [params.testid, params.traineeid]);

  useEffect(() => {
    let pAnswere = Post({
      url: `${apis.FETCH_TRAINEE_TEST_ANSWERSHEET}`,
      data: {
        testid: params.testid,
        userid: params.traineeid,
      },
    });

    let pquestions = Post({
      url: `${apis.FETCH_TRAINEE_TEST_QUESTION}`,
      data: {
        id: params.testid,
      },
    });

    Promise.all([pAnswere, pquestions])
      .then((r) => {
        if (r[0].data.success && r[1].data.success) {
          var d = r[0]?.data?.data?.answers?.map((d, i) => {
            if (d.chosenOption.length !== 0) {
              return { ...d, isMarked: false, isAnswered: true };
            } else if (d.customAnswer !== undefined && d.customAnswer !== "") {
              return { ...d, isMarked: false, isAnswered: true };
            } else return { ...d, isMarked: false, isAnswered: false };
          });
          dispatch({
            type: "UPDATE_TRAINEE_TEST_ANSWERSHEET",
            payload: d,
          });
          dispatch({
            type: "UPDATE_TRAINEE_TEST_QUESTIONS",
            payload: r[1].data.data,
            payload1: r[1].data.title,
          });
          fetchTestdata(params.testid, params.traineeid);
          setLoading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error.response
          ? error.response.data.message
          : error.message;
        // return Alert("error", "Error!", message);
      });
  }, [params.testid, params.traineeid]);

  useEffect(() => {
    setTestDetsils(params.testid, params.traineeid);
    fetchTraineedata(params.traineeid);
  }, [
    params.testid,
    params.traineeid,
    setTestDetsils,
    fetchTraineedata,
    fetchTestdata,
  ]);

  if (props.trainee.initialloading2 || props.trainee.initialloading1) {
    return (
      <div className="skeletor-wrapper">
        <Skeleton active />
        <Skeleton active />
      </div>
    );
  } else if (props.trainee.invalidUrl) {
    return (window.location.href = ``);
  } else if (props.trainee.LocaltestDone) {
    return (
      <div>
        <Answer />
      </div>
    );
  } else if (props.trainee.testconducted) {
    return (
      <div className="Test-portal-not-started-yet-wrapper">
        <div className="Test-portal-not-started-yet-inner">
          <Title
            className="Test-portal-not-started-yet-inner-message"
            style={{ color: "black" }}
            level={4}
          >
            The Test is Over!
            <br /> You are late.
          </Title>
        </div>
      </div>
    );
  } else if (!props.trainee.testbegins) {
    return (
      <div className="Test-portal-not-started-yet-wrapper">
        <div className="Test-portal-not-started-yet-inner">
          <Title
            className="Test-portal-not-started-yet-inner-message"
            style={{ color: "#000000" }}
            level={4}
          >
            The test has not started yet. Wait for the tutor's instruction then
            refresh the page.
          </Title>
        </div>
      </div>
    );
  } else if (props.trainee.startedWriting) {
    return (
      <div>
        <TestBoard loading={true} />
      </div>
    );
  } else {
    return (
      <div>
        <Instruction />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  trainee: state.trainee,
});

export default connect(mapStateToProps, {
  fetchTraineedata,
  setTestDetsils,
  fetchTestdata,
  fetchPeanding,
  fetchTraineeTestQuestions,
  fetchTraineeTestAnswerSheet,
})(MainPortal);
