import React, { Component } from "react";
import {
  Button,
  Skeleton,
  Modal,
  Form,
  // InputNumber,
  Transfer,
  Row,
  Col,
  Select,
} from "antd";
import { connect } from "react-redux";
import {
  changeStep,
  changeMode,
  removeQuestionFromMainQueue,
  changeBasicNewTestDetails,
  fetchSubjectWiseQuestion,
  fetchLevelSubjectWiseQuestion,
  pushQuestionToQueue,
} from "../../../actions/testAction";
import "./newtest.css";
import Alert from "../../common/alert";
import apis from "../../../services/Apis";
import { Post } from "../../../services/axiosCall";
import {
  givenComponents,
  givenDifficulties,
  givenExams,
  givenLevels,
  givenSchools,
  givenYears,
} from "../../common/constants";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const GeneraterandomQuestionO = ({
  mode,
  changeMode,
  test,
  fetchSubjectWiseQuestion,
  fetchLevelSubjectWiseQuestion,
  pushQuestionToQueue,
  setNextFormData,
}) => {
  const [generating, setGenerating] = useState(false);
  const [autogenerate, setAutogenerate] = useState(true);
  const [ActiveQuestionId, setActiveQuestionId] = useState(null);
  const [Mvisible, setMVisible] = useState(false);
  const [level, setLevel] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [school, setSchool] = useState("");
  const [year, setYear] = useState("");
  const [component, setComponent] = useState("");
  const [exam, setExam] = useState("");
  const [targetKey, setTargetKey] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const prevLevelRef = useRef(null);
  const path = apis.IMGAE_BASE_URL;

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  const videoExtensions = ["mp4", "avi", "mov", "mkv"];
  useEffect(() => {
    changeMode(mode);
  }, [mode]);

  useEffect(() => {
    fetchSubjectWiseQuestion(test.newtestFormData.testSubject);
  }, []);

  console.log(test);
  useEffect(() => {
    fetchLevelSubjectWiseQuestion(
      test.newtestFormData.testSubject,
      level,
      difficulty,
      school,
      year,
      component,
      exam
    );
  }, [level, difficulty, school, year, component, exam]);

  const renderItem = (item, targetKeys) => {
    targetKeys = null;
    const customLabel = (
      <span className="custom-item">
        <br />
        <Button
          shape="circle"
          onClick={() => {
            OpenModel(item._id);
          }}
          icon="info"
          style={{
            background:
              "linear-gradient(to right,rgb(80,190,189),rgb(0,153,153),rgb(0,153,203))",
            color: "greenblue",
          }}
          size="small"
        ></Button>
        <div
          key={item._id}
          dangerouslySetInnerHTML={{ __html: item.body }}
          id="selectHtml"
        />
      </span>
    );
    return {
      label: customLabel,
      value: item._id,
    };
  };

  const OpenModel = (qid) => {
    setActiveQuestionId(qid);
    setMVisible(true);
  };

  const handleCancel = () => {
    setMVisible(false);
  };

  const handleChange = (targetKeys, direction, moveKeys) => {
    setTargetKey(targetKeys);
    setNextFormData(targetKeys);
    pushQuestionToQueue(targetKeys);
  };

  const handleSelectChange = (key) => {
    // setTargetKey(key);
    setSelectedKeys(key);
  };

  return (
    <div>
      <Row
        offset={1}
        span={9}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "15px",
        }}
      >
        <Form.Item label="Level" hasFeedback required>
          <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select Level"
            optionFilterProp="s"
            onChange={(value) => {
              setTargetKey([]);
              setSelectedKeys([]);
              setLevel(value);
            }}
          >
            {givenLevels.map((d, i) => (
              <Select.Option key={d.value} s={d.label} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Difficulty" hasFeedback required>
          <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select Difficulty"
            optionFilterProp="s"
            onChange={(value) => {
              setTargetKey([]);
              setSelectedKeys([]);
              setDifficulty(value);
            }}
          >
            {givenDifficulties.map((d, i) => (
              <Select.Option key={d.value} s={d.label} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="School" hasFeedback>
          <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select School"
            optionFilterProp="s"
            onChange={(value) => {
              setTargetKey([]);
              setSelectedKeys([]);
              setSchool(value);
            }}
          >
            {givenSchools.map((d, i) => (
              <Select.Option key={d.value} s={d.label} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Year" hasFeedback>
          <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select Year"
            optionFilterProp="s"
            onChange={(value) => {
              setTargetKey([]);
              setSelectedKeys([]);
              setYear(value);
            }}
          >
            {givenYears.map((d, i) => (
              <Select.Option key={d.value} s={d.label} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Component" hasFeedback>
          <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select Component"
            optionFilterProp="s"
            onChange={(value) => {
              setTargetKey([]);
              setSelectedKeys([]);
              setComponent(value);
            }}
          >
            {givenComponents.map((d, i) => (
              <Select.Option key={d.value} s={d.label} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Exam" hasFeedback>
          <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select Exam"
            optionFilterProp="s"
            onChange={(value) => {
              setTargetKey([]);
              setSelectedKeys([]);
              setExam(value);
            }}
          >
            {givenExams.map((d, i) => (
              <Select.Option key={d.value} s={d.label} value={d.value}>
                {d.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Row>
      <Row>
        <Col offset={2} span={22}>
          <Transfer
            filterOption={(inputValue, option) =>
              (option.difficulty ? option.difficulty.indexOf(inputValue) : -1) >
                -1 ||
              (option.level ? option.level.indexOf(inputValue) : -1) > -1 ||
              (option.school ? option.school.indexOf(inputValue) : -1) > -1 ||
              (option.year ? option.year.indexOf(inputValue) : -1) > -1 ||
              (option.component ? option.component.indexOf(inputValue) : -1) >
                -1 ||
              (option.exam ? option.exam.indexOf(inputValue) : -1) > -1 ||
              (option.body ? option.body.indexOf(inputValue) : -1) > -1 ||
              (option.topic ? option.topic.indexOf(inputValue) : -1) > -1
            }
            showSearch
            disabled={mode === "random" ? true : false}
            rowKey={(record) => record._id}
            dataSource={test.questionsAvailablebasedonSubject}
            listStyle={{ width: "45%", height: 500 }}
            targetKeys={targetKey}
            render={renderItem}
            selectedKeys={selectedKeys}
            onChange={handleChange}
            onSelectChange={handleSelectChange}
          />
        </Col>
      </Row>
      <Modal
        destroyOnClose={true}
        width="70%"
        style={{ top: "30px" }}
        title="Question details"
        visible={Mvisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
      >
        <SingleQuestionDetails qid={ActiveQuestionId} />
      </Modal>
    </div>
  );
};

function GeneraterandomQuestion(props) {
  const {
    test,
    changeStep,
    changeBasicNewTestDetails,
    fetchSubjectWiseQuestion,
    fetchLevelSubjectWiseQuestion,
    pushQuestionToQueue,
    removeQuestionFromMainQueue,
    changeMode,
    setNextFormData,
  } = props;

  return (
    <Form name="Basic Form">
      <GeneraterandomQuestionO
        test={test}
        changeStep={changeStep}
        changeBasicNewTestDetails={changeBasicNewTestDetails}
        fetchSubjectWiseQuestion={fetchSubjectWiseQuestion}
        fetchLevelSubjectWiseQuestion={fetchLevelSubjectWiseQuestion}
        pushQuestionToQueue={pushQuestionToQueue}
        removeQuestionFromMainQueue={removeQuestionFromMainQueue}
        changeMode={changeMode}
        setNextFormData={setNextFormData}
      />
    </Form>
  );
}

const mapStateToProps = (state) => ({
  test: state.test,
});

export default connect(mapStateToProps, {
  changeStep,
  changeBasicNewTestDetails,
  fetchSubjectWiseQuestion,
  fetchLevelSubjectWiseQuestion,
  pushQuestionToQueue,
  removeQuestionFromMainQueue,
  changeMode,
})(GeneraterandomQuestion);

const SingleQuestionDetails = ({ qid }) => {
  const [fetching, setFetching] = useState(false);
  const [qdetails, setQDetails] = useState(null);

  const optn = ["A", "B", "C", "D", "E"];

  useEffect(() => {
    setFetching(true);
    Post({
      url: apis.FETCH_SINGLE_QUESTION_BY_TRAINEE,
      data: { qid },
    })
      .then((response) => {
        if (response.data.success) {
          setQDetails(response.data.data[0]);
        } else {
          Alert("error", "Error !", response.data.message);
        }
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  }, [qid]);

  if (qdetails !== null) {
    return (
      <div>
        <div className="mainQuestionDetailsContaine">
          <div className="questionDetailsBody">
            <div dangerouslySetInnerHTML={{ __html: qdetails.body }} />
            {/* {qdetails.body} */}
          </div>
          {qdetails.quesimg ? (
            <div className="questionDetailsImageContainer">
              <img
                crossorigin="anonymous"
                alt="Question121"
                className="questionDetailsImage"
                src={apis.IMGAE_BASE_URL + "/" + qdetails?.quesimg}
                style={{ fontWeight: "900", fontSize: "1.4rem" }}
              />
            </div>
          ) : null}
          <div>
            {qdetails.options
              ? qdetails.options.map((d, i) => {
                  return (
                    <div key={i}>
                      <Row
                        type="flex"
                        justify="center"
                        className="QuestionDetailsOptions"
                      >
                        <Col span={2}>
                          {d.isAnswer ? (
                            <Button className="green" shape="circle">
                              {optn[i]}
                            </Button>
                          ) : (
                            <Button type="primary" shape="circle">
                              {optn[i]}
                            </Button>
                          )}
                        </Col>
                        {d.optimg ? (
                          <Col span={6} style={{ padding: "5px" }}>
                            <img
                              crossorigin="anonymous"
                              alt="options"
                              width="150"
                              height="150"
                              className="questionDetailsImage"
                              src={d.optimg}
                            />
                          </Col>
                        ) : null}
                        {d.optimg ? (
                          <Col span={14}>{d.optbody}</Col>
                        ) : (
                          <Col span={20}>{d.optbody}</Col>
                        )}
                      </Row>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="skeletor-wrapper">
        <Skeleton active />
        <Skeleton active />
      </div>
    );
  }
};
