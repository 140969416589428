import apis from "./Apis";
import {
  Get,
  Post,
  SecureGet,
  SecurePost,
  SecurePostMultipart,
} from "./axiosCall";

class AuthService {
  constructor() {
    this.token = null;
  }

  retriveToken = () => {
    // return localStorage.getItem("Token");
    // const persistToken = localStorage.getItem("persistToken") === "true";

    return sessionStorage.getItem("Token") || localStorage.getItem("Token");
  };

  storeToken = (t) => {
    const isRemembered = localStorage.getItem("isRememberMe") === "true";
    // localStorage.setItem("Token", t);
    if (isRemembered) {
      localStorage.setItem("Token", t);
      localStorage.setItem("persistToken", "true");
    } else {
      sessionStorage.setItem("Token", t);
    }
  };

  deleteToken = () => {
    localStorage.removeItem("Token");
    localStorage.removeItem("isRememberMe");
    sessionStorage.removeItem("Token");
    localStorage.removeItem("type");
  };

  LoginAuth = (u, p, type) => {
    return Post({
      url: apis.LOGIN,
      data: {
        emailid: u,
        password: p,
        type: type,
      },
    });
  };
  ResetLogin = (email, url, type) => {
    return Post({
      url: apis.FORGOT_PASSWORD,
      data: {
        email: email,
        url: url,
      },
    });
  };
  ResetPassword = (data) => {
    return Post({
      url: apis.RESET_PASSWORD,
      data: data,
    });
  };

  FetchAuth = (t, s) => {
    return Get({
      url: apis.GETDETAILSUSER,
      params: {
        Token: t,
        subject: s,
      },
    });
  };

  FetchSubjectTeacher = (t) => {
    return Get({
      url: apis.GETDETAILTEACHER,
      params: {
        Token: t,
      },
    });
  };

  FetchStaistics = (t, s) => {
    return Get({
      url: apis.GETDETAILSUSERSTATISTICS,
      params: {
        Token: t,
        subject: s,
      },
    });
  };
  GeneratePaper = (t, s, data, paper, formattedDate) => {
    return Post({
      url: apis.GENERATEPAPER,
      params: {
        Token: t,
      },
      data: {
        subject: s,
        topics: data,
        examType: paper,
        testDate: new Date(),
        title: formattedDate,
      },
    });
  };

  FetchStaisticsComponent = (t, s) => {
    return Get({
      url: apis.GETDETAILSUSERSTATISTICSCOMPONENT,
      params: {
        Token: t,
        subject: s,
      },
    });
  };

  UpdateProfile = (t, data) => {
    return SecurePost({
      url: apis.UPDATEPROFILE,
      data: data,
      params: {
        Token: t,
      },
    });
  };

  UploadQuestionForConsulatation = (t, data) => {
    return SecurePostMultipart({
      url: apis.CONSULTATION_QUESTION,
      data: data,
      params: {
        Token: t,
      },
    });
  };

  GetQuestionForConsulatation = (t) => {
    return SecureGet({
      url: apis.GET_CONSULTATION_QUESTION,
      params: {
        Token: t,
      },
    });
  };

  GetAllCategory = (t) => {
    return SecureGet({
      url: apis.GET_CATEGORY,
      params: {
        Token: t,
      },
    });
  };

  GetAllReward = (t) => {
    return SecureGet({
      url: apis.GET_REWARD,
      params: {
        Token: t,
      },
    });
  };

  GetAllActiveOrders = (t) => {
    return SecureGet({
      url: apis.GET_ACTIVE_ORDER,
      params: {
        Token: t,
      },
    });
  };

  GetAllPastOrders = (t) => {
    return SecureGet({
      url: apis.GET_PAST_ORDER,
      params: {
        Token: t,
      },
    });
  };

  GetAllRewardCategory = (t, id) => {
    return SecureGet({
      url: `${apis.GET_REWARD_CATEGORY}/${id} `,
      params: {
        Token: t,
      },
    });
  };
}

export default new AuthService();
