import React from "react";
import DashboardWrapper from "../shared/DashboardWrapper";
import { Divider, Tabs, Typography } from "antd";
import DiamondIcon from "../../../assets/diamond.svg";
import "./index.css";
import CategoreyRewards from "./CategoreyRewards";
import { connect } from "react-redux";
import { GetCategory } from "../../../actions/GetCategory";
import { GetRewards } from "../../../actions/GetRewards";
import { useEffect } from "react";
import AuthServices from "../../../services/AuthServices";
import { useState } from "react";

const ClaimReward = (props) => {
  const { TabPane } = Tabs;

  const { category } = props;
  const item = category && category?.length > 0 && category[0];

  const [tabKey, setTabKey] = useState(item?._id);

  useEffect(() => {
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined") {
      AuthServices.GetAllCategory(t).then((response) => {
        props.GetCategory(response?.data?.data);
        setTabKey(
          response?.data?.data &&
            response?.data?.data.length > 0 &&
            response?.data?.data[0]._id
        );
      });
    } else {
      window.location = "/";
    }
  }, []);

  return (
    <DashboardWrapper isReward={true} title="CLAIM REWARDS">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "20px",
            // alignItems: "center",
            gap: "2px",
          }}
        >
          <div style={{ display: "flex", gap: "2px" }}>
            <img width="20px" src={DiamondIcon} alt="" />
            <Typography className="rewardPoints">
              {props.user?.userDetails?.points?.toLocaleString()}
            </Typography>
          </div>
          <Typography className="pointsText">Points Accumulated</Typography>
        </div>
        <Divider className="divier_edit" />
        <div className="reward_tabs">
          {category && category?.length > 0 && (
            <Tabs
              tabBarStyle={{
                borderBottom: "none",
              }}
              onTabClick={(e) => setTabKey(e)}
            >
              {category?.map((item) => (
                <TabPane tab={item.name} key={item._id}>
                  <CategoreyRewards categoryId={tabKey} />
                </TabPane>
              ))}
            </Tabs>
          )}
        </div>
      </div>
    </DashboardWrapper>
  );
};

const mapStateToProps = (state) => ({
  category: state.category.category,
  rewards: state.rewards.rewards,
  user: state.user,
});

export default connect(mapStateToProps, {
  GetCategory,
  GetRewards,
})(ClaimReward);
