import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Input, Button, Typography, Tabs, Icon } from "antd";
import "./conducttes.css";
import {
  changeConducttestId,
  updateCandidatesTest,
  updateQuestiosnTest,
} from "../../../actions/conductTest";
import TestDetails from "./details";
import Candidates from "./candidates";
import Questions from "./questions";
import { useHistory } from "react-router-dom";

const { Title } = Typography;
const { TabPane } = Tabs;

function ConductTestS(props) {
  const [localTestId, setLocalTestId] = useState(null);
  const [needRedirect, setNeedRedirect] = useState(false);
  const history = useHistory();

  useEffect(() => {
    props.changeConducttestId(props.testid);
  }, [props.changeConducttestId, props.testid]);

  const ChangeLocalTestId = (d) => {
    setLocalTestId(d.target.value);
  };

  const ChangetestId = () => {
    setNeedRedirect(true);
  };

  if (needRedirect) {
    history.push(`/user/conducttest?testid=${localTestId}`);
    // return (window.location.href = `/user/conducttest?testid=${localTestId}`);
  }

  return (
    <div className="conduct-test-main-wrapper">
      {!props.conduct.id ? (
        <div>
          <Title style={{ width: "100%", textAlign: "center" }} level={4}>
            Enter Test Id
          </Title>
          <div className="test-conduct-testid-form">
            <Input
              placeholder="Enter test id"
              onChange={ChangeLocalTestId}
              value={localTestId}
            />
            <Button
              onClick={ChangetestId}
              type="primary"
              style={{ marginTop: "30px" }}
            >
              Proceed
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {props.conduct.basictestdetails.testconducted ? (
            <div className="reasendmail-container-register">
              <Title
                style={{
                  color: "#000000",
                  textAlign: "center",
                  marginTop: "50px",
                }}
                level={4}
              >
                The Test has ended! Go to all tests to check the results
              </Title>
            </div>
          ) : (
            <div>
              <TestDetails />
              <Tabs defaultActiveKey="1" style={{ marginTop: "20px" }}>
                <TabPane
                  tab={
                    <span>
                      <Icon type="user" />
                      Registered Trainee
                    </span>
                  }
                  key="1"
                >
                  <Candidates />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <Icon type="question-circle" />
                      Questions
                    </span>
                  }
                  key="2"
                >
                  <Questions
                    id={props.conduct.id}
                    questionsOfTest={props.conduct.questionsOfTest}
                    updateQuestiosnTest={props.updateQuestiosnTest}
                  />
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function C(props) {
  if (props.conduct.basictestdetails.testconducted) {
    return (
      <div className="reasendmail-container-register">
        <Title
          style={{ color: "#000000", textAlign: "center", marginTop: "50px" }}
          level={4}
        >
          The Test has ended! Go to all tests to check the results
        </Title>
      </div>
    );
  } else {
    return (
      <div>
        <TestDetails />
        <Tabs defaultActiveKey="1" style={{ marginTop: "20px" }}>
          <TabPane
            tab={
              <span>
                <Icon type="user" />
                Registered Trainee
              </span>
            }
            key="1"
          >
            <Candidates />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="question-circle" />
                Questions
              </span>
            }
            key="2"
          >
            <Questions
              id={props.conduct.id}
              questionsOfTest={props.conduct.questionsOfTest}
              updateQuestiosnTest={props.updateQuestiosnTest}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  trainer: state.trainer,
  conduct: state.conduct,
});

const CC = connect(mapStateToProps, {
  changeConducttestId,
  updateCandidatesTest,
  updateQuestiosnTest,
})(C);

export default connect(mapStateToProps, {
  changeConducttestId,
  updateCandidatesTest,
  updateQuestiosnTest,
})(ConductTestS);


