import auth from "./AuthServices";
import apis from "./Apis";
const axios = require("axios");

const axiosInstance = axios.create({
  baseURL: apis.BASE,
  headers: {
    common: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, DELETE",
      "Access-Control-Allow-Headers": "Authorization, Content-Type",
      "Access-Control-Allow-Credentials": true,
    },
  },
});

const axiosInstanceMultipart = axios.create({
  baseURL: apis.BASE,
  headers: {
    "Content-type": "multipart/form-data",
    common: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, DELETE",
      "Access-Control-Allow-Headers": "Authorization, Content-Type",
      "Access-Control-Allow-Credentials": true,
    },
  },
});

export const SecureGet = (p) => {
  return axiosInstance({
    method: "get",
    ...p,
    params: {
      ...p.params,
      Token: auth.retriveToken(),
    },
  });
};

export const Get = (p) => {
  return axiosInstance({
    method: "get",
    ...p,
  });
};

export const SecurePostMultipart = (p) => {
  return axiosInstanceMultipart({
    method: "post",
    ...p,
    params: {
      ...p.params,
      Token: auth.retriveToken(),
    },
  });
};

export const SecurePost = (p) => {
  return axiosInstance({
    method: "post",
    ...p,
    params: {
      ...p.params,
      Token: auth.retriveToken(),
    },
  });
};

export const Post = (p) => {
  return axiosInstance({
    method: "post",
    ...p,
  });
};

export const SecureDelete = (p) => {
  return axiosInstance({
    method: "delete",
    ...p,
    params: {
      ...p.params,
      Token: auth.retriveToken(),
    },
  });
};
