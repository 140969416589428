import { Col, Divider, Progress, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import auth from "../../../../services/AuthServices";
import { connect } from "react-redux";
import { login } from "../../../../actions/loginAction";
import Trophy from "../../../../assets/trophy.svg";
import InfoIcon from "../../../../assets/infoIcon.svg";

// import { Line } from "react-chartjs-2";

import { faker } from "@faker-js/faker";
import { useRef } from "react";
import {
  Bar,
  BarChart,
  CartesianAxis,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getGrade, getGradeSecondary } from "../../utils";
import { useWindowDimensions } from "../../examPortal/testBoard";

function truncateString(str, maxLength) {
  if (str?.length > maxLength) {
    return str?.slice(0, maxLength) + "...";
  }
  return str;
}
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "white", padding: 10 }}>
        {payload.map((entry, index) => (
          <div style={{ display: "flex", color: "white" }}>
            <Typography>{truncateString(entry.payload.label, 20)} </Typography>
            <Typography>: {entry.payload.value}%</Typography>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const progressStyleCol = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
};

// Chart.register(ChartAnnotation);

const today = new Date();
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const last5Months = [];

for (let i = 4; i >= 0; i--) {
  const currentMonth = (today.getMonth() - i + 12) % 12; // Ensure the month is in the range 0-11
  const monthObject = { month: months[currentMonth] };
  last5Months.push(monthObject);
}

const SubjectDetails = (props) => {
  const { title, id } = props;

  const {
    // height,
    width,
  } = useWindowDimensions();

  const startingTime = new Date(
    props?.user?.userDetails?.countDownStartingTime
  );

  const [info, setInfo] = useState(false);
  const [infoPractical, setInfoPractical] = useState(false);

  const [testData, setTestData] = useState([]);

  const endingTime = new Date(props?.user?.userDetails?.countDownTimer);

  const timeDifference = endingTime - startingTime;
  const currentTime = new Date();

  const timeElapsed = currentTime - startingTime;

  const daysElapsed = Math.ceil(timeElapsed / (1000 * 60 * 60 * 24));
  const totalDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  const percentageDayLeft = (daysElapsed / totalDays) * 100;

  let keys = [];
  let arr = props?.user?.userDetails?.lat5TestsScorePercentage?.map(
    (item, i) => i
  );
  arr?.length !== 0 &&
    arr?.length !== undefined &&
    arr.forEach((element, index) => {
      const dt = Object.keys(
        props?.user?.userDetails?.lat5TestsScorePercentage[index]
      );
      keys.push(dt[0]);
    });

  useEffect(() => {
    const t = auth.retriveToken();
    if (t && t !== "undefined" && title) {
      auth.FetchAuth(t, title).then((response) => {
        props.login(response.data.user);
      });
    } else {
      window.location = "/";
    }
  }, [title]);

  const data = {
    labels: last5Months.map((item) => item.month),
    datasets: [
      {
        // label: "Dataset 2",
        data: props?.user?.userDetails?.last5MonthAverage,
        borderColor: "#18A0FB",
        backgroundColor: "transparent",
        color: "white",
      },
    ],
  };

  return (
    <div className="container">
      <div className="stack">
        <Typography className="name">
          Welcome back, {props?.user?.userDetails?.name}
        </Typography>
      </div>
      <div className="stateContainer">
        <div style={{ width: "30%", margin: "auto" }}>
          <Divider orientation="left" />
        </div>{" "}
        {/* <Typography className="subjectFont">Overall statistics:</Typography> */}
        <Row style={{ width: "80%", margin: "auto" }} gutter={16}>
          <Col
            style={progressStyleCol}
            className="gutter-row"
            md={8}
            xs={12}
            span={8}
          >
            <div
              className={
                props?.user?.userDetails?.averageOverall
                  ? "custom-progress"
                  : "custom-progress curcularProgressEmptyFill"
              }
            >
              <Progress
                type="circle"
                percent={
                  props?.user?.userDetails?.averageOverall
                    ? parseInt(props?.user?.userDetails?.averageOverall)
                    : 0
                }
                strokeColor={
                  props?.user?.userDetails?.averageOverall <=
                    props?.user?.userDetails[id] &&
                  props?.user?.userDetails?.averageOverall > 45
                    ? "#FAFF1C"
                    : props?.user?.userDetails?.averageOverall >=
                        props?.user?.userDetails[id] &&
                      props?.user?.userDetails?.averageOverall > 45
                    ? "#67FF0B"
                    : props?.user?.userDetails?.averageOverall < 45
                    ? "red"
                    : "red"
                } // Change the color of the progress
                strokeWidth={10} // Change the width of the progress
                trailColor="#B5B5B5" // Change the color of the trail
                trailWidth={10}
                strokeStyle={{
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow to the progress
                }}
                format={() => (
                  <span className="progress-text">
                    {props?.user?.userDetails?.averageOverall === null
                      ? "N/A"
                      : props?.user?.userDetails?.averageOverall + "%"}
                  </span>
                )}
              />
            </div>

            <Typography className="progressLabel">
              MY AVERAGE
              {/* {props?.user?.userDetails?.averageOverall === null
                ? "NO DATA"
                : "MY AVERAGE"} */}
            </Typography>
          </Col>
          <Col
            style={progressStyleCol}
            className="gutter-row"
            md={8}
            xs={12}
            span={8}
          >
            {/* <div
              className={
                props?.user?.userDetails[id]
                  ? "custom-progress"
                  : "custom-progress curcularProgressEmptyFill"
              }
            >
              <Progress
                type="circle"
                percent={
                  props?.user?.userDetails[id]
                    ? parseInt(props?.user?.userDetails[id])
                    : 0
                }
                strokeColor="#FAFF1C" // Change the color of the progress
                strokeWidth={10} // Change the width of the progress
                trailColor="#B5B5B5" // Change the color of the trail
                trailWidth={10} // Change the width of the trail
                strokeStyle={{
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow to the progress
                }}
                format={() => (
                  <span className="progress-text">
                    {props?.user?.userDetails[id]
                      ? props?.user?.userDetails[id] + "%"
                      : "N/A"}
                  </span>
                )}
              />
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <img width={"70%"} src={Trophy} alt="" />
              <Typography
                className="trophyText"
                style={{
                  position: "absolute",
                  top: "20%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                {props?.user?.userDetails[id] >= 0
                  ? props?.user?.userDetails[id] + "%"
                  : "N/A"}
                <br />
                {props?.user?.userDetails?.level?.startsWith("primary")
                  ? getGrade(props?.user?.userDetails[id])
                  : getGradeSecondary(props?.user?.userDetails[id])}
              </Typography>
            </div>

            <Typography className="progressLabel">MY GOAL</Typography>
          </Col>

          <Col
            style={progressStyleCol}
            className="gutter-row"
            md={8}
            xs={12}
            span={8}
          >
            <div
              className={
                props?.user?.userDetails?.averageOverallAllWebsite
                  ? "custom-progress"
                  : "custom-progress curcularProgressEmptyFill"
              }
            >
              <Progress
                type="circle"
                percent={
                  props?.user?.userDetails?.averageOverallAllWebsite
                    ? parseInt(
                        props?.user?.userDetails?.averageOverallAllWebsite
                      )
                    : 0
                }
                strokeColor="#18A0FB"
                strokeWidth={10} // Change the width of the progress
                trailColor="#B5B5B5" // Change the color of the trail
                trailWidth={10}
                strokeStyle={{
                  boxShadow: "0px 4px 4px 0px #00000040", // Add a box shadow to the progress
                }}
                format={() => (
                  <span className="progress-text">
                    {props?.user?.userDetails?.averageOverallAllWebsite === null
                      ? "N/A"
                      : props?.user?.userDetails?.averageOverallAllWebsite +
                        "%"}
                  </span>
                )}
              />
            </div>

            <Typography className="progressLabel">
              PEER’S AVERAGE
              {/* {props?.user?.userDetails?.averageOverallAllWebsite === null
                ? "NO DATA"
                : "WEBSITE AVERAGE"} */}
            </Typography>
          </Col>
        </Row>
        <div style={{ width: "80%", margin: "auto" }}>
          <Divider orientation="left" />
        </div>{" "}
        <Row
          gutter={[16, 16]}
          // style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          <Col
            style={{ ...progressStyleCol, height: "100%" }}
            className="gutter-row"
            md={10}
            xs={12}
            span={8}
          >
            <div className="wrapperBox">
              <Typography className="titleBox">
                AVERAGE MARKS (%) PER MONTH
              </Typography>
              <div
                style={{
                  // backgroundColor: "black",
                  height: "154px",
                }}
                className="Chart"
              >
                {/* <Line height={130} data={data} options={options} /> */}
                <div
                  style={{
                    width: "100%",
                    height: "160px",

                    // marginLeft: "-60px",
                    // marginBottom: "-30px",
                  }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      margin={{
                        top: 10,
                        right: 35,
                        left: -27,
                        // bottom: 5,
                      }}
                      data={props?.user?.userDetails?.last5MonthAverage}
                    >
                      <XAxis
                        dataKey="month"
                        tick={{ fill: "white" }} // Set label text color to white
                        interval={0}
                        style={{
                          fontSize: width > 1390 ? "12x" : "6px",
                        }}
                      />

                      <YAxis
                        tick={{
                          fill: "white",
                          fontSize: width > 1390 ? 12 : 6,
                          fontFamily: "Inter",
                        }}
                        domain={[0, 100]} // Set the desired Y-axis range
                        interval={0}
                      />
                      <Tooltip />
                      {/* <Legend /> */}
                      <CartesianGrid stroke="#7E7E7E" vertical={true} />
                      <ReferenceLine y={0} stroke="white" />
                      <ReferenceLine
                        y={props?.user?.userDetails[id]}
                        stroke="white"
                        strokeDasharray="9 9"
                        strokeWidth={2}
                        ifOverflow="extendDomain"
                      />

                      <Line dataKey="value" strokeWidth={3} stroke="#18A0FB" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "baseline",
                    justifyContent: "flex-end",
                    gap: "6px",
                    position: "absolute",
                    // top: "18.5%",
                    bottom: `${
                      props?.user?.userDetails[id]
                        ? props?.user?.userDetails[id] * 0.79 + "%"
                        : "0%"
                    }`,
                    // bottom: "79%",
                    width: "100%",
                    right: -10,
                    zIndex: 9999,
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: "white",
                    }}
                  >
                    Goal
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col
            style={{ ...progressStyleCol, height: "100%" }}
            className="gutter-row"
            md={8}
            xs={12}
            span={8}
          >
            <div className="wrapperBox1">
              <Typography className="titleBox">
                MARKS FROM PAST 5 PAPERS
              </Typography>

              <div
                style={{
                  height: "157px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="Chart"
              >
                <div
                  style={{
                    width: "100%",
                    height: "160px",
                    // backgroundColor: "black",
                    marginLeft: "-60px",
                    marginBottom: "-30px",
                  }}
                >
                  <ResponsiveContainer width={"100%"} height={"100%"}>
                    <BarChart
                      margin={{
                        top: 10,
                      }}
                      data={props?.user?.userDetails?.lat5TestsScorePercentage}
                    >
                      <XAxis
                        dataKey="name"
                        tick={{ fill: "white" }} // Set label text color to white
                      />
                      <YAxis
                        tick={{
                          fill: "white",
                          fontSize: width > 1390 ? 12 : 6,
                          fontFamily: "Inter",
                        }}
                        domain={[0, 100]} // Set the desired Y-axis range
                        interval={0}
                      />
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ fill: "transparent" }}
                      />
                      {/* <Legend /> */}
                      <CartesianGrid
                        stroke="#7E7E7E" // Set grid line color to white
                        vertical={false} // Remove vertical grid lines if needed
                      />
                      <ReferenceLine y={0} stroke="white" />
                      <ReferenceLine
                        y={props?.user?.userDetails[id]}
                        stroke="white"
                        strokeDasharray="9 9"
                        strokeWidth={2}
                      />

                      {/* {keys.map((key, index) => (
                        <Bar key={index} dataKey={key} shape={<CustomBar />} />
                      ))} */}

                      <Bar
                        dataKey="value"
                        fill="#18A0FB"
                        radius={[10, 10, 0, 0]}
                        barSize={40}
                        // shape={<CustomBar />}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div
                  style={{
                    display: "flex",
                    // alignItems: "baseline",
                    justifyContent: "flex-end",
                    gap: "6px",
                    position: "absolute",
                    // // top: "40%",
                    bottom: `${
                      props?.user?.userDetails[id]
                        ? props?.user?.userDetails[id] * 0.7 + "%"
                        : "0%"
                    }`,
                    // bottom: "70%",
                    width: "100%",
                    right: 0,
                    zIndex: 9999,
                  }}
                >
                  {/* <div
                    style={{
                      borderTop: "2px dashed white",
                      backgroundColor: "transparent",
                      width: "100%",
                      // borderStyle: "dashed",
                      zIndex: 99,
                    }}
                  ></div> */}
                  <p
                    style={{
                      fontSize: "14px",
                      color: "white",
                    }}
                  >
                    Goal
                  </p>
                </div>
              </div>
            </div>
          </Col>

          <Col
            style={progressStyleCol}
            className="gutter-row"
            md={6}
            xs={24}
            span={8}
          >
            <div className="wrapperBox2">
              <Typography className="titleBox">WEEKLY STATISTICS</Typography>
              <div className="weeklyStateStack"></div>
              <Typography className="weeklystateText">
                <span style={{ textDecoration: "underline" }}>
                  Papers Completed
                </span>
                <br />
                {props?.user?.userDetails?.papersCompleted >= 0
                  ? props?.user?.userDetails?.papersCompleted
                  : "N/A"}
              </Typography>
              <Typography className="weeklystateText">
                <span style={{ textDecoration: "underline" }}>
                  {" "}
                  Hours Spent
                </span>
                <br />
                {props?.user?.userDetails?.hoursSpend
                  ? props?.user?.userDetails?.hoursSpend
                  : "N/A"}{" "}
              </Typography>

              <Typography className="weeklystateText">
                <span style={{ textDecoration: "underline" }}>
                  Average Percentage
                </span>
                <br />
                {props?.user?.userDetails?.average === null
                  ? "N/A"
                  : props?.user?.userDetails?.average + "%"}
              </Typography>
            </div>
          </Col>
        </Row>
      </div>
      <div className="stateFooter">
        <Row
          style={{
            border: "2px solid #F1F1F1",
            borderRadius: "8px",
          }}
          gutter={16}
        >
          <Col
            style={{
              borderRight: "1px solid #F1F1F1",
            }}
            className="gutter-row"
            span={12}
            xs={24}
            lg={12}
          >
            <div className="footerInner">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  cursor: "pointer",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <Typography className="footerInnerTitle">
                  PRACTICE GOAL
                </Typography>
                <div className="hoverInfoBox">
                  <img className="infoIcon" src={InfoIcon} alt="" />
                  <div className="practicalInfo">
                    <Typography className="infoText">
                      The practice goal is a set amount of papers you should be
                      complete to increase your chances of reaching your
                      academic goal. This value is determined by the system.
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="space-between">
                <Typography className="progressFooterLabel">
                  Papers Completed:{" "}
                  {props?.user?.userDetails?.completedTest
                    ? props?.user?.userDetails?.completedTest
                    : "N/A"}
                </Typography>
                <Typography className="progressFooterLabel">
                  Goal:{" "}
                  {props?.user?.userDetails.goalPapers
                    ? props?.user?.userDetails.goalPapers
                    : "N/A"}
                </Typography>
              </div>
              <div className="progress-wrapper">
                <Progress
                  strokeColor="linear-gradient(180deg, #C6C6C6 0%, #FFF 100%)"
                  strokeWidth={20}
                  trailColor="#9B9B9B !important"
                  trailWidth={10}
                  percent={
                    props?.user?.userDetails.goalPapers
                      ? parseInt(
                          (props?.user?.userDetails.completedTest /
                            props?.user?.userDetails.goalPapers) *
                            100
                        )
                      : 0
                  }
                  showInfo={false}
                />
                {/* <div className="progressInfo">
                  <div className="progressInfo">
                    <Typography className="infoText">
                      {props?.user?.userDetails[id] && title ? (
                        <>
                          {`${title} papers completed: ${props?.user?.userDetails?.completedTest}`}
                          <br />
                          {`${title} papers to have been completed by today: ${props?.user?.userDetails?.todayCompletedTest}`}
                        </>
                      ) : (
                        <></>
                      )}
                      <br />
                      goal:{" "}
                      {props?.user?.userDetails[id]
                        ? parseInt(props?.user?.userDetails[id])
                        : "N/A"}
                    </Typography>
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12} lg={12} xs={24}>
            <div className="footerInner">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  cursor: "pointer",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <Typography className="footerInnerTitle">COUNTDOWN</Typography>
                <div className="hoverInfoBoxCountDown">
                  <img className="infoIcon" src={InfoIcon} alt="" />
                  <div
                    className="countDownInfo"
                    onMouseLeave={() => setInfo(false)}
                  >
                    <Typography className="infoText">
                      The countdown is based on the date you set in ‘Edit
                      Profile’. The start date resets every time you change the
                      date.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="space-between">
                <Typography className="progressFooterLabel">
                  Days Left:
                  {props?.user?.userDetails?.countDownTimer
                    ? Math.ceil(
                        (new Date(props?.user?.userDetails?.countDownTimer) -
                          new Date()) /
                          (1000 * 60 * 60 * 24)
                      ) > 0
                      ? Math.ceil(
                          (new Date(props?.user?.userDetails?.countDownTimer) -
                            new Date()) /
                            (1000 * 60 * 60 * 24)
                        )
                      : "Set New CountDown"
                    : "N/A"}
                </Typography>
                <Typography className="progressFooterLabel">
                  {props?.user?.userDetails?.countDownTimer
                    ? new Date(
                        props?.user?.userDetails?.countDownTimer
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : "N/A"}
                </Typography>
              </div>

              <div className="progress-wrapper">
                <Progress
                  strokeColor="linear-gradient(180deg, #C6C6C6 0%, #FFF 100%)"
                  strokeWidth={20}
                  trailColor="#9B9B9B !important"
                  trailWidth={10}
                  percent={percentageDayLeft}
                  showInfo={false}
                />
                <div className="progressInfo">
                  <Typography className="infoText">
                    Days left:{" "}
                    {props?.user?.userDetails?.countDownTimer
                      ? Math.ceil(
                          (new Date(props?.user?.userDetails?.countDownTimer) -
                            new Date()) /
                            (1000 * 60 * 60 * 24)
                        ) > 0
                        ? Math.ceil(
                            (new Date(
                              props?.user?.userDetails?.countDownTimer
                            ) -
                              new Date()) /
                              (1000 * 60 * 60 * 24)
                          )
                        : "Set New CountDown"
                      : "N/A"}
                    <br />
                    Countdown set to:{" "}
                    {props?.user?.userDetails?.countDownTimer
                      ? new Date(
                          props?.user?.userDetails?.countDownTimer
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : "N/A"}
                    <br /> Notes on countdown:{" "}
                    {props?.user?.userDetails?.notesOnCountDown}
                  </Typography>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  login,
})(SubjectDetails);
