import React from "react";
import "../../components/basic/homepage/homepage.css";
import "../../components/basic/homepage/homepage.jpg";
import { connect } from "react-redux";
import auth from "../../services/AuthServices";
import { Redirect } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";

function Forgot(props) {
  if (auth.retriveToken() && auth.retriveToken() !== "undefined") {
    return <Redirect to="/user/home" />;
  } else {
    return (
      <div className="home-page-container">
        <div className="parallax">
          {/* <HomepageHeader /> */}
          <ForgotPassword />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(Forgot);
