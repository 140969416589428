export const givenDifficulties = [
  { label: "Basic", value: "basic" },
  { label: "Moderate", value: "moderate" },
  { label: "Challenging", value: "challenging" },
];

export const givenLevels = [
  { label: "Primary 1", value: "primary 1" },
  { label: "Primary 2", value: "primary 2" },
  { label: "Primary 3", value: "primary 3" },
  { label: "Primary 4", value: "primary 4" },
  { label: "Primary 5", value: "primary 5" },
  { label: "Primary 6", value: "primary 6" },
  { label: "Secondary 1", value: "secondary 1" },
  { label: "Secondary 2", value: "secondary 2" },
  { label: "Secondary 3", value: "secondary 3" },
  { label: "Secondary 4", value: "secondary 4" },
  { label: "Secondary 5", value: "secondary 5" },
];

export const terms = [
  { label: "Term 1", value: "Term 1" },
  { label: "Term 2", value: "Term 2" },
  { label: "Term 3", value: "Term 3" },
  { label: "Term 4", value: "Term 4" },
];

export const givenSchools = [
  { label: "Raffles Girls", value: "Raffles Girls" },
  { label: "Nanyang Pri", value: "Nanyang Pri" },
  { label: "Nan Hua Pri", value: "Nan Hua Pri" },
  { label: "Chu Pri", value: "Chu Pri" },
  { label: "Tao Nan Pri", value: "Tao Nan Pri" },
  { label: "MGS Pri", value: "MGS Pri" },
  { label: "Maha Bodhi", value: "Maha Bodhi" },
  { label: "Catholic High Pri", value: "Catholic High Pri" },
  { label: "ACS (J) Pri", value: "ACS (J) Pri" },
  { label: "Red Swastika Pri", value: "Red Swastika Pri" },
  { label: "Maris Stella Pri", value: "Maris Stella Pri" },
  { label: "Rosyth", value: "Rosyth" },
  { label: "Henry Park", value: "Henry Park" },
  { label: "SCGS", value: "SCGS" },
  { label: "MGS (PL)", value: "MGS (PL)" },
  { label: "Nan Chiau", value: "Nan Chiau" },
  { label: "St Nicholas", value: "St Nicholas" },
];

export const givenYears = [
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
  { label: "2022", value: "2022" },
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
];

export const givenComponentsOthers = [
  { label: "Booklet A", value: "Paper 1 Booklet A" },
  { label: "Booklet B", value: "Paper 1 Booklet B" },
];
export const givenComponents = [
  { label: "Booklet A", value: "Paper 1 Booklet A" },
  { label: "Booklet B", value: "Paper 1 Booklet B" },
  { label: "Paper 2", value: "Paper 2" },
  { label: "Paper 1", value: "Paper 1" },
];
export const givenComponentMath = [
  { label: "Paper 2", value: "Paper 2" },
  { label: "Paper 1", value: "Paper 1" },
];

export const givenExams = [
  { label: "CA1", value: "CA1" },
  { label: "CA2", value: "CA2" },
  { label: "SA1", value: "SA1" },
  { label: "SA2", value: "SA2" },
];

export const paper = [
  { label: "Paper 1", value: "Paper 1" },
  { label: "Paper 2", value: "Paper 2" },
];
export const paper1 = [
  { label: "Booklet A", value: "Booklet A" },
  { label: "Booklet B", value: "Booklet B" },
];

// export const givenMathsTopics = [
//   { label: "Whole Numbers", value: "Whole Numbers" },
//   { label: "Fractions", value: "Fractions" },
//   { label: "Ratio", value: "Ratio" },
//   { label: "Area of Triangle", value: "Area of Triangle" },
//   { label: "Volume", value: "Volume" },
//   { label: "Decimals", value: "Decimals" },
//   { label: "Average", value: "Average" },
//   { label: "Percentage", value: "Percentage" },
//   { label: "Angles", value: "Angles" },
//   { label: "Rates", value: "Rates" },
//   { label: "Algebra", value: "Algebra" },
//   { label: "Circles", value: "Circles" },
//   { label: "3D Figures", value: "3D Figures" },
// ];

export const givenMathsTopics = [
  { label: "Numbers up to 100 000", value: "Numbers up to 100 000" },
  {
    label: "Measurement (FYI: Length, Mass)",
    value: "Measurement (FYI: Length, Mass)",
  },
  {
    label: "Mixed Numbers and Improper Fractions",
    value: "Mixed Numbers and Improper Fractions",
  },
  {
    label: "Fractions as a set of objects",
    value: "Fractions as a set of objects",
  },
  { label: "Decimals up to 3 places", value: "Decimals up to 3 places" },
  { label: "Area and Perimeter", value: "Area and Perimeter" },
  { label: "Rectangle and Square", value: "Rectangle and Square" },
  { label: "Line Symmetry", value: "Line Symmetry" },
  { label: "Tables and Line Graphs", value: "Tables and Line Graphs" },
  { label: "Numbers up to 10 million", value: "Numbers up to 10 million" },
  { label: "Fraction and Division", value: "Fraction and Division" },
  { label: "Addition and Subtraction", value: "Addition and Subtraction" },
  { label: "Money", value: "Money" },
  { label: "Time", value: "Time" },
  { label: "Distance, Time and Speed", value: "Distance, Time and Speed" },
  {
    label: "Area and Circumference of a Circle",
    value: "Area and Circumference of a Circle",
  },
  { label: "Special Quadrilaterals", value: "Special Quadrilaterals" },
  { label: "Geometry Nets", value: "Geometry Nets" },
  { label: "Equivalent Fractions", value: "Equivalent Fractions" },
  {
    label: "Perpendicular and Parallel Lines",
    value: "Perpendicular and Parallel Lines",
  },
  { label: "Pie Chart", value: "Pie Chart" },
  { label: "2D Figures", value: "2D Figures" },
  { label: "Triangle", value: "Triangle" },
  {
    label: "Parallelogram, Rhombus, Trapezoid",
    value: "Parallelogram, Rhombus, Trapezoid",
  },
  {
    label: "Multiplication and Division",
    value: "Multiplication and Division",
  },
  { label: "Factors and Multiples", value: "Factors and Multiples" },
  { label: "Patterns", value: "Patterns" },
  { label: "Lower Primary", value: "Lower Primary" },
  { label: "Whole Numbers", value: "Whole Numbers" },
  { label: "Volume (FYI: ml)", value: "Volume (FYI: ml)" },
  { label: "Area & Perimeter", value: "Area & Perimeter" },
  { label: "Angles", value: "Angles" },
  { label: "Lines & Symmetry", value: "Lines & Symmetry" },
  { label: "Graphs", value: "Graphs" },
  {
    label: "Whole Numbers & Four Operations",
    value: "Whole Numbers & Four Operations",
  },
  { label: "Decimals", value: "Decimals" },
  { label: "Ratio", value: "Ratio" },
  { label: "Rate", value: "Rate" },
  { label: "Triangles", value: "Triangles" },
  { label: "Average", value: "Average" },
  { label: "Algebra", value: "Algebra" },
  { label: "Fractions", value: "Fractions" },
  { label: "Percentage", value: "Percentage" },
  { label: "Quadrilaterals & Angles", value: "Quadrilaterals & Angles" },
  { label: "Circles", value: "Circles" },
  { label: "Volume of Cube & Cuboid", value: "Volume of Cube & Cuboid" },
  { label: "2D, 3D Figures & Nets", value: "2D, 3D Figures & Nets" },
  { label: "Speed", value: "Speed" },
  { label: "Graphs & Pie Charts", value: "Graphs & Pie Charts" },
  { label: "Heuristics", value: "Heuristics" },
  { label: "Critical Thinking", value: "Critical Thinking" },
];

export const givenEnglishTopics = [
  { label: "Grammar", value: "Grammar" },
  { label: "Vocabulary", value: "Vocabulary" },
  {
    label: "Vocabulary Cloze",
    value: "Vocabulary Cloze",
  },
  {
    label: "Visual Text Comprehention",
    value: "Visual Text Comprehention",
  },
  { label: "Grammar Cloze", value: "Grammar Cloze" },
  {
    label: "Editing for Spelling and Grammar",
    value: "Editing for Spelling and Grammar",
  },
  {
    label: "Comprehension Cloze",
    value: "Comprehension Cloze",
  },
  {
    label: "Synthesis/Transformation",
    value: "Synthesis/Transformation",
  },
  {
    label: "Comprehension Open Ended",
    value: "Comprehension Open Ended",
  },
];

export const givenScienceTopics = [
  { label: "Classifying Things", value: "Classifying Things" },
  {
    label: "Living and Non-Living Things",
    value: "Living and Non-Living Things",
  },
  { label: "Plants", value: "Plants" },
  { label: "Animals", value: "Animals" },
  { label: "Fungi and Bacteria", value: "Fungi and Bacteria" },
  { label: "Exploring Materials", value: "Exploring Materials" },
  { label: "Life Cycles", value: "Life Cycles" },
  {
    label: "Matter and Its Three States",
    value: "Matter and Its Three States",
  },
  { label: "From Parents to Young", value: "From Parents to Young" },
  { label: "Reproduction in Plants", value: "Reproduction in Plants" },
  { label: "Reproduction in Humans", value: "Reproduction in Humans" },
  { label: "Water and changes in State", value: "Water and changes in State" },
  { label: "The Water Cycle", value: "The Water Cycle" },
  { label: "The Human Body As A System", value: "The Human Body As A System" },
  { label: "The Digestive System", value: "The Digestive System" },
  { label: "Plants and Their Parts", value: "Plants and Their Parts" },
  { label: "The Plant Transport System", value: "The Plant Transport System" },
  {
    label: "Air and the Respiratory System",
    value: "Air and the Respiratory System",
  },
  { label: "The Circulatory System", value: "The Circulatory System" },
  { label: "The Unit of Life", value: "The Unit of Life" },
  { label: "Electical Systems", value: "Electical Systems" },
  { label: "Using Electricity", value: "Using Electricity" },
  { label: "Magnets", value: "Magnets" },
  { label: "Forces", value: "Forces" },
  { label: "Living Together", value: "Living Together" },
  {
    label: "Man's Impact on His Environment",
    value: "Man's Impact on His Environment",
  },
  { label: "Lights and Shadows", value: "Lights and Shadows" },
  { label: "Heat and Temperature", value: "Heat and Temperature" },
  { label: "Energy in Food", value: "Energy in Food" },
  { label: "Forms and Uses of Energy", value: "Forms and Uses of Energy" },
  { label: "Sources of Energy", value: "Sources of Energy" },
  {
    label: "Diversity of Living and Non-Living Things",
    value: "Diversity of Living and Non-Living Things",
  },

  {
    label: "Life Cycles of Plants and Animals",
    value: "Life Cycles of Plants and Animals",
  },
  { label: "Diversity of Materials", value: "Diversity of Materials" },
  { label: "Plant Parts and Functions", value: "Plant Parts and Functions" },
  { label: "Human Digestive System", value: "Human Digestive System" },
  { label: "Matter", value: "Matter" },
  { label: "Light Energy", value: "Light Energy" },
  { label: "Heat Energy", value: "Heat Energy" },
  { label: "Cells and Heredity", value: "Cells and Heredity" },
  { label: "Human Body System", value: "Human Body System" },
  { label: "Human Respiratory System", value: "Human Respiratory System" },
  { label: "Human Circulatory System", value: "Human Circulatory System" },
  { label: "Human Reproduction", value: "Human Reproduction" },
  { label: "Plant Reproduction", value: "Plant Reproduction" },
  { label: "Water Cycle", value: "Water Cycle" },
  { label: "Electrical System", value: "Electrical System" },
  { label: "Photosynthesis", value: "Photosynthesis" },
  { label: "Respiration", value: "Respiration" },
  { label: "Energy", value: "Energy" },
  { label: "Food Chains and Food Webs", value: "Food Chains and Food Webs" },
  { label: "Adaptations", value: "Adaptations" },
];

export const givenP5Topics = [
  {
    label: "Whole Numbers & Four Operations",
    value: "Whole Numbers & Four Operations",
  },
  { label: "Factors & Multiples", value: "Factors & Multiples" },
  { label: "Decimals", value: "Decimals" },
  { label: "Ratio", value: "Ratio" },
  { label: "Rate", value: "Rate" },
  { label: "Triangles", value: "Triangles" },
  { label: "Average", value: "Average" },
];

export const givenP6Topics = [
  { label: "Algebra", value: "Algebra" },
  { label: "Fractions", value: "Fractions" },
  { label: "Percentage", value: "Percentage" },
  { label: "Quadrilaterals & Angles", value: "Quadrilaterals & Angles" },
  { label: "Circles", value: "Circles" },
  { label: "Volume of Cube & Cuboid", value: "Volume of Cube & Cuboid" },
  { label: "2D, 3D Figures & Nets", value: "2D, 3D Figures & Nets" },
  { label: "Speed", value: "Speed" },
  { label: "Graphs & Pie Charts", value: "Graphs & Pie Charts" },
  { label: "Patterns", value: "Patterns" },
  { label: "Heuristics", value: "Heuristics" },
  { label: "Critical Thinking", value: "Critical Thinking" },
];

export const subjects = [
  { label: "Mathematics", value: "Mathematics" },
  { label: "English", value: "English" },
  { label: "Science", value: "Science" },
];

export const textModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
  ],
};
