import React from "react";
import "./profile.css";
import { Button, Typography } from "antd";
import { Tabs } from "antd";
import AuthServices from "../../../services/AuthServices";
import SubjectDetails from "./SubjectDetails";
import { useState } from "react";
import DashboardWrapper from "../shared/DashboardWrapper";

const mathData = {
  papersCompleted: "20",
  hoursSpend: "29",
  average: "71%",
  goal: "20%",
  websiteAverage: "20%",
  dayLeft: "20",
  date: "01/10/2024",
  subjectName: "Mathematics",
};

const englishData = {
  papersCompleted: "",
  hoursSpend: "",
  average: "",
  goal: "",
  websiteAverage: "",
  dayLeft: "",
  date: "",
  subjectName: "English",
};

const scienceData = {
  papersCompleted: "89",
  hoursSpend: "49",
  average: "78%",
  goal: "90%",
  websiteAverage: "80%",
  dayLeft: "5",
  date: "01/15/2024",
  subjectName: "Science",
};

const Profile = () => {
  const { TabPane } = Tabs;
  const [tabKey, setTabKey] = useState("Mathematics");

  return (
    <DashboardWrapper title="My Profile">
      <div className="tabs">
        <Tabs
          tabBarStyle={{
            borderBottom: "none",
          }}
          onTabClick={(e) => setTabKey(e)}
        >
          <TabPane tab="MATHEMATICS" key="Mathematics">
            <SubjectDetails title={tabKey} data={mathData} id="goalForMath" />
          </TabPane>
          <TabPane tab="ENGLISH" key="English">
            <SubjectDetails
              title={tabKey}
              data={englishData}
              id="goalForEnglish"
            />
          </TabPane>
          <TabPane tab="SCIENCE" key="Science">
            <SubjectDetails
              title={tabKey}
              data={scienceData}
              id="goalForScience"
            />
          </TabPane>
          <TabPane tab="MOTHER TONGUE" key="all"></TabPane>
        </Tabs>
      </div>
    </DashboardWrapper>
  );
};

export default Profile;
