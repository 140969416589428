const initialState = {
  teacherSubjects: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "TUTORSUBJECT":
      return {
        ...state,
        teacherSubjects: action.payload,
      };

    default:
      return state;
  }
};
