import React from "react";
import "../profile/profile.css";
import { Tabs } from "antd";
import { useState } from "react";
import SubjectStatetistics from "./SubjectStatetistics.js";
import DashboardWrapper from "../shared/DashboardWrapper.js";

const Statistics = () => {
  const { TabPane } = Tabs;
  const [tabKey, setTabKey] = useState("Mathematics");

  return (
    <DashboardWrapper title="MY STATISTICS">
      <div className="tabs">
        <Tabs
          tabBarStyle={{
            borderBottom: "none",
          }}
          onTabClick={(e) => setTabKey(e)}
        >
          <TabPane tab="MATHEMATICS" key="Mathematics">
            <SubjectStatetistics title={tabKey} />
          </TabPane>
          <TabPane tab="ENGLISH" key="English">
            <SubjectStatetistics title={tabKey} />
          </TabPane>
          <TabPane tab="SCIENCE" key="Science">
            <SubjectStatetistics title={tabKey} />
          </TabPane>
          <TabPane tab="MOTHER TONGUE" key="all"></TabPane>
        </Tabs>
      </div>
    </DashboardWrapper>
  );
};

export default Statistics;
