import React, { useState } from "react";
import { Form, Input, Button, Icon, Checkbox } from "antd";
import { connect } from "react-redux";
import { login, logout, resetpassword } from "../../actions/loginAction";
import auth from "../../services/AuthServices";
import { Redirect, withRouter, Link } from "react-router-dom";
import { Typography } from "antd";
import Alert from "../../components/common/alert";
import "../../components/basic/login/login.css";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
const ResetPassword = ({ form, history, user, login }) => {
  const { Title } = Typography;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const params = useParams();
  const userId = new URLSearchParams(location.search).get("userId");

  const handleClick = () => {
    history.push("/register");
  };

  const handleLoginClick = () => {
    history.push("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        auth
          .ResetPassword({
            password: values.password,
            code: params.id,
            userId: userId,
          })
          .then((response) => {
            if (response.data.success) {
              resetpassword(response.data.user);
              return Alert("success", "Success!", response.data.message);
              // auth.storeToken(response.data.token);
              // setIsLoggedIn(true);
            } else {
              return Alert(
                "error",
                "Error!",
                response.data.message.replaceAll("TRAINER", "TEACHER")
              );
            }
          })
          .catch((error) => {
            console.log(error);
            const message = error.response
              ? error.response.data.message
              : error.message;
            return Alert("error", "Error!", message);
          });
      }
    });
  };

  const { getFieldDecorator } = form;
  if (isLoggedIn) {
    return <Redirect to={user.userOptions[0].link} />;
  } else {
    return (
      <div className="home-page-container">
        <div className="parallax">
          <div className="login-container">
            <div className="login-student-inner">
              <div className="space-btw">
                <Button className="login-btn" ghost onClick={handleLoginClick}>
                  Sign In
                </Button>
                <Button className="register-btn" ghost onClick={handleClick}>
                  Register
                </Button>
              </div>
              <div className="login-log">
                <img
                  src="/assets/leagueonlinelogo2.png"
                  alt="logo"
                  width={"30%"}
                />
              </div>

              <Form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    gap: 12,
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <Title
                      style={{
                        color: "white",
                        fontSize: "30px",
                        fontWeight: 400,
                      }}
                      level={4}
                    >
                      Reset your password?
                    </Title>
                    <Title
                      style={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: 400,
                        textAlign: "start",
                      }}
                    >
                      Please enter your new password
                    </Title>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: 24,
                      flexDirection: "column",
                    }}
                  >
                    <Form.Item
                      label="Enter New Password"
                      colon={false}
                      hasFeedback
                    >
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Please input new password!",
                          },
                        ],
                      })(<Input type="password" placeholder="Password" />)}
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" block>
                        Reset Password
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const ForgotForm = Form.create({ name: "fogot-password" })(ResetPassword);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { login, logout, resetpassword })(ForgotForm)
);
