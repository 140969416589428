import { Button, Col, Modal, Row, Spin, Typography } from "antd";
import React, { useState } from "react";
import RewardImg from "../../../assets/rewardImg.png";
import { useWindowDimensions } from "../examPortal/testBoard";
import Diamond from "../../../assets/diamond.svg";
import { useEffect } from "react";
import AuthServices from "../../../services/AuthServices";
import apis from "../../../services/Apis";
import { SecureGet, SecurePost } from "../../../services/axiosCall";
import { connect } from "react-redux";
import Alert from "../../common/alert";
import { login } from "../../../actions/loginAction";

const CategoreyRewards = (props) => {
  const { categoryId } = props;
  const { height } = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryRewards, setCategoryRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rewardObject, setRewardObject] = useState();

  const handleOpen = (item) => {
    setRewardObject(item);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleClaimReward = () => {
    setIsModalOpen(false);
    SecurePost({
      url: `${apis.CLAIM_REWARD}`,
      data: {
        rewardId: rewardObject._id,
        date: new Date(),
      },
    })
      .then((response) => {
        if (response.data.success) {
          const t = AuthServices.retriveToken();
          if (t && t !== "undefined") {
            AuthServices.FetchAuth(t).then((response) => {
              props.login(response.data.user);
            });
          }
          return Alert("success", "Success!", response.data.message);
        } else {
          return Alert("error", "Error!", response.data.message);
        }
      })
      .catch((error) => {
        const message = error.response
          ? error.response.data.message
          : error.message;
        return Alert("error", "Error!", message);
      });
  };

  useEffect(() => {
    setIsLoading(false);
    const t = AuthServices.retriveToken();
    if (t && t !== "undefined" && categoryId) {
      SecureGet({
        url: apis.GET_REWARD_CATEGORY + "/" + categoryId,
        params: {
          Token: t,
        },
      }).then((response) => {
        setCategoryRewards(response?.data?.data);
        setIsLoading(true);
      });
    }
  }, [categoryId]);

  return (
    <>
      {isLoading ? (
        <Row style={{ padding: "20px 0px" }} gutter={[16, 16]}>
          {categoryRewards?.map((item) => (
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              md={{ span: 12 }}
              xl={{ span: 8 }}
            >
              <div className="reward_Card">
                <div className="rewardImgBox">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      width={"60%"}
                      height={height <= 760 ? "100px" : height * 0.16151515151}
                      src={apis.IMGAE_BASE_URL + "/" + item?.imgUrl}
                      alt=""
                    />
                  </div>
                  <Typography className="rewardTitle">{item?.name}</Typography>
                  <Typography className="rewardDesc text-container">
                    {item?.description}
                  </Typography>
                </div>
                <div className="rewardPointsBox">
                  <div
                    style={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <img width={"23px"} src={Diamond} alt="" />
                    <Typography className="rewardsPoints">
                      {parseInt(item?.cost || 0)?.toLocaleString()} points
                    </Typography>
                  </div>
                  <Button
                    type="primary"
                    className="reedemButton"
                    onClick={() => handleOpen(item)}
                  >
                    Redeem
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
          }}
        >
          <Spin size="large" />
        </div>
      )}

      <Modal
        visible={isModalOpen}
        footer={null}
        closable={false}
        // centered
        onCancel={() => setIsModalOpen(false)}
        className="rewardMModal"
        width={450}
        style={{
          top: "30%",
          borderRadius: "25px",
          backgroundColor: "#F1F1F1",
        }}
      >
        {props.user?.userDetails?.points < rewardObject?.cost ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <Typography className="reviewCost">
                Oops! You don't have enough points to get this reward.
              </Typography>
            </div>
            <div className="redemeActions">
              <Button
                className="reedemButtonCancel"
                type="primary"
                style={{
                  width: "60%",
                }}
                onClick={() => handleClose()}
              >
                Ok
              </Button>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <Typography className="reviewCost">Reward cost:</Typography>
              <div
                style={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <img width={"33px"} src={Diamond} alt="" />
                <Typography className="rewardsPointsPopup">
                  {parseInt(rewardObject?.cost || 0)?.toLocaleString()} points
                </Typography>
              </div>
            </div>
            <div className="redemeActions">
              <Typography>
                Points after purchase: <img src={Diamond} alt="" />{" "}
                {(
                  props.user?.userDetails?.points - rewardObject?.cost || 0
                ).toLocaleString()}
              </Typography>
              <Button
                className="reedemButton"
                type="primary"
                style={{
                  width: "60%",
                }}
                onClick={() => handleClaimReward()}
              >
                Confirm
              </Button>
              <Button
                className="reedemButtonCancel"
                type="primary"
                style={{
                  width: "60%",
                }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { login })(CategoreyRewards);
