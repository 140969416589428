import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, InputNumber, Input, Button, Select, Row, Col } from "antd";
import {
  changeStep,
  changeBasicNewTestDetails,
} from "../../../actions/testAction";
import { SecurePost } from "../../../services/axiosCall";
import "./newtest.css";
import "../../basic/login/login.css";
import apis from "../../../services/Apis";
import {
  givenDifficulties,
  givenExams,
  givenLevels,
  givenSchools,
  givenYears,
  paper,
  paper1,
  terms,
} from "../../common/constants";
// const { Option } = Select;

class BasicTestFormO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingName: "",
      level: "",
      exam: "",
      school: "",
      term: "",
      year: "",
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.changeBasicNewTestDetails({
          testType: values.type || "post-test",
          testTitle: values.title,
          testDuration: values.duration,
          OrganisationName: values.organisation,
          testSubject: values.subjects,
          level: this.state.level,
          exam: this.state.exam,
          school: this.state.school,
          term: this.state.term,
          year: this.state.year,
        });
        this.props.changeStep(1);
      }
    });
  };

  validateTestName = (rule, value, callback) => {
    if (value.length >= 5) {
      this.setState({ checkingName: "validating" });
      SecurePost({
        url: apis.CHECK_TEST_NAME,
        data: { testname: value },
      })
        .then((data) => {
          if (data.data.success) {
            if (data.data.can_use) {
              this.setState({ checkingName: "success" });
              callback();
            } else {
              this.setState({ checkingName: "error" });
              callback("Another test exist with same name.");
            }
          } else {
            this.setState({ checkingName: "success" });
            callback();
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ checkingName: "success" });
          callback();
        });
    } else callback();
  };

  handleLevelDifficultyChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const subjectsValue = this.props.form.getFieldValue("subjects");

    return (
      <div
        className="login-container"
        style={{ display: "flex", margin: "auto" }}
      >
        <div className="login-inner">
          <Form onSubmit={this.handleSubmit}>
            {/* <Form.Item label="Test Type" hasFeedback>
              {getFieldDecorator("type", {
                initialValue: this.props.test.newtestFormData.testType,
                rules: [
                  { required: true, message: "Please select a test type" },
                ],
              })(
                <Select placeholder="Test Type">
                  <Option value="pre-test">Pre Test</Option>
                  <Option value="post-test">Post Test</Option>
                </Select>
              )}
            </Form.Item> */}
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Test Title"
                  hasFeedback
                  validateStatus={this.state.checkingName}
                >
                  {getFieldDecorator("title", {
                    initialValue: this.props.test.newtestFormData.testTitle,
                    rules: [
                      { required: true, message: "Please give the test title" },
                      {
                        min: 5,
                        message: "Title should be atleast 5 character long",
                      },
                      { validator: this.validateTestName },
                    ],
                  })(<Input placeholder="Test Title" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Subjects" hasFeedback>
                  {getFieldDecorator("subjects", {
                    initialValue: this.props.test.newtestFormData.testSubject,
                    rules: [
                      { required: true, message: "Please select a test type" },
                    ],
                  })(
                    <Select
                      mode=""
                      placeholder="Select Subject"
                      style={{ width: "100%" }}
                      allowClear={true}
                      optionFilterProp="s"
                      size="large"
                      bordered
                      showArrow
                    >
                      {this.props.admin.subjectTableData.map((item) => (
                        <Select.Option
                          key={item._id}
                          value={item._id}
                          s={item.topic}
                          onClick={() =>
                            this.handleLevelDifficultyChange(
                              "subject",
                              item.topic
                            )
                          }
                        >
                          {item.topic}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Level" hasFeedback required>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Level"
                    optionFilterProp="s"
                  >
                    {givenLevels.map((d, i) => (
                      <Select.Option
                        key={d.value}
                        s={d.label}
                        value={d.value}
                        onClick={() =>
                          this.handleLevelDifficultyChange("level", d.value)
                        }
                      >
                        {d.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Term" hasFeedback required>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Terms"
                    optionFilterProp="s"
                  >
                    {terms.map((d, i) => (
                      <Select.Option
                        key={d.value}
                        s={d.label}
                        value={d.value}
                        onClick={() =>
                          this.handleLevelDifficultyChange("term", d.value)
                        }
                      >
                        {d.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="School" hasFeedback>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select School"
                    optionFilterProp="s"
                  >
                    {givenSchools.map((d, i) => (
                      <Select.Option
                        key={d.value}
                        s={d.label}
                        value={d.value}
                        onClick={() =>
                          this.handleLevelDifficultyChange("school", d.value)
                        }
                      >
                        {d.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Test Duration" hasFeedback>
                  {getFieldDecorator("duration", {
                    initialValue: this.props.test.newtestFormData.testDuration,
                    rules: [
                      { required: true, message: "Please give test duration" },
                    ],
                  })(
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Test Duration"
                      min={15}
                      max={180}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="Paper" hasFeedback>
                  {this.state.subject === "Mathematics" ? (
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Paper"
                      optionFilterProp="s"
                    >
                      {paper.map((d, i) => (
                        <Select.Option
                          key={d.value}
                          s={d.label}
                          value={d.value}
                          onClick={() =>
                            this.handleLevelDifficultyChange("exam", d.value)
                          }
                        >
                          {d.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Paper"
                      optionFilterProp="s"
                    >
                      {paper1.map((d, i) => (
                        <Select.Option
                          key={d.value}
                          s={d.label}
                          value={d.value}
                          onClick={() =>
                            this.handleLevelDifficultyChange("exam", d.value)
                          }
                        >
                          {d.label}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <Form.Item label="Year" hasFeedback>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Year"
                    optionFilterProp="s"
                  >
                    {givenYears.map((d, i) => (
                      <Select.Option
                        key={d.value}
                        s={d.label}
                        value={d.value}
                        onClick={() =>
                          this.handleLevelDifficultyChange("year", d.value)
                        }
                      >
                        {d.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* <Form.Item label="Organisation Name" hasFeedback>
              {getFieldDecorator("organisation", {
                initialValue: this.props.test.newtestFormData.OrganisationName,
              })(<Input placeholder="Organisation Name" />)}
            </Form.Item> */}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Next
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
const BasicTestForm = Form.create({ name: "Basic Form" })(BasicTestFormO);

const mapStateToProps = (state) => ({
  test: state.test,
  admin: state.admin,
});

export default connect(mapStateToProps, {
  changeStep,
  changeBasicNewTestDetails,
})(BasicTestForm);
